import React from 'react';
import { useObserver } from 'mobx-react';
import Moment from 'react-moment';

import DeleteEntityButton from '../../../components/delete-entity-button';
import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';
import { CellLink } from '../../../styled-components/blocks/table';
import { useMetadataTemplateStore } from '../../../stores';
import { MetadataTemplate } from '../../models/EntityMetadataTemplateModel';

const MetadataTemplateListTable = () => {
	const metadataTemplateStore = useMetadataTemplateStore();

	const metadataTemplateListColumns: ListColumn<
		MetadataTemplate
	>[] = makeColumns([
		{
			label: 'Name',
			prop: (t: MetadataTemplate) => (
				<CellLink to={`./${t._id}`}>{t.title}</CellLink>
			),
		},
		{
			label: 'Created',
			prop: (t: MetadataTemplate) => (
				<Moment format="MMM DD, YYYY" date={t.createdAt} />
			),
		},
		{
			label: '',
			prop: (t: MetadataTemplate) => (
				<DeleteEntityButton
					entity={t}
					deleteOne={metadataTemplateStore.deleteOne}
					entityName="Metadata template"
				/>
			),
		},
	]);

	const render = () => (
		<ListTable
			columns={metadataTemplateListColumns}
			rows={metadataTemplateStore.all}
			noResultsLabel="No metadata templates yet"
		/>
	);

	return useObserver(render);
};

export default MetadataTemplateListTable;
