import React from 'react';
import { UserGroup } from '../../accounts/models/UserGroupModel';
import GroupAvatarCircle, { GroupAvatarCircleLg } from './style';

// interfaces
interface GroupAvatarProps {
	group: UserGroup;
}

// components
const GroupAvatar = (props: GroupAvatarProps) => (
	<GroupAvatarCircle background={props.group.color}>
		<small>{props.group.title.charAt(0)}</small>
	</GroupAvatarCircle>
);

export const GroupAvatarLg = (props: GroupAvatarProps) => (
	<GroupAvatarCircleLg background={props.group.color}>
		<p className="mb-0">{props.group.title.charAt(0)}</p>
	</GroupAvatarCircleLg>
);

export default GroupAvatar;
