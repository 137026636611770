import React from 'react';

import { _logError } from '../../../common/log';
import { StageStatus } from '../../../models/StageStatusModel';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import themeStore from '../../../theme/models/ThemeStore';
import { isFulfilled, WorkflowActionableStage } from '../../models';
import StageDialog from './stage-dialog';

const workflowTerm = themeStore._.workflow.toLowerCase();

interface Props {
	stage: WorkflowActionableStage;
}

const ApproveStageDialog = ({ stage }: Props) => {
	const onSubmit = async (message: string) => {
		const fulfilled = isFulfilled(stage);
		if (fulfilled) {
			try {
				await stage.updateStatus(StageStatus.completed, message);
			} catch (error) {
				_logError(error);
				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text:
							'Unable to complete the stage, an issue occurred while saving. Please try again later.',
					})
				);
			}
		}
	};

	return (
		<StageDialog
			header={`Approving stage "${stage.title}"`}
			context="You may add context about the approval (optional)."
			warning={
				stage.nextStage
					? 'Are you sure you want to complete this stage?'
					: `Completing this stage also completes this ${workflowTerm}.`
			}
			onClick={onSubmit}
			messageRequired={false}
		/>
	);
};

export default ApproveStageDialog;
