import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { getParentOfType } from 'mobx-state-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { includesCaseInsensitive } from '../../../common';
import { DownshiftMultiSelect } from '../../../components/downshift-select';
import themeStore from '../../../theme/models/ThemeStore';

import {
	TemplateModel,
	TemplateRootStage,
	TemplateTransition,
	WorkflowTemplate,
} from '../../models';
import { SelectedTemplateStage } from '../../template.contexts';

const stageTerm = themeStore._.stage.toLowerCase();

const TransitionSelect = () => {
	const stage = SelectedTemplateStage.presentValue;

	const template: WorkflowTemplate = getParentOfType(stage, TemplateModel);

	const onSelect = useCallback(
		(selection: Nullable<TemplateRootStage>) => {
			if (selection) {
				stage.addBackwardTransitionTo(selection);
			}
		},
		[stage]
	);

	const removeItem = (wt: TemplateTransition) => stage.removeTransition(wt);

	const predicate = (inputValue: string | null, s: TemplateRootStage) =>
		includesCaseInsensitive(s.title, inputValue);

	const renderTransition = (xition: MaybeNull<TemplateTransition>) =>
		xition ? (
			<>
				{xition.title} <FontAwesomeIcon icon={faArrowRight} />{' '}
				{xition.targetStage.title}
			</>
		) : null;

	const selectionActions = {
		select: onSelect,
		unselect: removeItem,
	};

	return useObserver(() => {
		const selectionState = {
			selection: stage.transitions,
			options: template
				.getStagesBefore(stage)
				.filter(
					(s) =>
						!stage.backwardTransitions.some(
							({ targetStage }) => targetStage === s
						)
				),
			searchPredicate: predicate,
		};

		return (
			<DownshiftMultiSelect
				label="Transitions"
				placeholder={`Type the target ${stageTerm} name...`}
				selectionState={selectionState}
				selectionActions={selectionActions}
				renderItem={renderTransition}
				isRemovable={({ type }) => type !== 'forward'}
			/>
		);
	});
};

export default TransitionSelect;
