import styled from 'styled-components';
import defaultAsset from '../../../assets/images/default-asset.jpg';

export const DefaultAsset = styled.div`
	background: url(${defaultAsset}) center center / cover no-repeat;
	height: 172px;
	margin-top: -1.25em;
`;

export const Thumbnail = styled.img`
	display: table;
	height: 150px;
	margin-left: auto;
	margin-right: auto;
	width: auto;
`;
