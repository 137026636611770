import { ModalBody } from 'reactstrap';
import React from 'react';

import { AssetVersion } from '../../models/AssetVersionModel';
import { DialogModal } from '../../../components/modals';
import ShareDialogBody from '../share-dialog-body';
import themeStore from '../../../theme/models/ThemeStore';

const assetTerm = themeStore._.asset;

interface Props {
	assets: readonly AssetVersion[];
}

const ShareMultipleAssetsDialog = (props: Props) => {
	// const { assets } = props;

	return (
		<DialogModal header={`Share these ${assetTerm}s`}>
			<ModalBody>
				{/* TODO: add <AsyncDispatcher> when possible to get signed url for many assets (RRR-226)*/}
				<ShareDialogBody link="#" />
			</ModalBody>
		</DialogModal>
	);
};

export default ShareMultipleAssetsDialog;
