import styled from 'styled-components';
import { Link } from '@reach/router';

import { fsNormal } from '../../../theme/ui/typography';
import anchor from '../../../theme/components/anchor';

export const StyledLink = styled(Link)`
	${anchor}
	${fsNormal}
`;

export const StyledCell = styled.td`
	${fsNormal};
	vertical-align: middle !important;
`;
