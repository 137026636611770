import React from 'react';
import { useObserver } from 'mobx-react';
import { Row } from 'reactstrap';

import NoResultsWrapper from '../../../styled-components/elements/no-results-wrapper';
import { Workflow } from '../../models';
import WorkflowCard from '../workflow-card';

interface Props {
	workflows?: ReadonlyArray<Workflow>;
}

const renderNoResults = () => (
	<Row>
		<NoResultsWrapper>
			<p>No results found</p>
		</NoResultsWrapper>
	</Row>
);

const WorkflowCardGrid = (props: Props) => {
	const renderWorkflowList = (list: readonly Workflow[]) =>
		list?.map((workflow) => (
			<WorkflowCard key={workflow._id} workflow={workflow} />
		));

	const render = () => {
		const { workflows } = props;
		return (
			<Row>
				{workflows?.length ? renderWorkflowList(workflows) : renderNoResults()}
			</Row>
		);
	};

	// component
	return useObserver(render);
};

export default WorkflowCardGrid;
