import styled from 'styled-components';
import { ffRegular } from '../../theme/ui/typography';

const Subheading = styled.p`
	${ffRegular};
	font-size: 10px;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 0;
`;

export default Subheading;
