import styled from 'styled-components';
import { secondaryButton } from '../../../theme/components/secondary-button';

export const FileInputWrapper = styled.div`
	.uppy-Root > .uppy-FileInput-btn {
		${secondaryButton};
		font-family: 'Circular-Book', sans-serif;

		&:before {
			content: '';
			background-image: url('/images/upload-solid.svg');
			width: 15px;
			display: inline-block;
			height: 15px;
			background-repeat: no-repeat;
			margin-right: 5px;
		}
	}
`;
