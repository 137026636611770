import React, { useRef } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';

import { buildClassList } from '../../common';
import { StyledCircleButton } from './style';

interface CircleButtonProps {
	icon: IconDefinition;
	onClick: () => void;

	className?: string;
	disabled?: boolean;
	tooltip?: string;
	color?: string;

	/** @deprecated */
	id?: string;
}

const CircleButton = ({
	icon,
	onClick,
	className,
	disabled = false,
	tooltip,
	color,
}: CircleButtonProps) => {
	const buttonRef = useRef<HTMLButtonElement>(null);

	return (
		<StyledCircleButton
			color={color}
			innerRef={buttonRef}
			className={buildClassList('circle-button', className)}
			disabled={disabled}
			onClick={onClick}
		>
			<FontAwesomeIcon icon={icon} />
			{tooltip && buttonRef.current ? (
				<UncontrolledTooltip target={buttonRef.current}>
					{tooltip}
				</UncontrolledTooltip>
			) : null}
		</StyledCircleButton>
	);
};

export default CircleButton;
