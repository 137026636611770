import React, { ReactElement, useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

import { sanitizeForDOMElementId } from '../../../common';
import OwnerAvatarList from '../../../components/owner-avatar-list';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowParallelStageModel } from '../../models';
import { SelectedWorkflowStage } from '../../workflow.contexts';
import { OwnerButton } from './style';

import AssignStageOwnerDialog from './assign-stage-owner-dialog';

interface Props {
	children?: ReactElement;
}

const AssignStageOwnerButton = (props: Props) => {
	const stage = SelectedWorkflowStage.presentValue;
	const buildButtonId = (title: string) => {
		return `button${sanitizeForDOMElementId(title)}`;
	};

	const modalStack = useModalCreator();

	const showAssignOwnerModal = useCallback(() => {
		if (!WorkflowParallelStageModel.is(stage)) {
			modalStack.addModal(<AssignStageOwnerDialog stage={stage} />);
		}
	}, [stage, modalStack]);

	return (
		<>
			<UncontrolledTooltip target={buildButtonId(stage.title)}>
				Click to edit {themeStore._.owner.toLowerCase()} list
			</UncontrolledTooltip>
			<OwnerButton
				id={buildButtonId(stage.title)}
				onClick={showAssignOwnerModal}
			>
				<OwnerAvatarList owners={stage.owners} className="align-self-center" />
				{props.children || 'Unassigned'}
			</OwnerButton>
		</>
	);
};

export default AssignStageOwnerButton;
