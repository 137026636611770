import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import themeStore from '../../../theme/models/ThemeStore';

import {
	SelectedTemplate,
	SelectedTemplateStage,
} from '../../template.contexts';
import { TemplateState, TemplateSubstage } from '../../models';
import { SubstageCircleButton } from './style';

const stageTerm = themeStore._.stage.toLowerCase();
const subStageTerm = `sub${stageTerm}`;

// interface
export interface SubstageButtonProps {
	stage: TemplateSubstage;
}

// component
const SubstageCreationButton = ({ stage }: SubstageButtonProps) => {
	const [, setSelectedStage] = SelectedTemplateStage.useMaybe();
	const template = SelectedTemplate.presentValue;

	const onAddStageClick = useCallback(() => {
		const createdStage = template.addSubstage(stage);

		if (createdStage) {
			setSelectedStage(createdStage);
		}
	}, [template, stage, setSelectedStage]);

	if (template.editingState === TemplateState.final) {
		return null;
	}

	const buttonID = `create-substage-${stage._id}`;
	return (
		<>
			<UncontrolledTooltip placement="top" target={buttonID}>
				Add {subStageTerm}
			</UncontrolledTooltip>
			<SubstageCircleButton onClick={onAddStageClick} id={buttonID}>
				<FontAwesomeIcon icon={faPlus} />
			</SubstageCircleButton>
		</>
	);
};

export default SubstageCreationButton;
