import React from 'react';
import { Container, Row } from 'reactstrap';
import { Column } from './style';
import Divider from '../../styled-components/elements/divider';

const TermsView = () => {
	const render = () => (
		<Container fluid={true}>
			<Row>
				<Column xs={12}>
					<h1>Terms and Conditions</h1>
					<h2>COMPANY WEBSITE TERMS OF USE AND LEGAL RESTRICTIONS</h2>
					<Divider />
				</Column>
				<Column md={9}>
					<p>
						ATTENTION: PLEASE READ ALL THESE PROVISIONS ("TERMS") CAREFULLY
						BEFORE USING THIS WEBSITE. USING THIS WEBSITE INDICATES YOUR
						AGREEMENT AND ACCEPTANCE OF THESE TERMS. IF YOU DO NOT ACCEPT THESE
						TERMS, DO NOT USE THIS WEBSITE OR DOWNLOAD MATERIALS FROM THIS SITE.
					</p>

					<h2>The Site and its Use</h2>
					<p>
						This website (the "Site") is owned and provided by the Company
						headquartered in Saint Louis, Missouri and its subsidiaries and
						affiliated companies (collectively the "Company") for your personal
						information and non-commercial use. Please feel free to browse the
						Site; however, your access and use of the Site is subject to these
						Terms and all applicable laws.
					</p>

					<p>
						You shall use this Site for lawful purposes only. You shall not post
						or transmit through this Site any information or material which
						violates or infringes, in any way, the rights of others, or which is
						unlawful, threatening, abusive, defamatory, invasive of privacy or
						publicity rights, vulgar, obscene, profane, harassing or otherwise
						objectionable. Nor shall you post or transmit through this Site any
						information or material which encourages conduct that would
						constitute a criminal offense, give rise to liability or otherwise
						violate any law or administrative ruling or guidelines, is in
						violation of these Terms, or which, without the Company's prior
						written approval, contain advertising or any solicitation with
						respect to products or services. Any conduct by you that, in the
						Company's opinion, restricts or inhibits any other user from using
						or enjoying this Site will not be permitted.
					</p>

					<p>
						The Site contains information and materials (collectively
						"Materials") which are derived in whole or in part from information
						and materials supplied by the Company and other sources, and are
						protected by international copyright, trademark and other laws,
						including but not limited to, text, software, photos, video,
						graphics and audio, and the entire content of this Site is
						copyrighted as a collective work under the United States Copyright
						Laws. The Company owns the copyright in the overall selection,
						coordination, arrangement and enhancement of such content, and owns
						the rights in, or license to use, the individual components of such
						content. Materials may not be copied, reproduced, republished,
						modified, uploaded, posted, transmitted or distributed in any way or
						decompiled, reverse engineered or disassembled, including but not
						limited to the text, images, audio and/or video, as well as any
						software (including but not limited to any images or files
						incorporated in or generated by the software or data accompanying
						such software), except that you may download one copy of the
						Materials on any single computer for your personal, noncommercial
						use only, provided you keep intact all copyright, trademark and
						other proprietary notices and make no change in or deletion of
						author attribution. This limited authorization is not a transfer of
						title in the Materials and by your use of this Site, you acknowledge
						that you do not acquire any license, ownership or other rights to
						the Materials.
					</p>

					<p>
						Modification of the Materials or use of the Materials for any other
						purpose is a violation of the Company's or such other sources'
						copyright, trademark and other proprietary rights and may violate
						other laws. The use of any such Materials on any other website or
						networked computer or environment is prohibited without the
						Company's express written consent. If you breach any of these Terms,
						your authorization to use this Site automatically terminates and you
						must immediately destroy any downloaded or printed Materials. You
						may also be subject to liability to the Company or to third parties
						as a consequence of such breach.
					</p>

					<h2>Disclaimer</h2>
					<p>
						THE SITE AND MATERIALS AND RELATED COMMUNICATIONS ARE PROVIDED "AS
						IS" WITHOUT ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED,
						OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
						MERCHANTABILTY, NON-INFRINGEMENT, OR FITNESS FOR PARTICULAR PURPOSE.
						SOME JURISDICTIONS DO NOT ALLOW FOR THE EXCLUSION OF IMPLIED
						WARRANTIES, SO THE ABOVE EXCLUSIONS OF IMPLIED WARRANTIES MAY NOT BE
						APPLICABLE UNDER CERTAIN CIRCUMSTANCES. THE COMPANY ALSO DOES NOT
						WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS, INCLUDING BUT
						NOT LIMITED TO THE RELIABILITY OF ANY ADVICE, STATEMENT OR OTHER
						INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU
						ACKNOWLEDGE BY THE USE OF THIS SITE THAT ANY RELIANCE ON ANY SUCH
						MATERIALS, ADVICE, STATEMENT OR INFORMATION SHALL BE AT YOUR SOLE
						RISK.
					</p>

					<p>
						POSTINGS ARE MADE AT SUCH TIMES AS THE COMPANY DETERMINES IN ITS
						DISCRETION. READERS SHOULD NOT ASSUME THAT THE INFORMATION CONTAINED
						ON THIS SITE HAS BEEN UPDATED OR OTHERWISE CONTAINS CURRENT
						INFORMATION. THE COMPANY DOES NOT REVIEW PAST POSTINGS TO DETERMINE
						WHETHER THEY REMAIN ACCURATE, AND INFORMATION CONTAINED IN SUCH
						POSTINGS MAY HAVE BEEN SUPERSEDED.
					</p>

					<p>
						THE COMPANY ASSUMES NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR
						ANY DAMAGES TO, OR VIRUSES THAT MAY INFECT, YOUR COMPUTER EQUIPMENT
						OR OTHER PROPERTY ON ACCOUNT OF YOUR ACCESS TO, USE OF, OR BROWSING
						IN THE SITE (INCLUDING ANY BULLETIN BOARD AND CHAT ROOM) OR YOUR
						DOWNLOADING OF ANY MATERIALS FROM THE SITE.
					</p>

					<h2>Third-Party Sites</h2>
					<p>
						The Company may provide as a convenience to you, links to websites
						operated by other entities and persons, but makes no representations
						whatsoever about any other website which you may access through this
						one. Any link to a non-Company website does not mean that the
						Company endorses or accepts any responsibility for the content, or
						the use of such website. If you use these sites, you do so at your
						own risk. The Company makes no warranty or representation regarding,
						and does not sponsor or endorse, any linked websites or the
						information or materials appearing thereon or any of products and
						services described thereon. Furthermore, links do not imply that the
						Company is affiliated or associated with, or that any linked site is
						authorized to use any trademark, trade name, logo or copyright of
						the Company.
					</p>

					<h2>Third Party Content</h2>
					<p>
						Any opinions, statements, services, offers or other information or
						content expressed or made available in this Site by any third party
						are those of the respective author(s) or distributor(s) and not of
						the Company.
					</p>

					<h2>Limitation of Liability</h2>
					<p>
						Your use and browsing of the Site is at your risk. If you are
						dissatisfied with any of the Materials contained in the Site, or
						with any of these Terms, your sole and exclusive remedy is to
						discontinue accessing and using the Site.
					</p>

					<p>
						UNDER NO CIRCUMSTANCES WILL THE COMPANY, ITS SUPPLIERS OR OTHER
						THIRD PARTIES MENTIONED AT, OR INVOLVED IN CREATING, PRODUCING, OR
						DELIVERING THIS SITE BE LIABLE FOR ANY DIRECT, INCIDENTAL,
						CONSEQUENTIAL, INDIRECT, SPECIAL OR PUNITIVE DAMAGES WHATSOEVER
						(INCLUDING WITHOUT LIMITATION, COSTS AND EXPENSES OF ANY TYPE
						INCURRED, LOST PROFITS; LOST DATA OR PROGRAMS; AND BUSINESS
						INTERRUPTION) ARISING OUT OF YOUR ACCESS TO, USE, INABILITY TO USE
						OR THE RESULTS OF USE OF THIS SITE, ANY WEBSITES LINKED TO THIS
						SITE, OR ANY MATERIALS CONTAINED AT ANY OR ALL SUCH SITES (INCLUDING
						BUT LIMITED TO THOSE CAUSED BY OR RESULTING FROM A FAILURE OF
						PERFORMANCE; ERROR; OMISSION; LINKING TO OTHER WEBSITES;
						INTERRUPTION; DELETION; DEFECT; DELAY IN OPERATION OR TRANSMISSION;
						COMPUTER VIRUS; COMMUNICATION LINE FAILURE; OR DESTRUCTION,
						UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF ANY COMPUTER OR
						SYSTEM), WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE,
						STRICT LIABILITY, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT THE
						COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
					</p>

					<p>
						THE COMPANY SHALL HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY ACTS,
						OMISSIONS OR CONDUCT OF ANY USER OR OTHER THIRD PARTY.
					</p>

					<p>
						The Company's obligations and responsibilities regarding its
						products and services are governed solely by the Company's terms and
						conditions of sale or license under which such products and services
						are sold or licensed
					</p>

					<h2>Changes</h2>
					<p>
						The Company reserves the right, in its sole discretion, to revise
						these Terms at any time and correct any errors or omissions in any
						portion of the Site and Materials. Materials may also be changed,
						modified, added or removed and updated without notice at any time;
						however, the Company is under no obligation to update Materials. The
						Company may also make changes in the products, services, programs or
						prices (if any) described in the Site at any time without notice.
						Your continued use of the Site after any change the Company makes to
						the Site will be considered acceptance of those changes. You should
						revisit these Terms from time to time to review the then-current
						Terms because they are binding on you.
					</p>

					<h2>Linking to this Site</h2>
					<p>
						Anyone linking to the Company's website must comply with all
						applicable laws.
					</p>

					<h2>International Users</h2>
					<p>
						This Site is administered by the Company from its offices in the
						United States. Materials published at this Site may refer to
						products, programs or services that are not available in your
						country. Furthermore, the Company makes no representation that the
						Materials at this Site are appropriate or available for use at other
						locations outside of the United States and access to them from
						territories where their contents are illegal is prohibited. You may
						not use the Site or export information and materials in violation of
						the export laws of the United States or any other country. If you
						access this Site from a location outside of the United States, you
						are responsible for compliance with all applicable laws.
					</p>

					<h2>Governing Law</h2>
					<p>
						These Terms shall be governed by the laws of the State of Delaware,
						without giving effect to any principles of conflicts of law. If any
						provision of this Agreement and Terms shall be unlawful, void, or
						for any reason unenforceable then that provision shall be severable
						from these Terms and shall not affect the validity and
						enforceability of any remaining provisions.
					</p>

					<p>
						These Terms constitute the entire agreement between the Company and
						you with respect to your use of the Site. Any cause of action you
						may have with respect to your use of the Site must be commenced
						within six (6) months after the claim or cause of action arises.
					</p>
				</Column>
			</Row>
		</Container>
	);

	return render();
};

export default TermsView;
