import React from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';

import Heading from '../../../styled-components/elements/heading';
import AddAssetCollectionForm from '../../components/add-asset-collection-form';
import themeStore from '../../../theme/models/ThemeStore';
import Divider from '../../../styled-components/elements/divider';

const AssetCollectionCreationView = () => (
	<Container fluid={true}>
		<Row>
			<Col xs={12}>
				<Breadcrumb>
					<BreadcrumbItem tag={Link} to="/">
						Home
					</BreadcrumbItem>
					<BreadcrumbItem
						tag={Link}
						to=".."
					>{`${themeStore._.asset} ${themeStore._.assetCollection}s`}</BreadcrumbItem>
					<BreadcrumbItem active>New</BreadcrumbItem>
				</Breadcrumb>
				<Heading>Create a {themeStore._.assetCollection.toLowerCase()}</Heading>
				<Divider />
			</Col>
			<Col lg={8}>
				<AddAssetCollectionForm />
			</Col>
		</Row>
	</Container>
);

export default AssetCollectionCreationView;
