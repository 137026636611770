import React from 'react';
import { ToastBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimesCircle,
	faExclamationTriangle,
	faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import NotificationModel, { NotificationType } from '../../NotificationModel';
import { StyledHeader, StyledToast } from './style';

interface NotificationProps {
	model: NotificationModel;
}

const Notification = ({ model }: NotificationProps) => {
	const renderHeading = () => {
		let icon;
		switch (model.type) {
			case NotificationType.ERROR:
				icon = <FontAwesomeIcon icon={faTimesCircle} />;
				break;

			case NotificationType.WARNING:
				icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
				break;

			default:
				icon = <FontAwesomeIcon icon={faCheckCircle} />;
		}

		let heading = model.heading;
		if (!heading) {
			switch (model.type) {
				case NotificationType.ERROR:
					heading = 'Error';
					break;

				case NotificationType.WARNING:
					heading = 'Warning';
					break;

				default:
					heading = 'Info';
			}
		}

		return (
			<StyledHeader
				className={`notification-header ${model.type}`}
				icon={icon}
				toggle={model.dismiss}
			>
				{heading}
			</StyledHeader>
		);
	};

	const renderComponent = () => (
		<StyledToast isOpen={model.shouldBeDisplayed}>
			{renderHeading()}
			<ToastBody>{model.text}</ToastBody>
		</StyledToast>
	);

	return renderComponent();
};

export default Notification;
