import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { indexViewStore } from '../../../index-view/models/IndexViewStore';
import { unwrapEvent } from '../../../common';
import { ViewType } from '../../../index-view/models/IndexViewModel';
import { builtinWorkflowPredicates, WFilter } from '../../../common/filters';
import IndexViewToggler from '../../../index-view/components/index-view-toggler';
import SideBarFiltersNav from '../../../components/side-bar-filters-nav';
import themeStore from '../../../theme/models/ThemeStore';
import windowModel from '../../../models/WindowModel';
import WorkflowCardGrid from '../../components/workflow-card-grid';
import WorkflowListTable from '../../components/workflow-list-table';
import { PermissionsCheck } from '../../../accounts/permissions-check.component';
import { StorePaginator } from '../../../components/store-paginator';
import { useFilteredStore } from '../../../common/FilteredStore';
import { useWorkflowStore } from '../../../stores';
import { CreateWorkflowButton } from './style';
import Heading from '../../../styled-components/elements/heading';
import SearchBar from '../../../components/search-bar';
import SaveQueryButton from '../../../components/save-query-button';
import { QueryTypes } from '../../../queries/models/QueryStore';
import Divider from '../../../styled-components/elements/divider';

const workflowTerm = themeStore._.workflow;
const workflowLowerCaseTerm = workflowTerm.toLowerCase();

const filterNavOptions = [
	{
		label: `All ${workflowLowerCaseTerm}s`,
		value: undefined,
	},
	{
		label: 'Active',
		value: WFilter.ACTIVE,
	},
	{
		label: 'Paused',
		value: WFilter.PAUSED,
	},
	{
		label: 'Cancelled',
		value: WFilter.CANCELLED,
	},
	{
		label: 'Created by me',
		value: WFilter.CREATED_BY,
	},
	{
		label: 'Collaborating',
		value: WFilter.COLLABORATING,
	},
];

const WorkflowsIndexView = () => {
	const filteredWorkflowStore = useFilteredStore(
		useWorkflowStore(),
		builtinWorkflowPredicates
	);
	const workflowsViewOptions = indexViewStore.workflows;

	useEffect(() => {
		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue) {
			filteredWorkflowStore.setQuery(queryValue);
		}
	}, [filteredWorkflowStore]);

	const render = () => {
		const WorkflowsRenderer =
			indexViewStore.workflows.viewType === ViewType.grid
				? WorkflowCardGrid
				: WorkflowListTable;

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem active>{`${workflowTerm}s`}</BreadcrumbItem>
						</Breadcrumb>
						<Heading>{`${workflowTerm}s`}</Heading>
					</Col>
					<Col md={10}>
						<SearchBar
							placeholder={`Search all ${workflowLowerCaseTerm}s...`}
							onChange={unwrapEvent(filteredWorkflowStore.setQuery)}
						/>
					</Col>
					<Col md={2} className="mt-md-0 mt-3">
						<SaveQueryButton
							className="float-md-right float-none"
							searchTerm={filteredWorkflowStore.searchQuery}
							type={QueryTypes.WORKFLOWS}
						/>
					</Col>
					<Col xs={12} className="mt-4">
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col lg={2} md={3} className="mb-md-0 mb-4">
						<PermissionsCheck check="canCreateWorkflow">
							<CreateWorkflowButton to="./new">
								<FontAwesomeIcon icon={faPlus} className="mr-2" />
								New {workflowLowerCaseTerm}
							</CreateWorkflowButton>
						</PermissionsCheck>

						<SideBarFiltersNav
							filter={filteredWorkflowStore.predicate}
							setFilter={unwrapEvent(filteredWorkflowStore.setFilter)}
							options={filterNavOptions}
						/>
					</Col>
					<Col lg={10} md={9}>
						<div className="d-flex justify-content-end">
							<IndexViewToggler view={workflowsViewOptions} />
						</div>
						<WorkflowsRenderer
							workflows={filteredWorkflowStore.currentEntityPage}
						/>
						<StorePaginator
							pages={filteredWorkflowStore.pages}
							shouldPaginate={filteredWorkflowStore.shouldPaginate}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowsIndexView;
