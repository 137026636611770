import React, { useCallback } from 'react';
import { getParentOfType } from 'mobx-state-tree';
import { useObserver } from 'mobx-react';
import { UncontrolledTooltip } from 'reactstrap';
import { faEdit, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import themeStore from '../../../theme/models/ThemeStore';

import {
	TemplateModel,
	TemplateParallelStage,
	TemplateParallelStageModel,
	TemplateRootStageModel,
	TemplateStage,
	WorkflowTemplate,
} from '../../models';
import { SelectedTemplateStage } from '../../template.contexts';

import {
	AddSubstageButton,
	ButtonWrapper,
	DeleteButton,
	EditButton,
} from './style';

const stageTerm = themeStore._.stage;

// interfaces
interface TemplateStageButtonsProps<S extends TemplateStage = TemplateStage> {
	stage: S;
}

interface TemplateStageDeleteButtonProps extends TemplateStageButtonsProps {
	template: WorkflowTemplate;
}

const ParallelStageAddSubstageButton = ({
	stage,
}: TemplateStageButtonsProps<TemplateParallelStage>) => {
	const buttonID = `addSubStageButton${stage._id}`;
	const setSelectedStage = SelectedTemplateStage.setter;

	const addSubstage = useCallback(() => {
		const template = getParentOfType(stage, TemplateModel);
		return setSelectedStage(template.addSubstage(stage));
	}, [setSelectedStage, stage]);

	return (
		<AddSubstageButton id={buttonID} size="sm" onClick={addSubstage}>
			<FontAwesomeIcon icon={faPlus} className="plus-sign" />
			<UncontrolledTooltip target={buttonID}>
				Add sub-{stageTerm?.toLowerCase()}
			</UncontrolledTooltip>
		</AddSubstageButton>
	);
};

const DeleteStageButton = ({
	stage,
	template,
}: TemplateStageDeleteButtonProps) => {
	const deleteStage = useCallback(() => template.deleteStage(stage), [
		template,
		stage,
	]);

	// don't allow deleting the only stage
	if (template.stages.length === 1 && TemplateRootStageModel.is(stage)) {
		return null;
	}
	const buttonID = `delete-stage-${stage._id}`;

	return (
		<DeleteButton id={buttonID} size="sm" onClick={deleteStage}>
			<FontAwesomeIcon icon={faTimes} />
			<UncontrolledTooltip target={buttonID}>
				Delete {stageTerm?.toLowerCase()}
			</UncontrolledTooltip>
		</DeleteButton>
	);
};

const EditStageButton = ({ stage }: TemplateStageButtonsProps) => {
	const buttonID = `edit-stage-${stage._id}`;
	const setSelectedStage = SelectedTemplateStage.setter;

	return (
		<EditButton id={buttonID} size="sm" onClick={() => setSelectedStage(stage)}>
			<FontAwesomeIcon icon={faEdit} />
			<UncontrolledTooltip target={buttonID}>
				Edit {stageTerm?.toLowerCase()}
			</UncontrolledTooltip>
		</EditButton>
	);
};

// component definition
const TemplateStageEditButtons = (props: TemplateStageButtonsProps) => {
	const { stage } = props;

	return useObserver(() => {
		const template = getParentOfType(stage, TemplateModel);
		if (template.isFinalized) {
			// Don't show edit buttons for finalized templates
			return null;
		}

		const addStageButton = TemplateParallelStageModel.is(stage) ? (
			<ParallelStageAddSubstageButton stage={stage} />
		) : null;

		return (
			<ButtonWrapper>
				<EditStageButton stage={stage} />
				<DeleteStageButton template={template} stage={stage} />
				{addStageButton}
			</ButtonWrapper>
		);
	});
};

export default TemplateStageEditButtons;
