import React from 'react';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../styled-components/blocks/entity-props';
import { BaseWorkflowOwnable } from '../../../models/BaseWorkflowOwnableModel';
import OwnerList from '../../../components/owner-list';
import themeStore from '../../../theme/models/ThemeStore';

import {
	TemplateActionableStage,
	TemplateRootStage,
	TemplateSingleStageModel,
	TemplateStage,
	TemplateStageInputSlot,
	TemplateSubstage,
	TemplateTransition,
} from '../../models';
import {
	TemplateParallelStageModel,
	TemplateSubstageModel,
} from '../../models';

const ownerTerm = themeStore._.owner;

interface TemplateStageDetailsProps {
	stage: TemplateStage;
}

const TemplateStageDetails = ({ stage }: TemplateStageDetailsProps) => {
	let details;

	const getBackwardsTransitions = (stage: TemplateRootStage) => {
		if (stage.backwardTransitions.length) {
			return (
				<EntityPropListItem>
					<EntityPropLabel>Backward transitions:</EntityPropLabel>
					<p>
						{stage.backwardTransitions.map(
							(transition: TemplateTransition, index: number) => (
								<React.Fragment key={transition._id}>
									<span>{transition.targetStage.title}</span>
									<span>
										{stage.backwardTransitions.length > 1 &&
										index + 1 < stage.backwardTransitions.length
											? ' or '
											: ''}
									</span>
								</React.Fragment>
							)
						)}
					</p>
				</EntityPropListItem>
			);
		}
	};

	const getInstructions = (stage: TemplateSubstage) => {
		if (stage.instructions) {
			return (
				<EntityPropListItem>
					<EntityPropLabel>Instructions</EntityPropLabel>
					<p>{stage.instructions}</p>
				</EntityPropListItem>
			);
		}
	};

	const getOwners = (stage: BaseWorkflowOwnable) => (
		<EntityPropListItem>
			<EntityPropLabel>{ownerTerm}(s)</EntityPropLabel>
			<OwnerList entity={stage} />
		</EntityPropListItem>
	);

	const getInputSlots = (stage: TemplateActionableStage) => {
		if (stage.inputSlots.length) {
			return (
				<EntityPropListItem>
					<EntityPropLabel>Input slots</EntityPropLabel>
					<p>
						{stage.inputSlots.map(
							(slot: TemplateStageInputSlot, index: number) => (
								<React.Fragment key={slot._id}>
									<span>{slot.label}</span>
									<span>
										{stage.inputSlots.length > 1 &&
										index + 1 < stage.inputSlots.length
											? ', '
											: ''}
									</span>
								</React.Fragment>
							)
						)}
					</p>
				</EntityPropListItem>
			);
		}
	};

	if (TemplateSingleStageModel.is(stage)) {
		details = (
			<>
				{getOwners(stage)}
				{getBackwardsTransitions(stage)}
				{getInputSlots(stage)}
			</>
		);
	} else if (TemplateParallelStageModel.is(stage)) {
		details = <>{getBackwardsTransitions(stage)}</>;
	} else if (TemplateSubstageModel.is(stage)) {
		details = (
			<>
				{getOwners(stage)}
				{getInstructions(stage)}
				{getInputSlots(stage)}
			</>
		);
	}

	return (
		<EntityPropList>
			<EntityPropListItem>
				<EntityPropLabel>Title</EntityPropLabel>
				<p>{stage.title}</p>
			</EntityPropListItem>
			<EntityPropListItem>
				<EntityPropLabel>Expected Duration</EntityPropLabel>
				<p>{stage.expectedDurationHrs} hours</p>
			</EntityPropListItem>
			{details}
		</EntityPropList>
	);
};

export default TemplateStageDetails;
