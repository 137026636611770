import styled from 'styled-components';
import { CustomInput } from 'reactstrap';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const SwitchInput = styled(CustomInput)`
	&& {
		.custom-control-label::before {
			height: 24px;
			width: 37px;
			border-radius: 14px;
		}

		.custom-control-label::after {
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background-color: ${colors.white};
		}

		.custom-control-input:checked ~ .custom-control-label::before {
			border-color: ${colors.secondary};
			background-color: ${colors.secondary};
		}

		.custom-control-input:checked ~ .custom-control-label::after {
			background-image: url('/images/check.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: initial;
		}

		.custom-control-label::before {
			border-color: ${colors.grey};
			background-color: ${colors.grey};
		}
	}
`;
