import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faPaperclip,
	faPlus,
	faRandom,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

import { noop } from '../../../common';
import themeStore from '../../../theme/models/ThemeStore';

import {
	SelectedTemplate,
	SelectedTemplateStage,
} from '../../template.contexts';
import { StageActionType, TemplateRootStage } from '../../models';
import { Menu, MenuItem, MenuOpen, MenuOpenButton } from './style';

const stageTerm = themeStore._.stage.toLowerCase();

// interface
export interface StageButtonProps {
	stage: TemplateRootStage;
	onCreate?: (stage: TemplateRootStage) => unknown;
}

type TemplateCanvasStageType = 'approval' | 'input' | 'parallel';

// constants
const iconsByType: Record<TemplateCanvasStageType, IconDefinition> = {
	parallel: faRandom,
	approval: faCheck,
	input: faPaperclip,
};

// component
const StageCreationButton = ({ stage, onCreate = noop }: StageButtonProps) => {
	const template = SelectedTemplate.presentValue;
	const [, setSelectedStage] = SelectedTemplateStage.useMaybe();

	const [isOpen, setIsOpen] = useState(false);

	const onEditStageClick = useCallback(
		(type: TemplateCanvasStageType) => {
			let createdStage;

			switch (type) {
				case 'approval':
					createdStage = template.addSingleStage(
						stage,
						StageActionType.approval
					);
					break;

				case 'input':
					createdStage = template.addSingleStage(stage, StageActionType.input);
					break;

				case 'parallel':
					createdStage = template.addParallelStage(stage);
					break;

				default:
					console.warn(
						`Unable to create a new ${stageTerm}, ` +
							`the type "${type}" is not valid or handled.`
					);
			}

			if (createdStage) {
				onCreate(createdStage);
				setSelectedStage(createdStage);
			}

			setIsOpen(false);
		},
		[template, stage, onCreate, setSelectedStage]
	);

	const renderButtonForType = useCallback(
		(type: TemplateCanvasStageType) => {
			const buttonId = `add${type}${stage._id}`;
			return (
				<MenuItem
					key={buttonId}
					className="menu-item"
					onClick={() => onEditStageClick(type)}
				>
					<UncontrolledTooltip placement="top" target={buttonId}>
						Add {type} {stageTerm}
					</UncontrolledTooltip>
					<FontAwesomeIcon icon={iconsByType[type]} id={buttonId} />
				</MenuItem>
			);
		},
		[stage, onEditStageClick]
	);

	return (
		<Menu>
			<MenuOpen
				type="checkbox"
				name="menu-open"
				id={`menuOpen${stage._id}`}
				onChange={() => setIsOpen(!isOpen)}
				checked={isOpen}
			/>
			<MenuOpenButton
				className="menu-open-button"
				htmlFor={`menuOpen${stage._id}`}
			>
				<FontAwesomeIcon icon={faPlus} className="plus-sign" />
			</MenuOpenButton>
			{(['approval', 'input', 'parallel'] as const).map(renderButtonForType)}
		</Menu>
	);
};

export default StageCreationButton;
