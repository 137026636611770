import React, { ReactChild } from 'react';

import { AssetVersion } from '../../models/AssetVersionModel';
import {
	extMatcher,
	typeMatcher,
	typePrefixMatcher,
} from './asset-type-matchers';

import AssetViewerWarning from './asset-viewer-warning';
import ImageViewer from './image-viewer';
import { DamAsset } from '../../models/DamAssetModel';
import { DetailsOnlyViewer, FallbackViewer } from './fallback-viewer';
import { stubTrue } from '../../../common';
import { PreviewContainer } from './style';

export type Previewable = DamAsset | AssetVersion;

interface AssetPreviewProps {
	asset: Previewable;
}

interface AssetRenderer {
	render: (props: AssetPreviewProps) => ReactChild;
	canRender: (a: Previewable) => boolean;
}

const invalidFileRenderer = {
	render: () => (
		<AssetViewerWarning
			text="Unable to preview asset: File type is likely invalid."
			color="danger"
		/>
	),
	canRender: () => true,
};

const renderers: ReadonlyArray<AssetRenderer> = [
	{ render: ImageViewer, canRender: typePrefixMatcher('image') },
	{ render: DetailsOnlyViewer, canRender: extMatcher('ai') },
	{
		render: DetailsOnlyViewer,
		canRender: typeMatcher('application/postscript'),
	},
	{ render: ImageViewer, canRender: typeMatcher('application/pdf') },
	{ render: FallbackViewer, canRender: typeMatcher('application/zip') },
	{ render: FallbackViewer, canRender: stubTrue },
];

const findRendererFor = (a: Previewable) =>
	renderers.find((r) => r.canRender(a)) ?? invalidFileRenderer;

// component
const AssetPreview = (props: AssetPreviewProps) => {
	const { asset } = props;

	const renderedAsset = findRendererFor(asset).render(props);

	return (
		<PreviewContainer className="asset-preview">
			{renderedAsset}
		</PreviewContainer>
	);
};

export default AssetPreview;
