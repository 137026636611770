import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { shouldShow } from '../../../common';
import {
	SelectedTemplate,
	SelectedTemplateStage,
} from '../../template.contexts';
import TemplateStageDetails from './template-final-details-stage-details';
import { TemplateBaseFinalDetails } from './template-final-details-template-details';

const TemplateFinalDetailsCard = () => {
	const [template] = SelectedTemplate.usePresent();
	const [selectedStage] = SelectedTemplateStage.useMaybe();

	const title = `${selectedStage ? 'Stage' : 'Template'} Details`;

	let body: JSX.Element;
	if (shouldShow(selectedStage)) {
		body = (
			<TemplateStageDetails key={selectedStage._id} stage={selectedStage} />
		);
	} else {
		body = <TemplateBaseFinalDetails key={template._id} template={template} />;
	}

	return (
		<Card>
			<CardHeader>
				<p>{title}</p>
			</CardHeader>
			<CardBody>{body}</CardBody>
		</Card>
	);
};

export default TemplateFinalDetailsCard;
