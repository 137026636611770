import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';

import { doesNameableMatch } from '../../../common';
import {
	DownshiftMultiSelectProps,
	DownshiftMultiSelect,
} from '../../../components/downshift-select';
import { useWorkflowStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import { Workflow } from '../../../workflows/models';
import { WorkflowCollection } from '../../models/WorkflowCollectionModel';

const CollectionWorkflowSelect = ({
	collection,
}: {
	collection: WorkflowCollection;
}) => {
	const workflowStore = useWorkflowStore();

	const addWorkflow = useCallback(
		(workflow: Nullable<Workflow>) => {
			if (workflow) {
				collection.addWorkflow(workflow);
			}
		},
		[collection]
	);

	const removeWorkflow = useCallback(
		(workflow: Workflow) => {
			collection.removeWorkflow(workflow);
		},
		[collection]
	);

	const canBeAdded = useCallback(
		(workflow: Workflow) =>
			!(workflow.workflowCollection || collection.hasWorkflow(workflow)),
		[collection]
	);

	return useObserver(() => {
		const selectProps: DownshiftMultiSelectProps<Workflow, Workflow> = {
			label: `${themeStore._.workflow}s`,
			selectionState: {
				selection: collection.workflows,
				options: workflowStore.all.filter(canBeAdded),
				searchPredicate: doesNameableMatch,
			},
			selectionActions: {
				select: addWorkflow,
				unselect: removeWorkflow,
			},
		};

		return <DownshiftMultiSelect {...selectProps} />;
	});
};

export default CollectionWorkflowSelect;
