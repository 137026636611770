import styled from 'styled-components';
import { Link } from '@reach/router';
import anchor from '../../theme/components/anchor';
import { fsNormal } from '../../theme/ui/typography';

export const StyledLink = styled(Link)`
	${anchor}
	${fsNormal}

	&:hover {
		text-decoration: none;
	}

	.cta-label {
		line-height: 1;
		vertical-align: middle;
	}

	.cta-icon {
		margin-left: 8px;
		margin-top: 1px;
		vertical-align: middle;
	}
`;
