import React from 'react';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { capitalizeFirstLetter } from '../../../common';
import {
	EntityPropLabel,
	EntityPropListItem,
} from '../../../styled-components/blocks/entity-props';
import TagBadge from '../../../styled-components/elements/tag-badge';
import { EntityMetadata } from '../../models/EntityMetadataModel';

const EntityMetadataFields = ({ metadata }: { metadata: EntityMetadata }) => {
	let metadataFields: Array<any> = [];

	const renderTags = () =>
		metadata.tags.length ? (
			<EntityPropListItem>
				<EntityPropLabel>
					<FontAwesomeIcon icon={faTag} /> Tags
				</EntityPropLabel>
				{metadata.tags.map((tag) => (
					<TagBadge key={tag}>{tag}</TagBadge>
				))}
			</EntityPropListItem>
		) : null;

	metadata.values.forEach((value, key) => {
		metadataFields.push(
			<EntityPropListItem key={key}>
				<EntityPropLabel>{capitalizeFirstLetter(key)}</EntityPropLabel>
				<p>{value}</p>
			</EntityPropListItem>
		);
	});

	return (
		<>
			{metadataFields}
			{renderTags()}
		</>
	);
};

export default EntityMetadataFields;
