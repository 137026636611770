import styled from 'styled-components';

const EntityPropList = styled.ul`
	font-size: 14px;
	list-style: none;
	margin-bottom: 0;
	padding: inherit;

	@media only screen and (min-width: 768px) {
		padding: 0;
	}
`;

export default EntityPropList;
