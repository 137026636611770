import React from 'react';
import { useObserver } from 'mobx-react';
import { Card, CardFooter, CardHeader, Table } from 'reactstrap';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDashboardStore } from '../../../stores';
import SmallCTALink from '../../../components/small-cta-link';
import themeStore from '../../../theme/models/ThemeStore';
import { allWorkflowsRoute } from '../../../workflows/workflow-routes';
import { NoResultsRow } from '../../../components/no-results-row';
import RecentWorkflowRow from './recent-workflow-row';
import P from '../../../styled-components/elements/p';

const workflowPluralTerm = `${themeStore._.workflow.toLowerCase()}s`;

const RecentWorkflowTable = ({ max = 5 }: { max?: number }) => {
	const dashboardStore = useDashboardStore();

	const renderComponent = () => {
		const workflows = dashboardStore.recentWorkflows;

		return (
			<Card className="recent-workflows-table h-100">
				<CardHeader>
					<P className="mb-0">
						<FontAwesomeIcon icon={faChartLine} className="mr-2" />
						Recent {workflowPluralTerm}
					</P>
				</CardHeader>

				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Start</th>
							<th>Due</th>
							<th>Status</th>
							<th>Completion</th>
							<th>Health</th>
						</tr>
					</thead>

					<tbody>
						{workflows.length ? (
							workflows
								.slice(0, max)
								.map((workflow) => (
									<RecentWorkflowRow key={workflow._id} workflow={workflow} />
								))
						) : (
							<NoResultsRow term="workflow" />
						)}
					</tbody>
				</Table>

				<CardFooter className="bg-white">
					<div className="float-right">
						<SmallCTALink
							link={allWorkflowsRoute}
							label={`View all ${workflowPluralTerm}`}
						/>
					</div>
				</CardFooter>
			</Card>
		);
	};

	return useObserver(renderComponent);
};

export default RecentWorkflowTable;
