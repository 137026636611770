import React, { useState } from 'react';
import { FormFeedback, FormGroup, InputProps } from 'reactstrap';

import { unwrapEvent } from '../../common';
import StyledLabel from '../../styled-components/elements/label';
import StyledInput from '../../styled-components/elements/input';

// interfaces
export interface LabelInputProps extends InputProps {
	inputValid?: boolean;
	errorMsg?: string;
}

export interface ControlledLabelInputProps extends LabelInputProps {
	// Not optional. This is a controlled input.
	value: Maybe<string>;
}

export interface UncontrolledLabelInputProps extends LabelInputProps {
	value?: never;
}

// component
const LabeledInput = (props: ControlledLabelInputProps) => {
	const { id, label, inputValid = true, value = '', errorMsg, ...rest } = props;

	const feedback = inputValid ? null : (
		<FormFeedback invalid="true">{errorMsg}</FormFeedback>
	);

	return (
		<FormGroup>
			<StyledLabel for={id}>{label}</StyledLabel>
			<StyledInput
				value={value}
				className={!inputValid && 'is-invalid'}
				{...rest}
			/>
			{feedback}
		</FormGroup>
	);
};

export const UncontrolledLabeledInput = (
	props: UncontrolledLabelInputProps
) => {
	const [value, setValue] = useState('');
	return (
		<LabeledInput {...props} value={value} onChange={unwrapEvent(setValue)} />
	);
};

export default LabeledInput;
