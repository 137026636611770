import styled from 'styled-components';

export const AppContent = styled.div`
	transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	display: flex;
	flex: 1 1 auto;
	min-width: 0;
	flex-direction: column;
	position: relative;
	min-height: 100vh;
	padding-left: 0;

	@media only screen and (min-width: 768px) {
		padding-left: 75px;
	}
`;
