import styled from 'styled-components';
import { Alert } from 'reactstrap';

export const DisabledMask = styled.div`
	background: rgba(245, 245, 245, 0.5);
	z-index: 3;
	display: flex;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`;

export const DisabledText = styled.p`
	margin-bottom: 0;
`;

export const StyledAlert = styled(Alert)`
	z-index: 4;
`;
