import { useEffect, useState } from 'react';
import { useAPIClient } from '../../../stores';
import { ProductTemplate } from '../../models/ProductTemplateInterface';

const useTemplateSelect = () => {
	const [templateList, setTemplateList] = useState<ProductTemplate[]>([]);
	const client = useAPIClient();

	useEffect(() => {
		const fetchData = async () => {
			const request = client.get('product-templates/');

			const response = await request;

			const templates = response.map((x: any) => {
				const fields = x.fieldGroups.flatMap((y: any) => y.fields);

				const fieldGroups = fields.map((group: any) => {
					return { label: group.label, value: group.name };
				});

				return {
					name: x.name,
					id: x._id,
					fields: fieldGroups,
				};
			});

			setTemplateList(templates);
		};

		fetchData();
	}, [client]);

	return { templateList };
};

export default useTemplateSelect;
