import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';
import { boxShadowFocus } from '../ui/ui';
import { fsMedium } from '../ui/typography';

const { colors } = themeStore.selectedTheme;

export const primaryButton = css`
	${fsMedium};
	display: inline-block;
	-webkit-appearance: none;
	background-color: ${colors.primary};
	border-color: initial;
	border-image: initial;
	border-radius: 2px;
	border-style: initial;
	border-width: 0;
	box-sizing: border-box;
	color: ${colors.white};
	cursor: pointer;
	line-height: inherit;
	margin: 0;
	padding: 15px 20px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		background-color: ${colors.primaryHighlighted};
		color: ${colors.white};
		text-decoration: none;
	}

	&:focus {
		${boxShadowFocus};
		text-decoration: none;
	}

	&:disabled {
		cursor: not-allowed;
		background-color: ${colors.darkGrey};

		&:hover {
			background-color: ${colors.darkGrey};
		}
	}
`;
