import React, { useCallback } from 'react';
import { faFastForward } from '@fortawesome/free-solid-svg-icons';

import { buildClassList } from '../../../common';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';
import { usePermissions } from '../../../stores';
import { WorkflowActionableStage } from '../../models';
import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from './style';
import ForceActivateStageDialog from './force-activate-stage-dialog';

interface Props {
	className?: string;
	stage: WorkflowActionableStage;
}

const ForceActivateButton = (props: Props) => {
	const { className, stage } = props;
	const modalStack = useModalCreator();
	const permissions = usePermissions();

	const openForcedActivationModal = useCallback(() => {
		modalStack.addModal(<ForceActivateStageDialog stage={stage} />);
	}, [stage, modalStack]);

	if (
		!permissions.canForceActivateStage(stage) ||
		stage.status === StageStatus.active ||
		stage.status === StageStatus.roadblocked
	) {
		return null;
	}

	const buttonId = `forceActivateStage${stage._id}`;
	return (
		<ActionButtonContainer
			className={buildClassList('stage-action force-activate-stage', className)}
		>
			<StyledTooltip target={buttonId}>
				Forcibly set "{stage.title}" status to "active"
			</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				className="action-button force-activate-stage-button solid"
				onClick={openForcedActivationModal}
			>
				<IconWithRightMargin icon={faFastForward} />
				Force activate
			</ActionButton>
		</ActionButtonContainer>
	);
};

export default ForceActivateButton;
