import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import {
	PrimaryButton,
	SecondaryButton,
} from '../../../styled-components/elements/button';
import { ConfirmationDialog } from '../../../components/modals';
import ScrollTable from '../../../styled-components/elements/scroll-table';
import ScrollCard from '../../../styled-components/elements/scroll-card';

import windowModel from '../../../models/WindowModel';
import { useModalCreator } from '../../../stores/ModalStack';
import { Theme } from '../../../theme/models/ThemeModel';
import themeStore from '../../../theme/models/ThemeStore';
import { Cell, CenteredCell, CenteredHeadingCell, Image } from './style';

const { selectedTheme } = themeStore;

const renderNoThemesRow = () => (
	<tr>
		<Cell colSpan={3}>
			<p>No themes</p>
		</Cell>
	</tr>
);

const ThemeTable = () => {
	const modalStack = useModalCreator();

	const setTheme = useCallback((themeId: string) => {
		themeStore.setSelectedWithId(themeId);
		windowModel.reload();
	}, []);

	const showConfirmationDialog = useCallback(
		(themeId: string) => {
			const theme = themeStore.getThemeWithId(themeId);
			modalStack.addModal(
				<ConfirmationDialog
					header="Change theme"
					onConfirm={() => setTheme(themeId)}
				>
					<p>Do you want to set the theme to "{theme!.title}"?</p>
					<p>Once you confirm, the browser will reload this page.</p>
				</ConfirmationDialog>
			);
		},
		[modalStack, setTheme]
	);

	const renderThemeRow = (theme: Theme) => (
		<tr key={theme._id}>
			<Cell>{theme.title}</Cell>
			<CenteredCell>
				<div>
					<PrimaryButton style={{ backgroundColor: theme.colors.primary }}>
						Primary color
					</PrimaryButton>
				</div>
				<div className="mt-2">
					<SecondaryButton style={{ backgroundColor: theme.colors.secondary }}>
						Secondary color
					</SecondaryButton>
				</div>
			</CenteredCell>
			<CenteredCell>
				<Image
					style={{ backgroundImage: `url('${theme.images.desktopLogo}')` }}
				/>
			</CenteredCell>
			<CenteredCell>
				<Image
					style={{ backgroundImage: `url('${theme.images.mobileLogo}')` }}
				/>
			</CenteredCell>
			<CenteredCell>
				<Image
					style={{ backgroundImage: `url('${theme.images.loginBackground}')` }}
				/>
			</CenteredCell>
			<CenteredCell>
				<input
					type="checkbox"
					value={theme._id}
					checked={theme._id === selectedTheme._id}
					onChange={() => showConfirmationDialog(theme._id)}
				/>
			</CenteredCell>
		</tr>
	);

	const render = () => (
		<ScrollCard>
			<ScrollTable>
				<thead>
					<tr>
						<th>Name</th>
						<CenteredHeadingCell>Colors</CenteredHeadingCell>
						<CenteredHeadingCell>Logo (desktop)</CenteredHeadingCell>
						<CenteredHeadingCell>Logo (mobile)</CenteredHeadingCell>
						<CenteredHeadingCell>Login image</CenteredHeadingCell>
						<CenteredHeadingCell>Active</CenteredHeadingCell>
					</tr>
				</thead>
				<tbody>
					{themeStore.themes.length
						? themeStore.themes.map((theme) => renderThemeRow(theme))
						: renderNoThemesRow()}
				</tbody>
			</ScrollTable>
		</ScrollCard>
	);

	return useObserver(render);
};

export default ThemeTable;
