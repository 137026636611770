import React from 'react';
import { useObserver } from 'mobx-react';
import { ListColumn } from '../list-column';
import ScrollTable from '../../styled-components/elements/scroll-table';
import { Cell } from '../../styled-components/blocks/table';

export interface ListTableProps<T> {
	columns: ReadonlyArray<ListColumn<T>>;
	rows?: ReadonlyArray<T>;
	noResultsLabel?: string;
}

const renderRows = <T extends Identifiable>(
	rows: ReadonlyArray<T>,
	columns: ReadonlyArray<ListColumn<T>>
) => rows.map((r) => <tr key={r._id}>{columns.map((c) => c.render(r))}</tr>);

const renderNoResultsRow = (label?: string) => {
	if (!label) {
		label = 'Nothing here yet';
	}

	return (
		<tr>
			<Cell className="pt-4">{label}</Cell>
		</tr>
	);
};

const ListTable = <T extends Identifiable>(props: ListTableProps<T>) => {
	const { columns, rows, noResultsLabel } = props;

	return useObserver(() => (
		<ScrollTable>
			<thead>
				<tr>{columns.map((c) => c.renderHeader())}</tr>
			</thead>
			<tbody>
				{rows?.length
					? renderRows(rows, columns)
					: renderNoResultsRow(noResultsLabel)}
			</tbody>
		</ScrollTable>
	));
};

export default ListTable;
