import React from 'react';
import { useObserver } from 'mobx-react';

import { shouldShow } from '../../../common';
import {
	TemplateParallelStage,
	TemplateParallelStageModel,
	TemplateSingleStage,
	TemplateSingleStageModel,
	TemplateStage,
} from '../../models';

import TemplateSingleStageCard from '../template-single-stage-card';
import TemplateParallelStageCard from '../template-parallel-stage-card';

// interfaces
export interface TemplateStageCardProps<T extends TemplateStage> {
	stage: T;
	editable?: boolean;
	peeking?: boolean;
}

// component
const TemplateStageCard = <T extends TemplateStage>(
	props: TemplateStageCardProps<T>
) => {
	return useObserver(() => {
		if (shouldShow(props.stage)) {
			if (TemplateParallelStageModel.is(props.stage)) {
				return (
					<TemplateParallelStageCard
						{...(props as TemplateStageCardProps<TemplateParallelStage>)}
					/>
				);
			} else if (TemplateSingleStageModel.is(props.stage)) {
				return (
					<TemplateSingleStageCard
						{...(props as TemplateStageCardProps<TemplateSingleStage>)}
					/>
				);
			}
		}

		return null;
	});
};

export default TemplateStageCard;
