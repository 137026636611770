import React from 'react';
import { useObserver } from 'mobx-react';
import { getParentOfType } from 'mobx-state-tree';
import { Link } from '@reach/router';
import Moment from 'react-moment';
import { Card, CardFooter, CardHeader } from 'reactstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { buildStageUrl } from '../../../common/links';
import SmallCTALink from '../../../components/small-cta-link';
import { SmallTextMuted } from '../../../styled-components/elements/small';
import { useWorkflowStore } from '../../../stores';

import themeStore from '../../../theme/models/ThemeStore';
import { Roadblock, WorkflowModel } from '../../../workflows/models';

import { Avatar, CommentItem, RoadblockCardBody } from './style';
import P from '../../../styled-components/elements/p';

const { colors } = themeStore.selectedTheme;

interface RoadblocksPanelProps {
	link: string;
}

const RoadblocksPanel = (props: RoadblocksPanelProps) => {
	const workflowStore = useWorkflowStore();

	function workflowLink(roadblock: Roadblock) {
		const workflow = getParentOfType(roadblock.stage, WorkflowModel);

		return (
			<Link
				to={buildStageUrl(workflow, roadblock.stage)}
				style={{ color: `${colors.primary}` }}
			>
				{roadblock.stage.title} (in {workflow.title})
			</Link>
		);
	}

	const renderedRoadblocks = useObserver(() => (
		<>
			{workflowStore.activeRoadblocks.length ? (
				workflowStore.activeRoadblocks
					.slice(0, 4)
					.map((roadblock: Roadblock) => (
						<CommentItem key={roadblock.stage._id}>
							<Avatar
								src={roadblock.event.createdBy.avatar}
								alt={roadblock.event.createdBy.name}
							/>
							<div>
								<p>
									{workflowLink(roadblock)}
									<small className="text-danger">
										{' '}
										- <Moment fromNow date={roadblock.event.createdAt} />
									</small>
								</p>
								<SmallTextMuted>{roadblock.event.message}</SmallTextMuted>
							</div>
						</CommentItem>
					))
			) : (
				<p className="p-4">No roadblocks yet.</p>
			)}
		</>
	));

	return (
		<Card className="h-100">
			<CardHeader>
				<P className="text-danger mb-0">
					<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
					Roadblocks
				</P>
			</CardHeader>

			<RoadblockCardBody>{renderedRoadblocks}</RoadblockCardBody>

			<CardFooter className="bg-white border-top-0">
				<div className="float-right">
					<SmallCTALink link={props.link} label="View all roadblocks" />
				</div>
			</CardFooter>
		</Card>
	);
};
export default RoadblocksPanel;
