import React from 'react';

import { _logError } from '../../../common/log';
import { buildStageUrl } from '../../../common/links';
import { isIdentifiableLoaded } from '../../../common';
import { Workflow, WorkflowActionableStage } from '../../../workflows/models';
import { useWorkflowStore } from '../../../stores';

import { AssetVersion } from '../../models/AssetVersionModel';
import { StyledLink } from './style';

// interface
interface Props {
	workflow: Workflow;
	version: AssetVersion;
	showWorkflowLabel?: boolean;
}

// component
const LinkToAssetVersionStage = (props: Props) => {
	const workflowStore = useWorkflowStore();
	let stage: Maybe<WorkflowActionableStage>;

	// get stage
	try {
		stage = workflowStore.getStageWithAsset(props.version);
	} catch (error) {
		_logError(error);
	}

	const buildLabel = (stage: WorkflowActionableStage) => {
		let label = '';
		if (props.showWorkflowLabel) {
			label = `${props.workflow.title} (${stage.title})`;
		} else {
			label = stage.title;
		}
		return label;
	};

	return isIdentifiableLoaded(stage) ? (
		<StyledLink to={buildStageUrl(props.workflow, stage)}>
			{buildLabel(stage!)}
		</StyledLink>
	) : null;
};

export default LinkToAssetVersionStage;
