import styled from 'styled-components';
import { Link } from '@reach/router';
import { fsMedium } from '../../../theme/ui/typography';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const Heading = styled.h1`
	color: ${colors.darkestGrey};
	font-size: 16px;
	margin-bottom: 0;
`;

export const StyledLink = styled(Link)`
	${fsMedium};
	color: ${colors.primary};
	margin-top: 4px;

	&:hover {
		color: ${colors.primaryHighlighted};
	}
`;
