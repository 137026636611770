import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { FormFeedback } from 'reactstrap';

import { AssetMetadata } from '../../dam-assets/models/AssetMetadataModel';
import { stubTrue } from '../../common';
import { EntityMetadata } from '../../metadata/models/EntityMetadataModel';
import { SelectionActions } from '../downshift-select/downshift.interfaces';

import { DownshiftMultiFreeform } from '../downshift-select/';

// interface
interface TagInputProps {
	metadata: EntityMetadata | AssetMetadata;
}

// component
const TagInput = ({ metadata }: TagInputProps) => {
	const tags = metadata.tags;
	const [valid, setValid] = useState(true);

	const removeTag = (tag: string) => {
		metadata.removeTag(tag);
	};

	const addTag = (tag: MaybeNull<string>) => {
		if (tag && tags.indexOf(tag) <= -1) {
			setValid(true);
			metadata.addTag(tag);
		} else {
			setValid(false);
		}
	};

	const selectionActions: SelectionActions<string, string> = {
		select: addTag,
		unselect: removeTag,
	};

	return useObserver(() => {
		const selectionState = {
			selection: tags,
			options: [],
			searchPredicate: stubTrue,
		};

		return (
			<>
				<DownshiftMultiFreeform
					label="Tags"
					placeholder="Add a tag..."
					selectionState={selectionState}
					selectionActions={selectionActions}
				/>
				<FormFeedback invalid="true" className={valid ? 'd-none' : 'd-block'}>
					That tag was already added
				</FormFeedback>
			</>
		);
	});
};

export default TagInput;
