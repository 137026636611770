import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';
import { fsSmol } from '../../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

const EntityPropLabel = styled.div`
	${fsSmol};
	color: ${colors.darkerGrey};
`;

export default EntityPropLabel;
