import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import { QueryTypes } from '../../../queries/models/QueryStore';
import { useQueryStore } from '../../../stores';
import NotificationModel from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';

import { SecondaryButton } from '../../../styled-components/elements/button';
import { connectStateResults } from 'react-instantsearch-dom';
import { SearchState } from 'react-instantsearch-core';

const _SaveAlgoliaQueryButton = ({
	searchState,
	type,
	className,
}: {
	searchState: SearchState;
	className: string;
	type: QueryTypes;
}) => {
	const queryStore = useQueryStore();

	const saveQuery = () => {
		if (searchState.query) {
			queryStore.addNew(searchState.query, type);

			notificationStore.push(
				NotificationModel.create({
					text: `Saved query "${searchState.query}".`,
				})
			);
		}
	};

	const render = () => (
		<SecondaryButton
			className={`save-query-button ${className}`}
			onClick={saveQuery}
			disabled={!searchState.query}
		>
			<FontAwesomeIcon icon={faSave} className="mr-2" />
			<span className="button-label">Save query</span>
		</SecondaryButton>
	);

	return render();
};

const SaveAlgoliaQueryButton = connectStateResults(_SaveAlgoliaQueryButton);
export default SaveAlgoliaQueryButton;
