import themeStore from '../../theme/models/ThemeStore';
import styled from 'styled-components';

const { images } = themeStore.selectedTheme;

export const Logo = styled.figure`
	display: inline-block;
	background: url(${images.desktopLogo}) center center / contain no-repeat;
	width: 140px;
	height: 70px;
	margin-bottom: 2em;
`;
