import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardText, Col, Row } from 'reactstrap';
import { FileInput } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { UppyFile } from '@uppy/utils';
import { useObserver } from 'mobx-react';
import { _logError } from '../../../common/log';
import { UploadResult } from '@uppy/core';
import { SecondaryButton } from '../../../styled-components/elements/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { useFileUploadProvider } from '../../../stores';
import { FileInputWrapper } from './style';
import XLSX from 'xlsx';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import { ProductTemplate } from '../../models/ProductTemplateInterface';

const supportedFileFormats = ['.xls', '.xlsx'];

const productConfig = {
	id: 'aex-assets',
	meta: { type: 'file' },
	restrictions: {
		maxNumberOfFiles: 1,
		allowedFileTypes: supportedFileFormats,
	},
	autoProceed: true,
};

interface Props {
	setProductProgress: React.Dispatch<
		React.SetStateAction<{
			documentUploaded: boolean;
			templateSelected: boolean;
		}>
	>;
	setSheetHeaders: React.Dispatch<React.SetStateAction<string[]>>;
	setSubmissionData: React.Dispatch<
		React.SetStateAction<ProductSubmissionData | undefined>
	>;
	setTemplateSelected: React.Dispatch<
		React.SetStateAction<ProductTemplate | undefined>
	>;
}

const ProductsFileUpload = ({
	setProductProgress,
	setSheetHeaders,
	setSubmissionData,
	setTemplateSelected,
}: Props) => {
	const uploadProvider = useFileUploadProvider(productConfig);
	const uppy = uploadProvider;
	const files = uppy.getFiles();
	const [numFiles, setNumFiles] = useState(files.length);
	const [file, setFile] = useState<any>();

	const readFile = useCallback(
		(file: any) => {
			const reader = new FileReader();

			reader.addEventListener('load', (event) => {
				const fileFromEvent = event?.target?.result;
				setFile(fileFromEvent);
				if (typeof fileFromEvent === 'string') {
					const fileToRead = fileFromEvent?.split('base64,')[1];
					const wb = XLSX.read(fileToRead);
					const wbKeys = Object.keys(wb.Sheets)[0];
					const sheetKeys = Object.keys(wb.Sheets[wbKeys]);
					const headerValues = sheetKeys
						.filter((key) => /^[A-Z]*1$/.test(key))
						.map((key) => {
							return wb.Sheets[wbKeys][key].v;
						});

					setSheetHeaders(headerValues);
				}
			});

			reader.readAsDataURL(file);
		},
		[setSheetHeaders]
	);

	const onUpload = useCallback(
		(result: UploadResult) => {
			const filesNumber = result?.successful?.length;
			readFile(result?.successful?.[0].data);
			setProductProgress((c) => {
				return {
					...c,
					documentUploaded: !!filesNumber,
				};
			});
			setNumFiles(filesNumber);
			setSubmissionData({ file: result?.successful?.[0].data });
		},
		[readFile, setProductProgress, setSubmissionData]
	);

	useEffect(() => {
		uppy.on('complete', onUpload);

		return () => {
			uppy.off('complete', onUpload);
			uppy.reset();
		};
	}, [uppy, onUpload]);

	/* eslint-disable */
	// clear only works when given
	// an empty dependency array
	const clearInputSlot = useCallback((uf: UppyFile) => {
		try {
			uppy.removeFile(uf.id);
			setNumFiles(files.length);
			setSheetHeaders([]);
			setSubmissionData({ data: { productTemplateId: '' } });
			setTemplateSelected(undefined);
			setProductProgress((c) => {
				return {
					...c,
					documentUploaded: !!files.length,
				};
			});
		} catch (error) {
			_logError(error);
		}
	}, []);
	/* eslint-enable */

	const renderAssetCard = () => {
		return (
			<>
				{files.map((uf: UppyFile) => (
					<Row key={uf.id}>
						<Col>
							<Card className="h-100 p-2 justify-content-center">
								<CardText>
									<a href={file}>
										<FontAwesomeIcon icon={faFileExcel} /> {uf.name}
									</a>
								</CardText>
							</Card>
						</Col>
						<Col>
							<SecondaryButton onClick={() => clearInputSlot(uf)}>
								<FontAwesomeIcon icon={faSyncAlt} />
								<span className="ml-2">Select Another</span>
							</SecondaryButton>
						</Col>
					</Row>
				))}
			</>
		);
	};

	const renderUploadButton = () => (
		<FileInputWrapper>
			<FileInput
				uppy={uppy}
				pretty={true}
				locale={{
					strings: {
						chooseFiles: `Select From Computer`,
					},
				}}
			/>
		</FileInputWrapper>
	);

	const render = () => {
		if (files.length !== numFiles) {
			setNumFiles(files.length);
		}

		return numFiles ? renderAssetCard() : renderUploadButton();
	};

	return useObserver(render);
};

export default ProductsFileUpload;
