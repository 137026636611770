import React, { FormEvent, useCallback, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Form, FormFeedback } from 'reactstrap';

import { _logError } from '../../../common/log';
import { doesUserMatch, User } from '../../../accounts/models/UserModel';
import SubmitButton from '../../../components/submit-button';
import { useModalCloser } from '../../../stores/ModalStack';
import { useAssetStore, useUserStore } from '../../../stores';
import {
	DownshiftMultiSelectProps,
	DownshiftMultiSelect,
} from '../../../components/downshift-select';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';

import { ShareDialogComponentProps } from './index';

const SelectUsersForm = (props: ShareDialogComponentProps) => {
	const { link } = props;

	const assetStore = useAssetStore();
	const userStore = useUserStore();
	const modalStack = useModalCloser();

	const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
	const [errorMessage, setErrorMessage] = useState('');

	const selectUser = useCallback((user: Nullable<User>) => {
		if (user) {
			setSelectedUsers((selectedUsers) => selectedUsers.concat(user));
		}
	}, []);

	const deselectUser = useCallback((user: User) => {
		setSelectedUsers((selectedUsers) =>
			selectedUsers.filter((u) => u._id !== user._id)
		);
	}, []);

	const isNotSelected = useCallback(
		(user: User) => !selectedUsers.includes(user),
		[selectedUsers]
	);

	const notifyUsers = async (event: FormEvent) => {
		event.preventDefault();

		// validation
		if (!selectedUsers.length) {
			setErrorMessage('Please ensure to select one or more users.');
			return;
		}

		// clear error message
		setErrorMessage('');

		// call api
		try {
			await assetStore.share(link, selectedUsers);

			if (props.onSuccess) {
				props.onSuccess();
			}
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred, unable to notify users are this time. Please try again later.`,
				})
			);

			modalStack.closeModal();
		}

		return false;
	};

	const render = () => {
		const selectProps: DownshiftMultiSelectProps<User, User> = {
			label: 'Users',
			selectionState: {
				selection: selectedUsers,
				options: userStore.all.filter(isNotSelected),
				searchPredicate: doesUserMatch,
			},
			selectionActions: {
				select: selectUser,
				unselect: deselectUser,
			},
		};

		return (
			<Form>
				<DownshiftMultiSelect {...selectProps} />
				{errorMessage ? (
					<FormFeedback invalid="true" className="d-block">
						{errorMessage}
					</FormFeedback>
				) : null}
				<SubmitButton onClick={notifyUsers} label="Notify users" />
			</Form>
		);
	};

	return useObserver(render);
};

export default SelectUsersForm;
