import React, { FormEvent, useState } from 'react';
import { Form } from 'reactstrap';

import { _logError } from '../../../common/log';
import { SelectedWorkflowStage } from '../../workflow.contexts';
import { useUserStore } from '../../../stores';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';

import { NewComment, WorkflowParallelStageModel } from '../../models';

import { CommentContainer, CommentInput } from './style';
import { CommentAvatar } from '../../../styled-components/elements/avatar';

const CommentForm = () => {
	const stage = SelectedWorkflowStage.presentValue;
	const { currentUser } = useUserStore();

	const initialFormState: NewComment = {
		message: '',
	};

	const [comment, setComment] = useState(initialFormState);

	const onChange = (event: any) => {
		const { value } = event.target;
		setComment({ message: value });
	};

	const commentFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!comment.message.trim()) {
			return;
		}

		if (!WorkflowParallelStageModel.is(stage)) {
			try {
				await stage.addComment(comment);
				setComment({ message: '' });
			} catch (error) {
				_logError(error);
				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `Unable to post your comment, an issue occurred while saving. Please try again later.`,
					})
				);
			}
		}
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			commentFormSubmit(event).catch(_logError);
		}
	};

	return (
		<CommentContainer>
			<CommentAvatar image={currentUser.avatar} />
			<Form onSubmit={commentFormSubmit} className="w-100">
				<CommentInput
					type="textarea"
					name="message"
					value={comment.message}
					placeholder="Write a comment..."
					onChange={onChange}
					onKeyDown={onKeyDown}
				/>
			</Form>
		</CommentContainer>
	);
};

export default CommentForm;
