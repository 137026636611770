import React, {
	ChangeEvent,
	RefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useObserver } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { doesNameableMatch } from '../../../common';
import CircleButton from '../../../components/circle-button';
import { DownshiftSingleSelect } from '../../../components/downshift-select';
import LabeledInput from '../../../components/labeled-input';
import TagInput from '../../../components/tag-input';
import Subtitle from '../../../styled-components/elements/subtitle';
import { useMetadataTemplateStore } from '../../../stores';

import { EntityMetadata } from '../../models/EntityMetadataModel';

import { CircleButtonContainer } from './style';
import ExistingMetadataFields from '../existing-metadata-fields';
import { MetadataTemplate } from '../../../metadata-templates/models/EntityMetadataTemplateModel';
import { SelectionActions } from '../../../components/downshift-select/downshift.interfaces';

const EntityMetadataForm = (props: {
	metadata: EntityMetadata;
	displayTemplateField?: boolean;
}) => {
	const { metadata, displayTemplateField } = props;

	const metadataTemplateStore = useMetadataTemplateStore();

	const [selectedTemplate, setSelectedTemplate] = useState<
		MaybeNull<MetadataTemplate>
	>();
	const [newKvPair, setNewKvPair] = useState({ key: '', value: '' });
	const [valid, setValid] = useState(true);

	const ref: RefObject<HTMLInputElement> = useRef(null);

	useEffect(() => {
		if (selectedTemplate) {
			metadata.applyTemplate(selectedTemplate);
		}
	}, [selectedTemplate, metadata]);

	const handleAddField = () => {
		const { key, value } = newKvPair;

		if (key && metadata.fields.indexOf(key) <= -1) {
			metadata.setKvPair(key, value);
			setNewKvPair({ key: '', value: '' });
			setValid(true);
		} else {
			setValid(false);
		}
	};

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		setNewKvPair({ ...newKvPair, [name]: value });
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			handleAddField();

			if (ref.current) {
				(event.target as HTMLInputElement).blur();
				ref.current.focus();
			}
		}
	};

	const renderMetadataTemplateSelect = () => {
		if (displayTemplateField !== undefined && !displayTemplateField) {
			return null;
		}

		return (
			<Col md={12} className="mt-3">
				<DownshiftSingleSelect
					label="Metadata Template"
					placeholder="Search by name..."
					selectionState={{
						selection: selectedTemplate,
						options: [...metadataTemplateStore.allMetadataTemplates],
						searchPredicate: doesNameableMatch,
					}}
					selectionActions={
						{
							select: setSelectedTemplate,
						} as SelectionActions<MetadataTemplate, MetadataTemplate>
					}
				/>
			</Col>
		);
	};

	const render = () => (
		<>
			<Row>
				{renderMetadataTemplateSelect()}
				<Col md={12} className="mt-5 mb-1">
					<Subtitle>Metadata</Subtitle>
					<hr />
				</Col>
			</Row>

			<ExistingMetadataFields metadata={metadata} />

			<Row className="mt-3">
				<Col md={4} xs={10}>
					<LabeledInput
						label="Field name"
						type="text"
						name="key"
						id="metadataKey"
						value={newKvPair.key}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyDown}
						innerRef={ref}
						inputValid={valid}
						errorMsg={
							newKvPair.key
								? 'This field was already added'
								: 'This field is required'
						}
					/>
				</Col>
				<Col md={5} xs={10}>
					<LabeledInput
						label="Field value"
						type="text"
						name="value"
						id="metadataValue"
						value={newKvPair.value}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyDown}
					/>
				</Col>
				<Col md={3} xs={2}>
					<CircleButtonContainer>
						<CircleButton
							id="addMetadataItem"
							className="sm"
							icon={faPlus}
							onClick={handleAddField}
							tooltip="Add metadata entry"
						/>
					</CircleButtonContainer>
				</Col>
			</Row>

			<TagInput metadata={metadata} />
		</>
	);

	return useObserver(render);
};

export default EntityMetadataForm;
