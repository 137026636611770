import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';
import { boxShadowFocus } from '../ui/ui';
import { ffTextBody, fsNormal } from '../ui/typography';

const { colors } = themeStore.selectedTheme;

const inputField = css`
	${ffTextBody};
	${fsNormal};
	background-color: ${colors.white};
	border: 1px solid ${colors.darkGrey} !important;
	line-height: 20px;
	text-rendering: optimizeLegibility;
	padding: 12px !important;
	border-radius: 2px !important;

	&:focus {
		${boxShadowFocus};
		border: 1px solid ${colors.primaryHighlighted} !important;
	}

	&:disabled {
		background-color: ${colors.lightGrey} !important;
		cursor: not-allowed;
	}

	&:read-only {
		cursor: not-allowed;
	}
`;

export default inputField;
