import React from 'react';
import { Col, Row } from 'reactstrap';
import { LoadingContainer, LoadingDot } from './style';

// interfaces
interface Props {
	label?: string;
}

// components
export const LoadingIndicator = () => (
	<LoadingContainer>
		<LoadingDot>•</LoadingDot>
	</LoadingContainer>
);

export const LoadingRow = (props: Props) => {
	const label = props.label ? props.label : 'Loading...';

	return (
		<Row>
			<Col xs={12}>
				<p>{label}</p>
			</Col>
		</Row>
	);
};

export default LoadingIndicator;
