import React from 'react';
import themeStore from '../../theme/models/ThemeStore';
const { colors } = themeStore.selectedTheme;

const ArrowIcon = (isOpen: { isOpen: boolean }) => (
	<svg
		viewBox="0 0 20 20"
		preserveAspectRatio="none"
		width={16}
		fill="transparent"
		stroke={colors.darkerGrey}
		strokeWidth="1.1px"
		transform={isOpen.isOpen ? 'rotate(180)' : 'rotate(0)'}
	>
		<path d="M1,6 L10,15 L19,6" />
	</svg>
);

export default ArrowIcon;
