import React from 'react';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { capitalizeFirstLetter } from '../../../common';
import {
	EntityPropLabel,
	EntityPropListItem,
} from '../../../styled-components/blocks/entity-props';
import TagBadge from '../../../styled-components/elements/tag-badge';
import { AssetVersion } from '../../models/AssetVersionModel';

const AssetMetadataProps = (props: { asset: AssetVersion }) => {
	const { asset } = props;

	let metadataFields: Array<any> = [];
	asset.metadata.values.forEach((value, key) => {
		metadataFields.push(
			<EntityPropListItem key={key}>
				<EntityPropLabel>{capitalizeFirstLetter(key)}</EntityPropLabel>
				<p>{value}</p>
			</EntityPropListItem>
		);
	});

	return (
		<>
			{metadataFields}
			{asset.metadata.tags.length ? (
				<EntityPropListItem>
					<EntityPropLabel>
						<FontAwesomeIcon icon={faTag} /> Tags
					</EntityPropLabel>
					{asset.metadata.tags.map((tag) => (
						<TagBadge key={tag}>{tag}</TagBadge>
					))}
				</EntityPropListItem>
			) : null}
		</>
	);
};

export default AssetMetadataProps;
