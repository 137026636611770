import styled from 'styled-components';
import { Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const TaskContainer = styled.div`
	display: inline-block;
	margin-left: 15px;
`;

export const Detail = styled.p`
	font-size: 12px;
	color: ${colors.darkerGrey};
	margin-bottom: 0;
`;

export const TaskCard = styled(Card)`
	&:hover {
		background-color: ${colors.lightestGrey};
	}
`;

export const TaskCardBody = styled(CardBody)`
	&& {
		padding: 10px;
	}
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
	font-size: 13px;
	color: ${colors.darkerGrey};
`;
