import React from 'react';
import themeStore from '../../theme/models/ThemeStore';
const { colors } = themeStore.selectedTheme;

const XIcon = () => (
	<svg
		viewBox="0 0 20 20"
		preserveAspectRatio="none"
		width={12}
		fill="transparent"
		stroke={colors.darkerGrey}
		strokeWidth="1.1px"
	>
		<path d="M1,1 L19,19" />
		<path d="M19,1 L1,19" />
	</svg>
);

export default XIcon;
