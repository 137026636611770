import React from 'react';
import { useObserver } from 'mobx-react';
import { ControllerStateAndHelpers, GetInputPropsOptions } from 'downshift';

import { getId, getName } from '../../common';
import { useDownshift } from './downshift-options.context';
import { ItemRenderer } from './downshift.interfaces';

import { BorderlessInput, TagInputField } from '../tag-input/style';
import { DownshiftToggleButton, RemoveItemButton } from './downshift-buttons';
import { Chip } from '../../styled-components/blocks/chip';
import { DownshiftMultiSelectProps } from './downshift-multi-select';

interface TagChipProps<Item extends Displayable> {
	item: Item;
	renderItem: ItemRenderer<Item>;
	canRemove: boolean;
}

const DefaultTagChip = <Item extends Displayable>(
	props: TagChipProps<Item>
) => {
	const { item, renderItem, canRemove } = props;

	const removeItemButton = canRemove ? <RemoveItemButton item={item} /> : null;

	return useObserver(() => (
		<Chip>
			{renderItem(item)}
			{removeItemButton}
		</Chip>
	));
};

export interface DownshiftTagInputProps<
	Item extends Displayable,
	Option extends Displayable
> extends DownshiftMultiSelectProps<Item, Option> {
	inputProps?: (
		downshift: ControllerStateAndHelpers<Option>
	) => GetInputPropsOptions;
}

// component
export const DownshiftTagInputField = <
	Item extends Displayable,
	Option extends Displayable
>(
	props: DownshiftTagInputProps<Item, Option>
) => {
	const {
		label,
		placeholder = `Add ${label?.toLowerCase()} by name...`,
		renderItem = getName as ItemRenderer<Item>,
		inputProps = () => {},
		selectionState,
		isRemovable = () => true,
	} = props;

	const { downshift, selection } = useDownshift<Item, Option>();

	const renderSelection = () => {
		if (Array.isArray(selection) && selection.length) {
			return selection.map((item: Item) => (
				<DefaultTagChip
					key={getId(item)}
					renderItem={renderItem}
					item={item}
					canRemove={isRemovable(item)}
				/>
			));
		}
		return null;
	};

	// type 'any' because type React.HTMLProps<HTMLInputElement>
	// is not compatible with BorderlessInput StyledComponent type
	// and GetInputPropsOptions is not assignable to StyledComponent type
	const downshiftInputProps: any = downshift.getInputProps(
		inputProps(downshift)
	);

	return useObserver(() => {
		return (
			<TagInputField>
				{renderSelection()}

				<BorderlessInput placeholder={placeholder} {...downshiftInputProps} />

				{selectionState.options.length ? <DownshiftToggleButton /> : null}
			</TagInputField>
		);
	});
};
