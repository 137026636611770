import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const Menu = styled.nav`
	position: absolute;
	font-size: 16px;
	height: 26px;
	left: 0;
	margin: 30px auto;
	right: 0;
	text-align: center;
	width: 26px;
`;

export const MenuItem = styled.button`
	display: flex;
	position: absolute;
	align-items: center;
	background-color: ${colors.darkerGrey};
	border-radius: 100%;
	border: none;
	color: ${colors.white};
	font-size: 16px;
	height: 26px;
	justify-content: center;
	text-align: center;
	transform: scale(1, 1) translate3d(0, 0, 0);
	transition: transform ease-out 200ms;
	width: 26px;
	z-index: 2;

	&:hover {
		box-shadow: 2px 4px 24px ${colors.grey};
	}
`;

export const MenuOpen = styled.input`
	display: none;

	&:checked + .menu-open-button {
		background-color: ${colors.danger};
		transform: scale(0.6, 0.6) translate3d(0, 0, 0);
		transition-duration: 200ms;
		transition-timing-function: linear;

		&:hover {
			transform: scale(0.7, 0.7) translate3d(0, 0, 0);
		}

		.plus-sign {
			transform: translate3d(0, 0, 0) rotate(45deg);
		}
	}

	&:checked ~ .menu-item {
		transition-timing-function: cubic-bezier(0.935, 0, 0.34, 1.33);

		/* add approval stage button */
		&:nth-child(3) {
			transition-duration: 380ms;
			transform: translate3d(-40px, 0, 0);

			&:hover {
				box-shadow: 2px 4px 24px ${colors.grey};
				transform: scale(1.2, 1.2) translate3d(-32.5px, 0, 0);
			}
		}

		/* add parallel stage button */
		&:nth-child(4) {
			transition-duration: 380ms;
			transform: translate3d(40px, 0, 0);

			&:hover {
				box-shadow: 2px 4px 24px ${colors.grey};
				transform: scale(1.2, 1.2) translate3d(32.5px, 0, 0);
			}
		}

		/* add input stage button */
		&:nth-child(5) {
			transition-duration: 480ms;
			transform: translate3d(0, 40px, 0);

			&:hover {
				box-shadow: 2px 4px 24px ${colors.grey};
				transform: scale(1.2, 1.2) translate3d(0, 32.5px, 0);
			}
		}
	}
`;

export const MenuOpenButton = styled.label`
	display: flex;
	position: absolute;
	align-items: center;
	background-color: ${colors.darkerGrey};
	border-radius: 100%;
	border: none;
	color: ${colors.white};
	cursor: pointer;
	font-size: 16px;
	height: 26px;
	justify-content: center;
	text-align: center;
	transform: scale(1.1, 1.1) translate3d(0, 0, 0);
	transition-duration: 400ms;
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: transform ease-out 200ms;
	width: 26px;
	z-index: 3;

	&:hover {
		box-shadow: 2px 4px 24px ${colors.grey};
		transform: scale(1.2, 1.2) translate3d(0, 0, 0);
	}
`;
