import styled from 'styled-components';

export const List = styled.ul`
	padding-left: 0;
	list-style: none;
	margin-bottom: 0;
`;

export const ListItem = styled.li`
	margin-bottom: 16px;

	&:last-child {
		margin-bottom: 0;
	}
`;
