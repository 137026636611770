import styled from 'styled-components';
import { Link } from '@reach/router';

import themeStore from '../../theme/models/ThemeStore';

import { focus } from '../../theme/ui/ui';
const { colors } = themeStore.selectedTheme;

export const FooterContainer = styled.footer`
	transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	height: 100px;
	display: flex;
	align-items: center;
	margin-top: auto;
`;

export const StyledLink = styled(Link)`
	color: ${colors.primary};

	&:hover {
		color: ${colors.primaryHighlighted};
	}

	&:focus {
		${focus};
	}
`;
