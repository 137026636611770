import React, { useCallback } from 'react';
import { Alert, ModalBody } from 'reactstrap';

import AsyncDispatcher from '../../../components/async-dispatcher';
import { DialogModal } from '../../../components/modals';

import { useUserStore } from '../../../stores';
import { User } from '../../../accounts/models/UserModel';
import ShareLink from '../../../dam-assets/components/share-link';
import notificationStore from '../../../notifications/NotificationStore';
import { PBold } from '../../../styled-components/elements/p';

const renderErrorState = () => (
	<>
		<p>We're currently unable to obtain a password-reset link.</p>
		<p>
			Please try again later, or contact a system administrator if the issue
			persists.
		</p>
	</>
);

const PasswordLinkDialog = ({ user }: { user: User }) => {
	const userStore = useUserStore();

	const getResetLink = useCallback(
		() => userStore.createPasswordResetUrl(user._id),
		[userStore, user]
	);

	return (
		<DialogModal header={`Password Reset`}>
			<ModalBody>
				<p>This link can be used to reset the password for:</p>

				<div className="my-2">
					<PBold>
						{user.name} (<a href={`mailto:${user.email}`}>{user.email}</a>)
					</PBold>
				</div>

				<Alert color="warning">
					<span role="img" aria-label="Warning">
						⚠
					</span>
					️ Don't share this link with anyone else!
				</Alert>

				<AsyncDispatcher loader={getResetLink}>
					{{
						success: (resetLink) => (
							<ShareLink
								link={resetLink}
								onSuccess={() =>
									notificationStore.push({ text: 'Link copied to clipboard!' })
								}
							/>
						),
						error: renderErrorState,
					}}
				</AsyncDispatcher>
			</ModalBody>
		</DialogModal>
	);
};

export default PasswordLinkDialog;
