import React from 'react';
import { Container } from 'reactstrap';

import { AuthContext } from '../../core';
import { FooterContainer, StyledLink } from './style';

// component
const Footer = () => {
	const auth = React.useContext(AuthContext);

	const renderTermsLink = () => (
		<p className="text-muted float-right">
			<StyledLink to="/admin/terms">Terms &amp; Conditions</StyledLink>
		</p>
	);

	const render = () => (
		<FooterContainer>
			<Container fluid={true} className="clearfix">
				<p className="text-muted float-left">
					© {new Date().getFullYear()} Altavia
				</p>
				{auth.isAuthenticated ? renderTermsLink() : null}
			</Container>
		</FooterContainer>
	);

	return render();
};

export default Footer;
