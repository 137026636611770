import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';

import Subtitle from '../../../styled-components/elements/subtitle';
import { useUserStore } from '../../../stores';
import { PanelHeader } from './style';
import UserTable from './user-table';
import SideNavButton from '../side-nav-button';
import AddUserDialog from './add-user-dialog';
import { useModalCreator } from '../../../stores/ModalStack';
import ScrollCard from '../../../styled-components/elements/scroll-card';

const UsersPanel = () => {
	const userStore = useUserStore();
	const modalStack = useModalCreator();

	const showAddUserModal = useCallback(
		() => modalStack.addModal(<AddUserDialog />),
		[modalStack]
	);

	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">Users</Subtitle>

				{/*TODO: Add button back when inviting users is enabled (RRR-198)*/}
				<SideNavButton onClick={showAddUserModal} label="Invite User" />
			</PanelHeader>
			<ScrollCard className="mt-4">
				<UserTable users={userStore.all} />
			</ScrollCard>
		</article>
	);

	return useObserver(render);
};

export default UsersPanel;
