import themeStore from '../../../theme/models/ThemeStore';
import styled from 'styled-components';
import { Toast, ToastHeader } from 'reactstrap';

const { colors } = themeStore.selectedTheme;

export const StyledToast = styled(Toast)`
	margin-top: 2em;
	min-width: 200px;

	&:first-of-type {
		margin-top: 1em;
	}
`;

export const StyledHeader = styled(ToastHeader)`
	/* ensure class names match NotificationType */
	&.error {
		background-color: ${colors.danger};
		color: ${colors.white};

		.close {
			color: ${colors.white};
			opacity: 1;
		}
	}
	&.warning {
		background-color: ${colors.warning};
		color: ${colors.darkestGrey};
	}
	&.info {
		background-color: ${colors.secondary};
		color: ${colors.white};

		.close {
			color: ${colors.white};
			opacity: 1;
		}
	}
`;
