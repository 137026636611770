import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Col, Row } from 'reactstrap';

import { LoadingRow } from '../../../../components/loading-indicator';
import { resolveIdentifierWithTypeIn, shouldShow } from '../../../../common';
import themeStore from '../../../../theme/models/ThemeStore';
import {
	WorkflowParallelStageModel,
	WorkflowSingleStageModel,
	WorkflowStage,
	WorkflowSubstageModel,
} from '../../../models';
import {
	SelectedWorkflow,
	SelectedWorkflowStage,
} from '../../../workflow.contexts';
import StageTable from '../../stage-table';
import WorkflowDisabledMask from '../workflow-disabled-mask';
import StageDetailCard from '../workflow-stage-card';

const stagePluralTerm = `${themeStore._.stage.toLowerCase()}s`;

const StageIndexPanel = () => {
	const workflow = SelectedWorkflow.presentValue;

	const [selectedStage, setSelectedStage] = useState(
		workflow.activeStage as WorkflowStage
	);

	useEffect(() => {
		const hash = window.location.hash.replace('#', '');
		if (hash) {
			const resolved = resolveIdentifierWithTypeIn(
				[
					WorkflowSingleStageModel,
					WorkflowParallelStageModel,
					WorkflowSubstageModel,
				],
				workflow,
				hash
			);

			if (resolved) {
				window.history.replaceState(
					null,
					document.title,
					window.location.pathname
				);
				setSelectedStage(resolved);
			}
		}
	}, [workflow]);

	const renderStageAccessLogs = () =>
		shouldShow(selectedStage) ? <StageDetailCard /> : null;

	const renderDetails = () => (
		<Row>
			<Col xl={6} lg={7}>
				<StageTable />
			</Col>
			<Col xl={6} lg={5}>
				{renderStageAccessLogs()}
			</Col>
		</Row>
	);

	return useObserver(() => (
		<SelectedWorkflowStage.Provider value={[selectedStage, setSelectedStage]}>
			<WorkflowDisabledMask workflow={workflow} />
			{workflow ? (
				renderDetails()
			) : (
				<LoadingRow label={`Loading ${stagePluralTerm}...`} />
			)}
		</SelectedWorkflowStage.Provider>
	));
};

export default StageIndexPanel;
