import { Instance, SnapshotIn } from 'mobx-state-tree';
import { makeEntityStore } from '../models/EntityStore.model';
import { Report, ReportModel } from './ReportModel';
import { loadingValue } from '../common';

const $loadingReportTemplate = {
	_id: loadingValue,
	title: 'Loading...',
};

const ReportStoreInferred = makeEntityStore<
	ReportModel,
	Report,
	SnapshotIn<ReportModel>
>(ReportModel, 'workflow-reports', $loadingReportTemplate);

export interface ReportStoreModel extends Infer<typeof ReportStoreInferred> {}
export interface ReportStore extends Instance<ReportStoreModel> {}

export const ReportStoreModel: ReportStoreModel = ReportStoreInferred;
