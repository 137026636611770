import styled from 'styled-components';
import { fsSmol } from '../../../theme/ui/typography';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const DeleteChipButton = styled.span`
	${fsSmol};
	background-color: ${colors.white};
	border-radius: 100%;
	color: ${colors.darkerGrey};
	cursor: pointer;
	margin-left: 5px;
	padding: 0.25em 0.5em;

	svg {
		&& {
			vertical-align: initial;
		}
	}
`;

export default DeleteChipButton;
