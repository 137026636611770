import React, { FormEvent } from 'react';
import { useObserver } from 'mobx-react';
import { Form } from 'reactstrap';

import { unwrapEvent, usePatchers } from '../../../common';
import LabeledInput from '../../../components/labeled-input';
import SubmitButton from '../../../components/submit-button';
import EntityMetadataForm from '../../../metadata/components/entity-metadata-form';
import PhaseInput from './phase-input';
import { WorkflowTemplate } from '../../models';

const EditTemplateForm = (props: {
	selectedTemplate: WorkflowTemplate;
	buttonLabel: string;
	onSubmit: (event: FormEvent) => Promise<unknown>;
}) => {
	const { selectedTemplate, buttonLabel, onSubmit } = props;

	const template = selectedTemplate;

	const propertyPatcher = usePatchers(template);

	const render = () => {
		const isFormValid = () => {
			return template.title !== '';
		};

		return (
			<Form>
				<LabeledInput
					label="Title*"
					type="text"
					name="title"
					id="templateTitle"
					value={template.title}
					onChange={unwrapEvent(propertyPatcher('title'))}
					required
					inputValid={template.title !== ''}
					errorMsg="Title is required"
				/>

				<PhaseInput className="mt-3" template={template} />

				<EntityMetadataForm metadata={template.metadata} />

				<SubmitButton
					label={buttonLabel}
					onClick={onSubmit}
					disabled={!isFormValid()}
				/>
			</Form>
		);
	};

	return useObserver(render);
};

export default EditTemplateForm;
