import React from 'react';
import { capitalizeFirstLetter } from '../../../common';
import { SmallTextMuted } from '../../../styled-components/elements/small';
import { WorkflowHealth } from '../../../workflows/models';
import { Dot, Stat } from './style';

interface Props {
	numWorkflows: number;
	status: WorkflowHealth;
}

const WorkflowHealthIndicator = (props: Props) => (
	<>
		<Stat>
			<span>{props.numWorkflows}</span>
			<Dot className={props.status} />
		</Stat>
		<SmallTextMuted className="mt-2">
			{capitalizeFirstLetter(props.status)}
		</SmallTextMuted>
	</>
);

export default WorkflowHealthIndicator;
