import styled, { css } from 'styled-components';
import { NavItem as NavItemBase } from 'reactstrap';

import tabButton from '../../../theme/components/tab-button';
import themeStore from '../../../theme/models/ThemeStore';
import { fsNormal } from '../../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

// styles
const circularButton = css`
	position: relative;
	background-color: ${colors.darkerGrey};
	border-radius: 100%;
	border: none;
	color: ${colors.white};
	height: 28px;
	padding: 3px 0;
	transform: scale(1.1, 1.1) translate3d(0, 0, 0);
	transition-duration: 400ms;
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: transform ease-out 200ms;
	width: 28px;

	&:hover {
		box-shadow: 2px 4px 24px ${colors.grey};
		transform: scale(1.2, 1.2) translate3d(0, 0, 0);
	}
`;

// styled components
export const Line = styled.hr`
	border-top: 2px solid ${colors.primaryHighlighted} !important;
`;

export const Aside = styled.div`
	height: 100%;
	overflow: scroll;
`;

export const AsideContent = styled.div`
	padding: 20px 40px 0;

	@media only screen and (min-width: 768px) {
		padding: 20px 20px 0;
	}
`;

export const NavItem = styled(NavItemBase)`
	border: none;
`;

export const TabButton = styled.button`
	${tabButton};
`;

export const CircleButton = styled.button`
	${circularButton};
	left: 52%;
	margin-left: -14px;
`;

export const DeleteButton = styled.button`
	${circularButton};
	background-color: transparent;
	color: ${colors.darkerGrey};

	&:hover {
		background-color: ${colors.danger}
		color: ${colors.white};
	}
`;

export const AddInputButtonContainer = styled.div`
	display: block;
	margin-top: 1em;
	width: 100%;
`;

export const WarningListItem = styled.li`
	${fsNormal};
`;
