import React from 'react';
import { getParentOfType } from 'mobx-state-tree';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { buildStageUrl } from '../../../common/links';
import { _logError } from '../../../common/log';
import {
	Workflow,
	WorkflowModel,
	WorkflowStage,
} from '../../../workflows/models';
import themeStore from '../../../theme/models/ThemeStore';

import {
	Detail,
	StyledFontAwesomeIcon,
	TaskCard,
	TaskCardBody,
	TaskContainer,
} from './style';
import { Dot } from '../workflow-health-indicator/style';
import UnstyledLink from '../../../styled-components/elements/unstyled-link';

const workflowTerm = themeStore._.workflow.toLowerCase();

interface Props {
	stage: WorkflowStage;
}

const getWorkflow = (stage: WorkflowStage) => {
	let workflow: Maybe<Workflow> = undefined;

	try {
		workflow = getParentOfType(stage, WorkflowModel);
	} catch (error) {
		_logError(error);
	}

	return workflow;
};

const AssignmentItem = ({ stage }: Props) => {
	const workflow = getWorkflow(stage);

	const render = () => (
		<article className="assignment-item">
			{workflow ? (
				<TaskCard>
					<UnstyledLink to={buildStageUrl(workflow, stage)}>
						<TaskCardBody>
							<div className="d-flex justify-content-between align-items-center">
								<div className="d-flex align-items-center">
									<Dot className={stage.status} />
									<TaskContainer>
										<p className="mb-0">{stage.title}</p>
										<Detail>
											in {workflowTerm} "{workflow.title}"
										</Detail>
									</TaskContainer>
								</div>
								<StyledFontAwesomeIcon icon={faChevronRight} />
							</div>
						</TaskCardBody>
					</UnstyledLink>
				</TaskCard>
			) : null}
		</article>
	);

	return render();
};

export default AssignmentItem;
