import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Form } from 'reactstrap';

import { _logError } from '../../../common/log';
import {
	EntityPropList,
	EntityPropListItem,
} from '../../../styled-components/blocks/entity-props';
import LabeledInput from '../../../components/labeled-input';
import SubmitButton from '../../../components/submit-button';
import { useEditingFlow } from '../../../common';
import EntityMetadataForm from '../../../metadata/components/entity-metadata-form';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import themeStore from '../../../theme/models/ThemeStore';

import { AssetVersion } from '../../models/AssetVersionModel';

const assetTerm = themeStore._.asset.toLowerCase();

const loadingLabel = 'Loading...';

interface Props {
	asset: AssetVersion;
}

const EditAssetForm = (props: Props) => {
	const { asset } = props;
	const [updatedAsset, setUpdatedAsset] = useState(asset);
	const [
		propertyPatcher,
		getCurrentAssetSnapshot,
		setLastKnownGood,
	] = useEditingFlow(asset);

	const onChange = (event: ChangeEvent<any>) => {
		const { name, value } = event.target;
		setUpdatedAsset({ ...updatedAsset, [name]: value });
		propertyPatcher(name);
	};

	const updateAssetSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedAssetVersion = await asset.save(getCurrentAssetSnapshot());
			setLastKnownGood(updatedAssetVersion);

			notificationStore.push(
				NotificationModel.create({
					text: `${themeStore._.asset} saved!`,
				})
			);
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while updating ${assetTerm.toLowerCase()}. Please try again later.`,
				})
			);
		}

		return false;
	};

	const render = () => (
		<Form>
			<EntityPropList>
				<EntityPropListItem>
					<LabeledInput
						label="Display name"
						type="text"
						name="title"
						value={updatedAsset.title}
						onChange={onChange}
						readOnly={updatedAsset.title === loadingLabel}
					/>
				</EntityPropListItem>

				{/* TODO: enable editing privacy property (RRR-139) */}
			</EntityPropList>

			<EntityMetadataForm
				metadata={updatedAsset.metadata}
				displayTemplateField={false}
			/>

			<SubmitButton
				onClick={updateAssetSubmit}
				label={`Update ${assetTerm}`}
				type="button"
			/>
		</Form>
	);

	return useObserver(render);
};

export default EditAssetForm;
