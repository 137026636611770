import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import { unwrapEvent } from '../../../common';
import { useDashboardStore } from '../../../stores';
import { WorkflowStage } from '../../../workflows/models';
import { compareStageStatus } from '../../../models/StageStatusModel';

import SideBarFiltersNav from '../../../components/side-bar-filters-nav';
import AssignmentsTable from '../../components/assignments-table';
import Divider from '../../../styled-components/elements/divider';

// constants
const filterNavOptions = [
	{
		label: 'All assignments',
		value: undefined,
	},
	{
		label: 'Active',
		value: 'active',
	},
	{
		label: 'Pipeline',
		value: 'queue',
	},
	{
		label: 'Completed',
		value: 'completed',
	},
];

// component
const AssignmentsIndexView = () => {
	const dashboardStore = useDashboardStore();
	const [filter, setFilter] = useState();

	const render = () => {
		const ongoingStages = dashboardStore.recentWorkflows.flatMap(
			(wf) => wf.stagesOwnedByCurrentUser
		);

		const stagesResult: readonly WorkflowStage[] = filter
			? ongoingStages.filter(({ status }) => status === filter)
			: ongoingStages.sort(compareStageStatus);

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem active>My Assignments</BreadcrumbItem>
						</Breadcrumb>
						<Heading>My Assignments</Heading>
						<Divider />
					</Col>
					<Col md={3} lg={2}>
						<SideBarFiltersNav
							setFilter={unwrapEvent(setFilter)}
							options={filterNavOptions}
						/>
					</Col>
					<Col md={9} lg={10}>
						<AssignmentsTable stages={stagesResult} />
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default AssignmentsIndexView;
