import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import themeStore from '../../../theme/models/ThemeStore';
import {
	SelectedTemplate,
	SelectedTemplateStage,
} from '../../template.contexts';
import { TemplateRootStage, TemplateStage } from '../../models';
import BackingSvg from '../../svg/BackingSVG';
import {
	ButtonLink,
	FinalTemplateCanvas,
	FinalTemplateKeyWrapper,
	TemplateHeader,
} from './style';
import { StageWrapper } from '../template-editor/style';
import { canvasRootClass } from '../../svg/ElementStore';
import TemplateCloneButton from '../template-clone-button';
import TemplateFinalDetailsCard from './template-final-details-card';
import TemplateStageCard from '../template-stage-card';
import TemplatePhaseKey from '../template-phase-key';
import Heading from '../../../styled-components/elements/heading';
import InfoButton from './info-button';

const workflowTerm = themeStore._.workflow;

// TODO: make structure match editor component more closely.
const TemplateFinalDetails = () => {
	const [template] = SelectedTemplate.usePresent();

	const [selectedStage, setSelectedStage] = useState<Maybe<TemplateStage>>(
		undefined
	);

	// render methods
	const render = () => (
		<SelectedTemplateStage.Provider value={[selectedStage, setSelectedStage]}>
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem
								tag={Link}
								to=".."
							>{`${workflowTerm} Templates`}</BreadcrumbItem>
							<BreadcrumbItem active>{template.title}</BreadcrumbItem>
						</Breadcrumb>
						<TemplateHeader>
							<Heading
								className="cursor-pointer"
								onClick={() => setSelectedStage(undefined)}
							>
								{template.title}
								{selectedStage ? <InfoButton /> : null}
							</Heading>
							<div>
								<TemplateCloneButton />
								<ButtonLink
									to={`/admin/workflow/workflows/new#${template._id}`}
								>
									<FontAwesomeIcon icon={faPlus} className="mr-2" />
									Create a {workflowTerm.toLowerCase()} from this template
								</ButtonLink>
							</div>
						</TemplateHeader>
					</Col>
					<Col md={9}>
						<FinalTemplateKeyWrapper>
							<TemplatePhaseKey template={template} />
						</FinalTemplateKeyWrapper>
						<FinalTemplateCanvas className={canvasRootClass}>
							{template.stages.map((stage: TemplateRootStage) => (
								<StageWrapper key={stage._id}>
									<TemplateStageCard stage={stage} editable={false} />
								</StageWrapper>
							))}
							<BackingSvg />
						</FinalTemplateCanvas>
					</Col>
					<Col md={3}>
						<TemplateFinalDetailsCard />
					</Col>
				</Row>
			</Container>
		</SelectedTemplateStage.Provider>
	);

	return render();
};

export default TemplateFinalDetails;
