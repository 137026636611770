import React, { FormEvent } from 'react';
import { useObserver } from 'mobx-react';
import { Form, ModalBody, ModalFooter } from 'reactstrap';

import { useEditingFlow } from '../../../common';
import { _logError } from '../../../common/log';
import { SecondaryButton } from '../../../styled-components/elements/button';
import { DialogModal } from '../../../components/modals';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useModalCloser } from '../../../stores/ModalStack';
import OwnerSelect from '../../../workflow-templates/components/owner-select';
import themeStore from '../../../theme/models/ThemeStore';
import { WorkflowActionableStage } from '../../models';

interface Props {
	stage: WorkflowActionableStage;
}

const AssignStageOwnerDialog = ({ stage }: Props) => {
	const modalStack = useModalCloser();
	const stageTerm = themeStore._.stage;
	const ownerTerm = themeStore._.owner;

	const [, getCurrentStageSnapshot, setLastKnownGood] = useEditingFlow(stage);

	const updateStageSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedStage = await stage.setOwner(
				getCurrentStageSnapshot().owners
			);
			setLastKnownGood(updatedStage);

			modalStack.closeModal();

			notificationStore.push(
				NotificationModel.create({
					text: `${stageTerm} saved!`,
				})
			);
		} catch (error) {
			_logError(error);

			modalStack.closeModal();

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while updating ${stageTerm.toLowerCase()}. Please try again later.`,
				})
			);
		}
	};

	// TODO: Implement add user form and action [RRR-133]
	// const [collapse, setCollapse] = useState(false);
	// const toggle = () => setCollapse(!collapse);
	//
	// const addUser = useCallback(
	// 	(user: User) => {
	// 		userStore.addOne(user);
	// 		setCollapse(false);
	// 	},
	// 	[userStore, stage],
	// );

	return useObserver(() => (
		<DialogModal header={`Edit ${ownerTerm}s`}>
			<Form onSubmit={updateStageSubmit}>
				<ModalBody>
					<OwnerSelect ownable={stage} />

					{/*TODO: Implement add user form and action [RRR-133]*/}
					{/*<FormGroup className="mt-4">*/}
					{/*	<p>Not on the list?</p>*/}
					{/*	<MutedButton type="button" onClick={ toggle }>*/}
					{/*		<FontAwesomeIcon*/}
					{/*			icon={ faPlus }*/}
					{/*			className="mr-2"*/}
					{/*		/>*/}
					{/*		Invite new user*/}
					{/*	</MutedButton>*/}
					{/*	<Collapse isOpen={ collapse } className="mt-4">*/}
					{/*		<AddUserForm addUser={ addUser } />*/}
					{/*	</Collapse>*/}
					{/*</FormGroup>*/}
				</ModalBody>
				<ModalFooter>
					<SecondaryButton>Save</SecondaryButton>
				</ModalFooter>
			</Form>
		</DialogModal>
	));
};

export default AssignStageOwnerDialog;
