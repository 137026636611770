import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';

import Subtitle from '../../../styled-components/elements/subtitle';
import { useModalCreator } from '../../../stores/ModalStack';

import SideNavButton from '../side-nav-button';
import AddGroupDialog from './add-group-dialog';

import { PanelHeader } from '../users-panel/style';
import UserGroupsTable from './user-groups-table';
import ScrollCard from '../../../styled-components/elements/scroll-card';

const UserGroupsPanel = () => {
	const modalStack = useModalCreator();

	const showAddGroupModal = useCallback(
		() => modalStack.addModal(<AddGroupDialog />),
		[modalStack]
	);

	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">User Groups</Subtitle>
				<SideNavButton onClick={showAddGroupModal} label="Create User Group" />
			</PanelHeader>
			<ScrollCard className="mt-4">
				<UserGroupsTable />
			</ScrollCard>
		</article>
	);

	return useObserver(render);
};

export default UserGroupsPanel;
