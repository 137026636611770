import styled from 'styled-components';
import { Label } from 'reactstrap';
import { fsNormal } from '../../theme/ui/typography';

const StyledLabel = styled(Label)`
	${fsNormal};
	margin-bottom: 0.25em;
	margin-top: 0.5em;
`;

export default StyledLabel;
