import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';

import { useUserStore } from '../../../stores';
import { UserGroup } from '../../../accounts/models/UserGroupModel';
import { doesUserMatch, User } from '../../../accounts/models/UserModel';
import {
	DownshiftMultiSelectProps,
	DownshiftMultiSelect,
} from '../../../components/downshift-select';

const MemberSelect = ({ group }: { group: UserGroup }) => {
	const userStore = useUserStore();

	const addMember = useCallback(
		(user: Nullable<User>) => {
			if (user) {
				group.addMember(user);
			}
		},
		[group]
	);

	const removeMember = useCallback((user: User) => group.removeMember(user), [
		group,
	]);

	const isNotMember = useCallback((user: User) => !group.isMember(user), [
		group,
	]);

	return useObserver(() => {
		const selectProps: DownshiftMultiSelectProps<User, User> = {
			label: 'Members',
			selectionState: {
				selection: group.members,
				options: userStore.all.filter(isNotMember),
				searchPredicate: doesUserMatch,
			},
			selectionActions: {
				select: addMember,
				unselect: removeMember,
			},
		};

		return <DownshiftMultiSelect {...selectProps} />;
	});
};

export default MemberSelect;
