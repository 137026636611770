import styled from 'styled-components';
import { fsNormal } from '../../../theme/ui/typography';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const Chip = styled.div`
	${fsNormal};
	display: inline-block;
	background-color: ${colors.darkerGrey};
	border-radius: 4px;
	color: ${colors.white};
	cursor: default;
	margin-bottom: 10px;
	margin-right: 10px;
	padding: 0.5em 1em;
`;

export default Chip;
