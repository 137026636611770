import {
	applyPatch,
	castToReferenceSnapshot,
	castToSnapshot,
	destroy,
	getParentOfType,
	getRelativePath,
	Instance,
	ITypeUnion,
	SnapshotIn,
	SnapshotOut,
	types,
} from 'mobx-state-tree';

import {
	generateID,
	getArrayIndex,
	includesCaseInsensitive,
	isLastInArray,
	noop,
	stubFalse,
} from '../../common/index';
import {
	InstanceArray,
	nonEmptyStringWithDefault,
	optionalString,
} from '../../models/common';
import {
	isBackward,
	isForward,
	TransitionType,
} from '../../models/BaseWorkflowTransitionModel';
import { BaseWorkflowOwnableModel } from '../../models/BaseWorkflowOwnableModel';
import {
	BaseWorkflowOwner,
	BaseWorkflowOwnerModel,
} from '../../models/BaseWorkflowOwnerModel';
import { BaseWorkflowPhaseReference } from '../../models/BaseWorkflowPhaseModel';

import { TemplateModel } from './TemplateModel';
import {
	TemplateForwardSubstageTransition,
	TemplateForwardTransition,
	TemplateRootTransition,
	TemplateRootTransitionModel,
	TemplateSubstageTransitionModel,
	TemplateTransition,
	TemplateTransitionModel,
	TransitionWithTarget,
} from './TemplateTransitionModel';

import {
	TemplateStageInputSlot,
	TemplateStageInputSlotModel,
} from './TemplateStageInputSlot';
import { StageType } from './StageTypes';
import { sumBy } from 'lodash';

const TemplateStageBaseModel = types
	.model('TemplateStage', {
		_id: types.identifier,
		title: types.optional(types.string, 'Untitled Stage'),
		transitions: types.array(TemplateTransitionModel) as InstanceArray<
			TemplateTransition
		>,
		phase: BaseWorkflowPhaseReference,
	})
	.actions((self) => ({
		addBackwardTransitionTo(stage: TemplateRootStage): void {
			self.transitions.push({
				targetStage: castToReferenceSnapshot(stage),
				type: TransitionType.backward,
			});
		},
		removeTransition(transition: TemplateTransition): void {
			destroy(transition);
		},
	}))
	.views((self) => ({
		get backwardTransitions(): TemplateTransition[] {
			return self.transitions.filter(isBackward);
		},
		get forwardTransition(): Maybe<TemplateForwardTransition> {
			return self.transitions.find(isForward) as Maybe<
				TemplateForwardTransition
			>;
		},
	}));

const TemplateRootStageCommonModelInferred = TemplateStageBaseModel.named(
	'TemplateRootStage'
)
	.props({
		initial: types.optional(types.boolean, false),
		final: types.optional(types.boolean, false),
		transitions: types.array(TemplateRootTransitionModel) as InstanceArray<
			TemplateRootTransition
		>,
	})
	.views((self) => ({
		get forwardTransition(): Maybe<TemplateRootTransition> {
			return self.transitions.find(isForward) as Maybe<TemplateRootTransition>;
		},
		get nextStage(): Maybe<TemplateRootStage> {
			return this.forwardTransition?.targetStage;
		},
		get previousStage(): Maybe<TemplateRootStage> {
			if (self.initial) {
				return undefined;
			}

			return getParentOfType(self, TemplateModel).stagePointingTo(
				self as TemplateSingleStage
			);
		},
	}))
	.actions((self) => ({
		addForwardTransitionTo(stage: TemplateRootStage): void {
			const existingForwardTransition = self.forwardTransition;
			if (existingForwardTransition) {
				existingForwardTransition.targetStage = stage;
			} else {
				self.transitions.push({
					targetStage: castToReferenceSnapshot(stage),
					type: TransitionType.forward,
				});
			}
		},
	}));

interface TemplateRootStageCommonModel
	extends Infer<typeof TemplateRootStageCommonModelInferred> {}

const TemplateRootStageCommonModel: TemplateRootStageCommonModel = TemplateRootStageCommonModelInferred;

const TemplateActionableStageModelInferred = types
	.compose(TemplateStageBaseModel, BaseWorkflowOwnableModel)
	.named('TemplateActionableStage')
	.props({
		instructions: optionalString,
		expectedDurationHrs: types.optional(types.number, 24),
		owners: types.array(BaseWorkflowOwnerModel),
		inputSlots: types.array(TemplateStageInputSlotModel),
	})
	.actions((self) => ({
		addInputSlot(label: string = 'Unlabeled input'): void {
			self.inputSlots.push({ _id: generateID(), label });
		},
		deleteInputSlot(slot: TemplateStageInputSlot): void {
			destroy(slot);
		},
	}))
	.views((self) => ({
		includesMentionOf(value: string): boolean {
			let ownersIncludeMention = false;
			self.owners.forEach((owner) => {
				if (owner.includesMentionOf(value)) {
					ownersIncludeMention = true;
				}
			});

			let slotsIncludeMention = false;
			self.inputSlots.forEach((slot) => {
				if (slot.includesMentionOf(value)) {
					slotsIncludeMention = true;
				}
			});

			return (
				includesCaseInsensitive(self.instructions, value) ||
				ownersIncludeMention ||
				slotsIncludeMention
			);
		},
	}));

interface TemplateActionableStageModel
	extends Infer<typeof TemplateActionableStageModelInferred> {}

export const TemplateActionableStageModel: TemplateActionableStageModel = TemplateActionableStageModelInferred;

const TemplateSingleStageModelInferred = types
	.compose(TemplateRootStageCommonModel, TemplateActionableStageModel)
	.named('TemplateSingleStage')
	.props({
		type: types.literal(StageType.single),
	});

interface TemplateSingleStageModel
	extends Infer<typeof TemplateSingleStageModelInferred> {}

export const TemplateSingleStageModel: TemplateSingleStageModel = TemplateSingleStageModelInferred;

export interface TemplateSingleStage
	extends Instance<TemplateSingleStageModel> {}

const TemplateSubstageModelInferred = TemplateActionableStageModel.named(
	'TemplateSubstage'
)
	.props({
		type: types.literal(StageType.substage),

		transitions: types.array(TemplateSubstageTransitionModel) as InstanceArray<
			TemplateRootTransition
		>,

		expectedDurationHrs: types.optional(types.number, 24),
	})
	.views((self) => ({
		get parentStage(): TemplateParallelStage {
			return getParentOfType(self, TemplateParallelStageModel);
		},
		get previousStage(): Maybe<TemplateRootStage> {
			return this.parentStage.previousStage;
		},
		get nextSubstage(): Maybe<TemplateSubstage> {
			const forwardTransition = self.forwardTransition as Maybe<
				TemplateForwardSubstageTransition
			>;
			return forwardTransition?.targetStage;
		},
	}))
	.actions((self) => ({
		addSubstageTransitionTo(stage: TemplateSubstage): void {
			const existingForwardTransition = self.forwardTransition as Maybe<
				TransitionWithTarget<TemplateSubstage>
			>;
			if (existingForwardTransition) {
				existingForwardTransition.targetStage = stage;
			} else {
				self.transitions.push({
					targetStage: castToReferenceSnapshot(stage),
					type: TransitionType.forward,
				});
			}
		},
	}));

export interface TemplateSubstageModel
	extends Infer<typeof TemplateSubstageModelInferred> {}

export const TemplateSubstageModel: TemplateSubstageModel = TemplateSubstageModelInferred;

const createSubstage = (): TemplateSubstage =>
	TemplateSubstageModel.create({
		type: StageType.substage,
		_id: generateID(),
	});

export interface TemplateSubstage extends Instance<TemplateSubstageModel> {}

const TemplateParallelStageModelInferred = TemplateRootStageCommonModel.named(
	'TemplateParallelStage'
)
	.props({
		title: nonEmptyStringWithDefault('Untitled Stage Group'),
		type: types.literal(StageType.parallel),
		substages: types.array(types.array(TemplateSubstageModel)),
	})
	.views((self) => ({
		get flatSubstages(): readonly TemplateSubstage[] {
			return self.substages.flat();
		},
		get flatStages(): readonly TemplateStage[] {
			return this.flatSubstages as readonly TemplateStage[];
		},
		/**
		 * Here mainly to avoid checking for stage types in components.
		 * Parallel stages aren't really "owned".
		 */
		get owners(): readonly BaseWorkflowOwner[] {
			return [];
		},
		isOwner: stubFalse,
		addOwner: noop,
		removeOwner: noop,

		get expectedDurationHrs(): number {
			return self.substages.reduce(
				(acc: number, substageGroup: TemplateSubstage[]) => {
					const substageSequenceDuration = sumBy(
						substageGroup,
						'expectedDurationHrs'
					);
					return Math.max(acc, substageSequenceDuration);
				},
				0
			);
		},
	}))
	.actions((self) => ({
		addSubstageGroup(): TemplateSubstage {
			const newSubstage = createSubstage();

			self.substages.push([castToSnapshot(newSubstage)]);

			return newSubstage;
		},
		addSubstage(from: TemplateSubstage): TemplateSubstage {
			const newSubstage = createSubstage();

			const path = getRelativePath(self, from);

			const nextIndex: string = isLastInArray(from)
				? '-'
				: `${getArrayIndex(from) + 1}`;

			applyPatch(self, {
				op: 'add',
				path: path.replace(/[\d]+$/, nextIndex),
				value: newSubstage,
			});

			const existingNextSubstage = from.forwardTransition?.targetStage;
			if (TemplateSubstageModel.is(existingNextSubstage)) {
				newSubstage.addSubstageTransitionTo(existingNextSubstage);
			}
			from.addSubstageTransitionTo(newSubstage);

			return newSubstage;
		},
	}));

export interface TemplateParallelStageModel
	extends Infer<typeof TemplateParallelStageModelInferred> {}

export const TemplateParallelStageModel: TemplateParallelStageModel = TemplateParallelStageModelInferred;

export interface TemplateParallelStage
	extends Instance<TemplateParallelStageModel> {}

/*
 * Explicitly declare the union, otherwise TS goes crazy with inferences
 */

export interface TemplateStageModel
	extends ITypeUnion<
		SnapshotIn<
			| TemplateSingleStageModel
			| TemplateParallelStageModel
			| TemplateSubstageModel
		>,
		SnapshotOut<
			| TemplateSingleStageModel
			| TemplateParallelStageModel
			| TemplateSubstageModel
		>,
		TemplateSingleStage | TemplateParallelStage | TemplateSubstage
	> {}

export const TemplateStageModel: TemplateStageModel = types.union(
	TemplateSingleStageModel,
	TemplateParallelStageModel,
	TemplateSubstageModel
);

export interface TemplateRootStageModel
	extends ITypeUnion<
		SnapshotIn<TemplateSingleStageModel | TemplateParallelStageModel>,
		SnapshotOut<TemplateSingleStageModel | TemplateParallelStageModel>,
		TemplateSingleStage | TemplateParallelStage
	> {}

export const TemplateRootStageModel: TemplateRootStageModel = types.union(
	TemplateSingleStageModel,
	TemplateParallelStageModel
);

export const isTemplateRootStage = (x: unknown): x is TemplateRootStage =>
	TemplateRootStageModel.is(x);
export const isTemplateStage = (x: unknown): x is TemplateStage =>
	TemplateStageModel.is(x);

export interface TemplateRootStageSnapshotIn
	extends Omit<
		SnapshotIn<TemplateSingleStageModel | TemplateParallelStageModel>,
		'type'
	> {
	type: StageType.single | StageType.parallel;
}

export interface TemplateRootStageSnapshotOut
	extends Omit<
		SnapshotOut<TemplateSingleStageModel | TemplateParallelStageModel>,
		'type'
	> {
	type: StageType.single | StageType.parallel;
}

export type TemplateRootStage = TemplateSingleStage | TemplateParallelStage;
export type TemplateStage =
	| TemplateSingleStage
	| TemplateParallelStage
	| TemplateSubstage;
export type TemplateActionableStage = TemplateSingleStage | TemplateSubstage;

export function isStageActionable(
	stage: TemplateStage
): stage is TemplateActionableStage {
	return !TemplateParallelStageModel.is(stage);
}

export function isInputStage(
	stage: TemplateStage
): stage is TemplateActionableStage &
	HasDefinedProp<TemplateActionableStage, 'inputSlots'> {
	return !TemplateParallelStageModel.is(stage) && stage.inputSlots != null;
}
