import styled from 'styled-components';
import { Input } from 'reactstrap';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const SearchInput = styled(Input)`
	border: none !important;
	color: ${colors.darkGrey};
	height: 100% !important;
`;

export default SearchInput;
