import styled from 'styled-components';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledCircleButton } from '../../../components/circle-button/style';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// main component
export const ActionButtonContainer = styled.div`
	display: inline-block;
	margin-left: 1em;
`;

export const ActionButton = styled(Button)`
	&& {
		border: 1px solid ${colors.darkestGrey};
		text-transform: uppercase;
		padding: 0.5rem 0.75rem;
		font-size: 0.8rem;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.btn-secondary {
		&& {
			background-color: ${colors.secondary};
			border: 1px solid ${colors.secondary};

			&:hover {
				background-color: ${colors.secondaryHighlighted};
				&& {
					border: 1px solid ${colors.secondaryHighlighted};
				}
			}
		}
	}

	&.success:hover {
		background-color: ${colors.success};
		&& {
			border: 1px solid ${colors.success};
			color: ${colors.white};
		}
	}

	&.danger:hover {
		background-color: ${colors.danger};
		&& {
			border: 1px solid ${colors.danger};
			color: ${colors.white} !important;
		}
	}

	&.solid:hover {
		background-color: ${colors.darkestGrey};

		&& {
			border: 1px solid ${colors.darkestGrey};
			color: ${colors.white};
		}
	}
`;

// other components
export const IconWithRightMargin = styled(FontAwesomeIcon)`
	margin-right: 8px;

	&.completed {
		color: ${colors.success};
	}
`;

export const OutlinedCircleButton = styled(StyledCircleButton)`
	&& {
		border: 1px solid ${colors.darkestGrey};

		&:hover {
			color: ${colors.white};
		}
	}

	&.roadblocked {
		background-color: ${colors.danger};
		border: 1px solid ${colors.danger} !important;
		color: ${colors.white};
		width: auto;

		&& {
			border-radius: 20px;
		}
	}
`;

export const StyledTooltip = styled(UncontrolledTooltip)`
	.tooltip {
		pointer-events: none;
	}
`;

export const OwnerButton = styled.button`
	display: flex;
	border-radius: 2px;
	border: 1px solid transparent;
	pointer: cursor;
	align-items: center;

	&:hover {
		border: 1px solid ${colors.darkGrey};
		background-color: ${colors.lightestGrey};
		transition: all 0.25s;
	}
`;
