import styled from 'styled-components';
import { DropdownItem, DropdownToggle } from 'reactstrap';

import { focus } from '../../theme/ui/ui';
import dropdownMenuItem from '../../theme/components/drop-down-menu';

export const DropDownToggle = styled(DropdownToggle)`
	&:focus {
		${focus}
	}
`;

export const DropDownMenuItem = styled(DropdownItem)`
	${dropdownMenuItem}
`;
