import React from 'react';
import { getParentOfType } from 'mobx-state-tree';

import { formatDuration } from '../../../common';
import { buildStageUrl } from '../../../common/links';
import { _logError } from '../../../common/log';
import {
	Workflow,
	WorkflowModel,
	WorkflowStage,
} from '../../../workflows/models';

import StageStatusPill from '../../../components/stage-status-pill';
import { Cell, CellLink } from '../../../styled-components/blocks/table';

const getWorkflow = (stage: WorkflowStage) => {
	let workflow: Maybe<Workflow> = undefined;

	try {
		workflow = getParentOfType(stage, WorkflowModel);
	} catch (error) {
		_logError(error);
	}

	return workflow;
};

const AssignmentTableRow = (props: { stage: WorkflowStage }) => {
	const { stage } = props;
	const workflow = getWorkflow(stage);

	if (!workflow) {
		return null;
	}

	return (
		<tr key={stage._id}>
			<Cell>
				<CellLink to={buildStageUrl(workflow, stage)}>{stage.title}</CellLink>
			</Cell>
			<Cell>{formatDuration(stage.expectedDurationHrs)}</Cell>
			<Cell>
				<StageStatusPill status={stage.status} />
			</Cell>
			<Cell>{workflow.title}</Cell>
		</tr>
	);
};

export default AssignmentTableRow;
