import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Subtitle from '../../../styled-components/elements/subtitle';
import MetadataTemplateListTable from '../../components/entity-metadata-template-list-table';
import ScrollCard from '../../../styled-components/elements/scroll-card';
import { PanelHeader } from '../../../admin-dashboard/components/users-panel/style';
import CreateTemplateButton from '../../../styled-components/elements/create-template-button';

const MetadataTemplatesIndexView = () => (
	<article>
		<PanelHeader>
			<Subtitle className="mb-md-0">Metadata Templates</Subtitle>
			<CreateTemplateButton to="./new">
				<FontAwesomeIcon icon={faPlus} className="mr-2" />
				New Metadata Template
			</CreateTemplateButton>
		</PanelHeader>
		<ScrollCard className="mt-4">
			<MetadataTemplateListTable />
		</ScrollCard>
	</article>
);

export default MetadataTemplatesIndexView;
