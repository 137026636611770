import React from 'react';
import Moment from 'react-moment';
import { useObserver } from 'mobx-react';

import {
	buildUserUrl,
	buildVersionUrl,
	buildWorkflowUrl,
} from '../../../common/links';
import { Cell } from '../../../styled-components/blocks/table';
import { Workflow } from '../../../workflows/models';
import themeStore from '../../../theme/models/ThemeStore';

import { AssetVersion } from '../../models/AssetVersionModel';

import LinkToAssetVersionStage from '../link-to-asset-version-stage';
import { HeadingCell, StyledLink, StyledTable } from './style';

const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;

interface Props {
	workflowA: Workflow;
	versionA: AssetVersion;
	workflowB: Workflow;
	versionB: AssetVersion;
}

export const AssetComparisonTable = (props: Props) => {
	const { workflowA, versionA, workflowB, versionB } = props;

	const render = () => (
		<StyledTable>
			<thead>
				<tr>
					<HeadingCell>Property</HeadingCell>
					<HeadingCell>Source</HeadingCell>
					<HeadingCell>Other</HeadingCell>
				</tr>
			</thead>

			<tbody>
				<tr>
					<Cell>ID</Cell>
					<Cell>{versionA._id}</Cell>
					<Cell>{versionB._id}</Cell>
				</tr>

				<tr>
					<Cell>File name</Cell>
					<Cell>
						<StyledLink to={buildVersionUrl(versionA)}>
							{versionA.fileName}
						</StyledLink>
					</Cell>
					<Cell>
						<StyledLink to={buildVersionUrl(versionB)}>
							{versionB.fileName}
						</StyledLink>
					</Cell>
				</tr>

				<tr>
					<Cell>File size</Cell>
					<Cell>{versionA.size}</Cell>
					<Cell>{versionB.size}</Cell>
				</tr>

				<tr>
					<Cell>File type</Cell>
					<Cell>{versionA.type}</Cell>
					<Cell>{versionB.type}</Cell>
				</tr>

				<tr>
					<Cell>Created on</Cell>
					<Cell>
						<Moment format="MM/DD/YYYY, h:MM A" date={versionA.createdAt} />
					</Cell>
					<Cell>
						<Moment format="MM/DD/YYYY, h:MM A" date={versionB.createdAt} />
					</Cell>
				</tr>

				<tr>
					<Cell>Created by</Cell>
					<Cell>
						<StyledLink to={buildUserUrl(versionA.createdBy!)}>
							{versionA.createdBy?.name}
						</StyledLink>
					</Cell>
					<Cell>
						<StyledLink to={buildUserUrl(versionB.createdBy!)}>
							{versionB.createdBy?.name}
						</StyledLink>
					</Cell>
				</tr>

				<tr>
					<Cell>{workflowTerm}</Cell>
					<Cell>
						<StyledLink to={buildWorkflowUrl(workflowA)}>
							{workflowA.title}
						</StyledLink>
					</Cell>
					<Cell>
						<StyledLink to={buildWorkflowUrl(workflowB)}>
							{workflowB.title}
						</StyledLink>
					</Cell>
				</tr>

				<tr>
					<Cell>{stageTerm}</Cell>
					<Cell>
						<LinkToAssetVersionStage workflow={workflowA} version={versionA} />
					</Cell>
					<Cell>
						<LinkToAssetVersionStage workflow={workflowB} version={versionB} />
					</Cell>
				</tr>

				<tr>
					<Cell>Number of views</Cell>
					<Cell>{versionA.views}</Cell>
					<Cell>{versionB.views}</Cell>
				</tr>
			</tbody>
		</StyledTable>
	);

	return useObserver(render);
};
