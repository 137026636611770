import React from 'react';
import { Redirect } from '@reach/router';
import { useObserver } from 'mobx-react';

import { appRoutes } from './app.routes';
import { AuthContext } from '../core';
import { isIdentifiableLoaded } from '../common';
import { useUserStore } from '../stores';
import ViewWrapper from '../styled-components/elements/view-wrapper';
import DialogPortal from '../components/modals/dialog-portal';
import errorMonitor from '../third-parties/error-monitor.service';
import Footer from '../components/footer';
import LoadingIndicator from '../components/loading-indicator';
import NotificationsContainer from '../notifications/components/notifications-container';
import AdminNavigation from './components/admin-navigation';
import AdminSidebar from './components/admin-sidebar';
import { AppContent } from './style';

const MainView = () => {
	return (
		<ViewWrapper>
			<AdminSidebar />
			<AppContent>
				<AdminNavigation />
				{appRoutes}
				<Footer />
			</AppContent>
			<NotificationsContainer />
			<DialogPortal />
		</ViewWrapper>
	);
};

const Admin = () => {
	const auth = React.useContext(AuthContext);
	const userStore = useUserStore();

	return useObserver(() => {
		if (!auth.isAuthenticated) {
			return <Redirect to="/auth" noThrow />;
		}

		errorMonitor.setUser(userStore.currentUser);

		return isIdentifiableLoaded(userStore.currentUser) ? (
			<MainView />
		) : (
			<LoadingIndicator />
		);
	});
};
export default Admin;
