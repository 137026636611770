import React, { useCallback, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Form, FormGroup } from 'reactstrap';

import { doesNameableMatch } from '../../../common';
import { _logError } from '../../../common/log';
import { DownshiftSingleSelect } from '../../../components/downshift-select';
import SubmitButton from '../../../components/submit-button';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useWorkflowCollectionStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import { SelectedWorkflow } from '../../workflow.contexts';
import { SelectionActions } from '../../../components/downshift-select/downshift.interfaces';
import { WorkflowCollection } from '../../../workflow-collections/models/WorkflowCollectionModel';

const collectionTerm = themeStore._.workflowCollection;
const workflowTerm = themeStore._.workflow;

const AddWorkflowToCollectionForm = () => {
	const workflowCollectionStore = useWorkflowCollectionStore();
	const workflow = SelectedWorkflow.presentValue;

	const [selectedCollection, setSelectedCollection] = useState<
		Nullable<WorkflowCollection>
	>();

	const onSubmit = useCallback(async () => {
		if (selectedCollection) {
			try {
				await workflow.updateCollection(selectedCollection._id);

				notificationStore.push({
					text: `Added to ${collectionTerm.toLowerCase()}!`,
				});
			} catch (error) {
				_logError(error);

				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An error occurred while adding this ${workflowTerm} to a ${collectionTerm}. Please try again later.`,
					})
				);
			}
		}
	}, [workflow, selectedCollection]);

	return useObserver(() => (
		<Form className="mb-5">
			<FormGroup>
				<DownshiftSingleSelect
					label={`Add to ${collectionTerm}`}
					placeholder="Search by name..."
					selectionState={{
						selection: selectedCollection,
						options: [...workflowCollectionStore.all],
						searchPredicate: doesNameableMatch,
					}}
					selectionActions={
						{
							select: setSelectedCollection,
						} as SelectionActions<WorkflowCollection, WorkflowCollection>
					}
				/>
			</FormGroup>
			<SubmitButton onClick={onSubmit} label="Add" className="mt-0" />
		</Form>
	));
};

export default AddWorkflowToCollectionForm;
