import styled from 'styled-components';
import { textBody } from '../../theme/ui/ui';
import { ffBold } from '../../theme/ui/typography';

const P = styled.p`
	${textBody};
`;

//modifiers
export const PBold = styled(P)`
	${ffBold};
`;

export default P;
