import React, { FormEvent } from 'react';
import { useObserver } from 'mobx-react';
import { Link, navigate } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import Heading from '../../../styled-components/elements/heading';
import NotificationModel from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useMetadataTemplateStore } from '../../../stores';
import EntityMetadataTemplateEditForm from '../../components/entity-metadata-template-edit-form';
import Divider from '../../../styled-components/elements/divider';

interface MetadataTemplateDetailViewProps {
	metadataTemplateId: string;
}

const MetadataTemplateDetailsView = (
	props: MetadataTemplateDetailViewProps
) => {
	const metadataTemplateStore = useMetadataTemplateStore();
	const { metadataTemplateId } = props;

	const render = () => {
		const metadataTemplate = metadataTemplateStore.findOne(metadataTemplateId);

		if (!metadataTemplate) {
			return null;
		}

		const updateMetadataTemplate = (event: FormEvent<Element>) => {
			event.preventDefault();

			metadataTemplateStore.updateOne(metadataTemplate);

			notificationStore.push(
				NotificationModel.create({
					text: `${metadataTemplate.title} saved!`,
				})
			);

			return navigate(``);
		};

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem tag={Link} to="..">
								Metadata Templates
							</BreadcrumbItem>
							<BreadcrumbItem active>{metadataTemplate.title}</BreadcrumbItem>
						</Breadcrumb>
						<Heading>{metadataTemplate.title}</Heading>
						<Divider />
					</Col>
					<Col lg={8}>
						<EntityMetadataTemplateEditForm
							selectedMetadataTemplate={metadataTemplate}
							onSubmit={(event) => updateMetadataTemplate(event)}
							displayTemplateField={false}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default MetadataTemplateDetailsView;
