import React from 'react';
import Moment from 'react-moment';
import { Table } from 'reactstrap';
import { getParentOfType } from 'mobx-state-tree';

import { buildStageUrl } from '../../../common/links';
import { Cell, CellLink } from '../../../styled-components/blocks/table';
import { Roadblock, WorkflowModel } from '../../../workflows/models';
import themeStore from '../../../theme/models/ThemeStore';
import ScrollCard from '../../../styled-components/elements/scroll-card';

const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;

interface Props {
	roadblocks: ReadonlyArray<Roadblock>;
}

// components
const renderNoRoadblocksRow = () => (
	<tr>
		<td colSpan={3}>
			<p>There are currently no roadblocks.</p>
		</td>
	</tr>
);

const RoadblocksTable = (props: Props) => {
	const renderRoadblocksRow = (roadblock: Roadblock) => {
		const workflow = getParentOfType(roadblock.stage, WorkflowModel);

		return (
			<tr key={roadblock.event._id}>
				<Cell>
					<CellLink to={buildStageUrl(workflow, roadblock.stage)}>
						{roadblock.stage.title}
					</CellLink>
				</Cell>
				<Cell>{workflow.title}</Cell>
				<Cell>{roadblock.event.createdBy.name}</Cell>
				<Cell>
					<Moment format="MMM DD, YYYY" date={roadblock.event.createdAt} />
				</Cell>
				<Cell>{roadblock.event.message}</Cell>
			</tr>
		);
	};

	const render = () => (
		<ScrollCard>
			<Table>
				<thead>
					<tr>
						<th>{`${stageTerm}`}</th>
						<th>{workflowTerm}</th>
						<th>Reported by</th>
						<th>Reported on</th>
						<th>Message</th>
					</tr>
				</thead>
				<tbody>
					{props.roadblocks.length
						? props.roadblocks.map((roadblock: Roadblock) =>
								renderRoadblocksRow(roadblock)
						  )
						: renderNoRoadblocksRow()}
				</tbody>
			</Table>
		</ScrollCard>
	);

	return render();
};

export default RoadblocksTable;
