import React from 'react';
import { Cell } from '../../styled-components/blocks/table';
import { ThemeTerm } from '../../theme/models/ThemeTermsModel';
import themeStore from '../../theme/models/ThemeStore';

export const NoResultsRow = ({ term }: { term: ThemeTerm }) => (
	<tr>
		<Cell>
			<p>No {themeStore._[term].toLowerCase()}s yet.</p>
		</Cell>
	</tr>
);
