import styled from 'styled-components';
import inputField from '../../theme/components/input-field';
import themeStore from '../../theme/models/ThemeStore';

import { boxShadowFocus } from '../../theme/ui/ui';

const { colors } = themeStore.selectedTheme;

export const TagInputField = styled.div`
	${inputField};
	padding: 12px;
	position: relative;

	&:focus-within {
		${boxShadowFocus};
		border: 1px solid ${colors.secondaryHighlighted} !important;
	}
`;

export const BorderlessInput = styled.input`
	display: inline-block;
	border: none;
	outline: 0 !important;
	text-rendering: optimizeLegibility;
	width: calc(100% - 30px);
`;
