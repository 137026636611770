import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';

import ActionsDropdownToggle from '../../../styled-components/elements/action-dropdown-toggle';
import { DropDownMenuItem } from '../../../styled-components/elements/dropdown-menu';
import { ConfirmationDialog } from '../../../components/modals';
import WarningModal from '../../../components/modals/warning-modal';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { TemplateState, WorkflowTemplate } from '../../models';
import { WarningListItem } from '../template-stage-edit-form/style';
import UnstyledLink from '../../../styled-components/elements/unstyled-link';

// interface
interface TemplateOwnersListProps {
	template: WorkflowTemplate;
}

// constants
const canFinalizeTemplate = (template: WorkflowTemplate) => {
	// TODO: add more conditions as needed
	return !(
		template.isUntitled ||
		template.hasUntitledStages ||
		template.hasUnlabeledInputSlots
	);
};

// component
const TemplateActions = (props: TemplateOwnersListProps) => {
	const modalStack = useModalCreator();

	const showFinalizeModal = useCallback(() => {
		if (canFinalizeTemplate(props.template)) {
			const finalizeTemplate = () =>
				applyPatch(props.template, {
					op: 'replace',
					path: `/editingState`,
					value: TemplateState.final,
				});

			modalStack.addModal(
				<ConfirmationDialog
					header="Finalize Template"
					onConfirm={finalizeTemplate}
				>
					<p>
						Are you certain you want to finalize this template? You won't be
						able to edit it in the future.
					</p>
				</ConfirmationDialog>
			);
		} else {
			modalStack.addModal(
				<WarningModal header="Warning" buttonLabel="OK">
					<p>It is not currently possible to finalize this template.</p>
					<p>Please address the issues below and try again.</p>
					<ul>
						{props.template.isUntitled ? (
							<WarningListItem>
								Template has not been given a name.
							</WarningListItem>
						) : null}
						{props.template.hasUntitledStages ? (
							<WarningListItem>
								Not all {themeStore._.stage.toLowerCase()}s have been given a
								title.
							</WarningListItem>
						) : null}
						{props.template.hasUnlabeledInputSlots ? (
							<WarningListItem>
								Not all input labels have been labeled.
							</WarningListItem>
						) : null}
					</ul>
				</WarningModal>
			);
		}
	}, [props.template, modalStack]);

	const renderComponent = () => (
		<UncontrolledDropdown size="sm" className="mt-2">
			<ActionsDropdownToggle caret>Actions</ActionsDropdownToggle>
			<DropdownMenu right>
				<DropDownMenuItem onClick={showFinalizeModal}>
					Finalize
				</DropDownMenuItem>
				<DropDownMenuItem>
					<UnstyledLink to="./edit">Edit details</UnstyledLink>
				</DropDownMenuItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);

	return useObserver(renderComponent);
};

export default TemplateActions;
