import styled from 'styled-components';
import { focus } from '../../../theme/ui/ui';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const SearchButton = styled.button`
	background-color: ${colors.white};
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border: none;
	color: ${colors.darkestGrey};
	z-index: 1;

	&:focus {
		${focus};
	}
`;

export default SearchButton;
