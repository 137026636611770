import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DeleteButton } from '../template-stage-edit-form/style';
import { EditButton } from '../template-stage-edit-buttons/style';
import {
	faEdit,
	faFileUpload,
	faTimes,
} from '@fortawesome/free-solid-svg-icons';
import TemplateStageInputSlotEditorModal from './template-stage-edit-slots-modal';
import { TemplateStageInputSlot } from '../../models';
import { useModalCreator } from '../../../stores/ModalStack';
import { InputSlotCard, InputSlotImage } from './style';

export interface TemplateStageInputSlotEditorProps {
	slot: TemplateStageInputSlot;
}

export const TemplateStageInputSlotEditor = ({
	slot,
}: TemplateStageInputSlotEditorProps) => {
	const modalStack = useModalCreator();

	const showSlotModal = useCallback(
		() =>
			modalStack.addModal(<TemplateStageInputSlotEditorModal slot={slot} />),
		[modalStack, slot]
	);

	return useObserver(() => (
		<InputSlotCard key={slot._id}>
			<InputSlotImage>
				<FontAwesomeIcon icon={faFileUpload} />
			</InputSlotImage>
			<CardBody className="d-flex justify-content-between p-3">
				<p className="mb-0 align-self-center mr-2">{slot.label}</p>
				<div className="d-flex">
					<EditButton size="sm" onClick={showSlotModal} className="mr-3">
						<FontAwesomeIcon icon={faEdit} />
					</EditButton>
					<DeleteButton
						type="button"
						aria-label="Delete input slot"
						title="Delete input slot"
						onClick={slot.remove}
					>
						<FontAwesomeIcon icon={faTimes} />
					</DeleteButton>
				</div>
			</CardBody>
		</InputSlotCard>
	));
};
