import styled from 'styled-components';

import themeStore from '../../../theme/models/ThemeStore';

import { fsSmol } from '../../../theme/ui/typography';
import unstyledButton from '../../../theme/components/unstyled-button';

const { colors } = themeStore.selectedTheme;

export const ShareAssetButton = styled.button`
	${unstyledButton};
	${fsSmol};
	display: inline-block;
	color: ${colors.primary};

	&:hover {
		text-decoration: underline;
		color: ${colors.primaryHighlighted};
	}
`;
