import React from 'react';
import { useObserver } from 'mobx-react';

import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';
import { CellLink } from '../../../styled-components/blocks/table';
import { useTemplateStore } from '../../../stores';
import { WorkflowTemplate } from '../../models';
import DeleteTemplateButton from '../delete-template-button';

const templateListColumns: ListColumn<WorkflowTemplate>[] = makeColumns([
	{
		label: 'Name',
		prop: (t: WorkflowTemplate) => (
			<CellLink to={`./${t._id}`}>{t.title}</CellLink>
		),
	},
	{
		label: 'Template #',
		prop: '_id',
	},
	{
		label: 'Created',
		prop: 'creationTime',
	},
	{
		label: 'Editing State',
		prop: 'displayState',
	},
	{
		label: '',
		prop: (t: WorkflowTemplate) => <DeleteTemplateButton template={t} />,
	},
]);

// component
const TemplateListTable = () => {
	const templateStore = useTemplateStore();

	const render = () => (
		<ListTable
			columns={templateListColumns}
			rows={templateStore.currentPage}
			noResultsLabel="No templates yet"
		/>
	);

	return useObserver(render);
};

export default TemplateListTable;
