import React, { ChangeEvent, useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { applyPatch } from 'mobx-state-tree';
import { SelectedMetadata } from '../../asset.contexts';
import LabeledInput from '../../../components/labeled-input';

const AssetMetadataForm = () => {
	const metadata = SelectedMetadata.presentValue;

	const updateMetadataValues = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;

			applyPatch(metadata, {
				op: 'replace',
				path: `/values/${name}`,
				value: value,
			});
		},
		[metadata]
	);

	const inputs = useObserver(() =>
		metadata.fields.map((field: string) => (
			<LabeledInput
				label={field}
				type="text"
				name={field}
				id={field}
				key={field}
				value={metadata.values.get(field)}
				onChange={updateMetadataValues}
			/>
		))
	);

	return <>{inputs}</>;
};

export default AssetMetadataForm;
