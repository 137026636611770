import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';
import { boxShadowFocus } from '../ui/ui';
import { ffTextBody, fsNormal } from '../ui/typography';

const { colors } = themeStore.selectedTheme;

const select = css`
	${ffTextBody};
	${fsNormal};
	-moz-appearance: none;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	appearance: none;
	background-color: transparent;
	background-image: url(/images/caret-down.min.svg);
	background-position-x: calc(100% - 12px);
	background-position-y: 50%;
	background-repeat: no-repeat;
	background-size: 8px 4px;
	border-radius: 2px;
	border: 1px solid ${colors.darkGrey};
	color: ${colors.darkestGrey};
	cursor: pointer;
	line-height: 20px;
	padding: 6px 24px 6px 12px;
	position: relative;
	text-rendering: optimizeLegibility;

	&:focus {
		${boxShadowFocus}
		border: 1px solid ${colors.primaryHighlighted} !important;
		color: ${colors.darkestGrey};
	}

	/* fix for firefox removes dotted outline on selection option */
	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 ${colors.darkestGrey};
	}
`;

export default select;
