import styled from 'styled-components';
import { Badge } from 'reactstrap';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const TagBadge = styled(Badge)`
	&& {
		background-color: ${colors.lightGrey};
		color: ${colors.black};
		padding: 8px;
		font-size: 14px;
	}
	margin: 10px 10px 0 0;
`;

export default TagBadge;
