import React from 'react';
import { Router } from '@reach/router';
import WorkflowDashboard from '../dashboard/views/dashboard';
import AssetsIndexView from '../dam-assets/views/assets-index';
import AssetDetailsView from '../dam-assets/views/asset-details';
import EditDAMAssetView from '../dam-assets/views/edit-dam-asset';
import VersionsComparisonView from '../dam-assets/views/versions-comparison';
import AssetCollectionsIndexView from '../dam-asset-collections/views/asset-collections-index';
import AssetCollectionDetailsView from '../dam-asset-collections/views/asset-collection-details';
import AssetCollectionEditView from '../dam-asset-collections/views/asset-collection-edit';
import AssetCollectionCreationView from '../dam-asset-collections/views/asset-collection-creation';
import TemplatesIndexView from '../workflow-templates/views/template-index';
import TemplateDetailsView from '../workflow-templates/views/template-details';
import TemplateEditView from '../workflow-templates/views/template-edit';
import TemplateCreationView from '../workflow-templates/views/template-creation';
import WorkflowsIndexView from '../workflows/views/workflow-index';
import VersionDetailsView from '../dam-assets/views/version-details';
import WorkflowDetailsView from '../workflows/views/workflow-details';
import WorkflowCreationView from '../workflows/views/workflow-creation';
import WorkflowCollectionsIndexView from '../workflow-collections/views/workflow-collections-index';
import WorkflowCollectionDetailsView from '../workflow-collections/views/workflow-collection-details';
import WorkflowCollectionEditView from '../workflow-collections/views/workflow-collection-edit';
import WorkflowCollectionCreationView from '../workflow-collections/views/workflow-collection-creation';
import AssignmentsIndexView from '../assignments/views/assignments-index';
import RoadblocksIndexView from '../roadblocks/views/roadblocks-index';
import ReportsIndexView from '../reports/views/reports-index';
import QueriesIndexView from '../queries/views/queries-index';
import AdminDashboardView from '../admin-dashboard/views/admin-dashboard';
import UserDetailsView from '../admin-dashboard/views/user-details';
import MetadataTemplateDetailsView from '../metadata-templates/views/metadata-template-details';
import MetadataTemplateCreationView from '../metadata-templates/views/metadata-template-creation';
import EditProfile from '../views/edit-profile';
import NotFound from '../views/not-found';
import TermsView from '../terms-and-conditions/views';
import ProductImportView from '../products/views/products-import';
import ProductsIndexView from '../products/views/products-index';
import { ProductRegistryIndex } from '../product-registry/views/product-registry-index';

const RouterGroup = ({ children }) => <>{children}</>;

export const appRoutes = (
	<Router primary={false}>
		<WorkflowDashboard path="/" />

		<RouterGroup path="dam">
			<RouterGroup path="assets">
				<AssetsIndexView path="/" />
				<AssetDetailsView path=":assetId" />
				<EditDAMAssetView path=":assetId/edit" />
				<VersionsComparisonView path="comparison/:workflowAId/:versionAId/:workflowBId/:versionBId" />
			</RouterGroup>

			<RouterGroup path="collections">
				<AssetCollectionsIndexView path="/" />
				<AssetCollectionDetailsView path=":collectionId" />
				<AssetCollectionEditView path=":collectionId/edit" />
				<AssetCollectionCreationView path="new" />
			</RouterGroup>
		</RouterGroup>

		<RouterGroup path="workflow">
			<WorkflowDashboard path="/" />

			<RouterGroup path="templates">
				<TemplatesIndexView path="/" />
				<TemplateDetailsView path=":workflowTemplateId" />
				<TemplateEditView path=":workflowTemplateId/edit" />
				<TemplateCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="workflows">
				<WorkflowsIndexView path="/" />
				<VersionDetailsView path=":workflowId/asset-versions/:versionId" />
				<WorkflowDetailsView path=":workflowId/*" />
				<WorkflowCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="projects">
				<WorkflowCollectionsIndexView path="/" />
				<WorkflowCollectionDetailsView path=":projectId" />
				<WorkflowCollectionEditView path=":projectId/edit" />
				<WorkflowCollectionCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="assignments">
				<AssignmentsIndexView path="/" />
			</RouterGroup>

			<RouterGroup path="roadblocks">
				<RoadblocksIndexView path="/" />
			</RouterGroup>
		</RouterGroup>

		<RouterGroup path="products">
			<ProductsIndexView path="/" />
			<ProductImportView path="import" />
		</RouterGroup>

		<RouterGroup path="reports">
			<ReportsIndexView path="/" />
		</RouterGroup>

		<RouterGroup path="saved-queries">
			<QueriesIndexView path="/" />
		</RouterGroup>

		<RouterGroup path="dashboard">
			<AdminDashboardView path="/*" />
			<UserDetailsView path="/users/:userId" />
			<MetadataTemplateDetailsView path="/metadata-templates/:metadataTemplateId" />
			<MetadataTemplateCreationView path="/metadata-templates/new" />
		</RouterGroup>

		<RouterGroup path="terms">
			<TermsView path="/" />
		</RouterGroup>

		<EditProfile path="profile" />
		<EditProfile path="profile" />
		<NotFound default />
	</Router>
);
