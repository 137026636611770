import React, { HTMLProps, useState } from 'react';
import { buildClassList } from '../../../common';
import { HasAssetVersion } from '../../models/AssetVersionModel';
import { DefaultAsset, Thumbnail } from './style';

// interface
interface Props {
	className?: string;
	src?: string;
	alt?: string;
}

// component
const AssetCardThumbnail = (
	props: HasAssetVersion & HTMLProps<HTMLImageElement>
) => {
	const [isValid, setValid] = useState(true);

	const {
		asset,
		src = asset.previewURL,
		alt = asset.fileName,
		className,
	} = props;

	if (asset.type === 'application/zip') {
		return <DefaultAsset />;
	}

	if (isValid && src) {
		return (
			<Thumbnail
				onError={() => setValid(false)}
				className={buildClassList('card-thumbnail', className)}
				src={src}
				alt={alt}
			/>
		);
	} else {
		return <DefaultAsset />;
	}
};

export default AssetCardThumbnail;
