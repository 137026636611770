import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';

import { isIdentifiableLoaded } from '../../../common';
import themeStore from '../../../theme/models/ThemeStore';

import AssetCard from '../asset-card';
import { DamAsset, HasDamAsset } from '../../models/DamAssetModel';
import { useAssetStore } from '../../../stores';
import { _logError } from '../../../common/log';

const RelatedAssetsColumn = ({ asset }: HasDamAsset) =>
	useObserver(() =>
		isIdentifiableLoaded(asset) ? (
			<Col lg={4} sm={6} className="mb-4">
				<AssetCard assetVersion={asset} />
			</Col>
		) : null
	);

const RelatedAssetsContent = ({ asset }: HasDamAsset) => {
	const assetStore = useAssetStore();

	const [relatedIDs, setRelatedIDs] = useState<string[]>([]);

	// todo use an 'observable' way of getting these instead of useEffect
	useEffect(() => {
		asset.relatedDamAssets.then(setRelatedIDs).catch(_logError);
	}, [asset]);

	return useObserver(() => {
		if (relatedIDs.length) {
			return (
				<>
					<p>Related {themeStore._.asset.toLowerCase()}s</p>
					<hr />
					<Row>
						{relatedIDs.map((relatedId) => (
							<RelatedAssetsColumn
								key={relatedId}
								asset={assetStore.getOne(relatedId)}
							/>
						))}
					</Row>
				</>
			);
		}
		return null;
	});
};

const RelatedAssetsRow = ({ asset }: { asset?: DamAsset }) => {
	const render = () => (
		<Container fluid={true} className="mt-5 px-0">
			{isIdentifiableLoaded(asset) ? (
				<RelatedAssetsContent asset={asset} />
			) : (
				<p>Loading...</p>
			)}
		</Container>
	);

	return useObserver(render);
};

export default RelatedAssetsRow;
