import React, { useCallback } from 'react';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useModalCreator } from '../../../../stores/ModalStack';
import themeStore from '../../../../theme/models/ThemeStore';
import {
	isActionable,
	isInputStage,
	WorkflowStageInputSlot,
} from '../../../models';
import { SelectedWorkflowStage } from '../../../workflow.contexts';
import UploadStageAssetDialog, {
	UploadAssetDialogProps,
} from '../../stage-actions/upload-stage-asset-dialog';
import {
	Hr,
	InputSlot,
	InputSlotContainer,
} from '../workflow-stage-card/style';
import Subheading from '../../../../styled-components/elements/subheading';

const StageInputSlots = () => {
	const stage = SelectedWorkflowStage.presentValue;
	const modalStack = useModalCreator();

	const openAssetUploadModal = useCallback(
		({ stage, selectedSlot }: UploadAssetDialogProps) => {
			if (isActionable(stage)) {
				modalStack.addModal(
					<UploadStageAssetDialog stage={stage} selectedSlot={selectedSlot} />
				);
			}
		},
		[modalStack]
	);

	if (isInputStage(stage)) {
		return (
			<div className="mt-4">
				<Subheading>Required {themeStore._.asset}s</Subheading>

				<InputSlotContainer>
					{stage.inputSlots.map((slot: WorkflowStageInputSlot) => (
						<InputSlot
							key={slot._id}
							className={slot.versions.length ? 'fulfilled' : 'missing'}
							onClick={() =>
								openAssetUploadModal({ stage, selectedSlot: slot })
							}
						>
							<div>
								<FontAwesomeIcon
									icon={slot.versions.length ? faCheck : faPlus}
								/>
								<p>{slot.label}</p>
							</div>
						</InputSlot>
					))}
				</InputSlotContainer>
				<Hr />
			</div>
		);
	} else return null;
};

export default StageInputSlots;
