import React from 'react';
import { ModalBody } from 'reactstrap';

import AsyncDispatcher from '../../../components/async-dispatcher';
import { DialogModal } from '../../../components/modals';
import themeStore from '../../../theme/models/ThemeStore';

import { AssetVersion, HasAssetVersion } from '../../models/AssetVersionModel';

import ShareDialogBody from '../share-dialog-body';

const assetTerm = themeStore._.asset;

const assetShareTitle = (asset: AssetVersion) => {
	if (asset.fileName) {
		return `Share "${asset.fileName}"`;
	} else {
		return `Share this ${assetTerm?.toLowerCase()}`;
	}
};

const renderErrorState = () => (
	<>
		<p>
			We're currently unable to obtain a link which can be used to share this{' '}
			{assetTerm?.toLowerCase()}.
		</p>
		<p>
			Please try again later, or contact a system administrator if the issue
			persists.
		</p>
	</>
);

const ShareSingleAssetDialog = (props: HasAssetVersion) => {
	const { asset } = props;

	return (
		<DialogModal header={assetShareTitle(asset)}>
			<ModalBody>
				<AsyncDispatcher loader={() => asset.getNewSignedURL()}>
					{{
						success: (filePath) => <ShareDialogBody link={filePath} />,
						error: renderErrorState,
					}}
				</AsyncDispatcher>
			</ModalBody>
		</DialogModal>
	);
};

export default ShareSingleAssetDialog;
