import React from 'react';
import { useObserver } from 'mobx-react';

import Subtitle from '../../../styled-components/elements/subtitle';
import ThemeTable from './theme-table';
import { PanelHeader } from '../users-panel/style';
import ScrollCard from '../../../styled-components/elements/scroll-card';

const ThemePanel = () => {
	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">Theme</Subtitle>
			</PanelHeader>
			<ScrollCard className="mt-4">
				<ThemeTable />
			</ScrollCard>
		</article>
	);

	return useObserver(render);
};

export default ThemePanel;
