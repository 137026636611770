import React, { MouseEventHandler } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecondaryButton } from '../../../styled-components/elements/button';

interface Props {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	label: string;
}

const SideNavButton = (props: Props) => (
	<SecondaryButton onClick={props.onClick}>
		<FontAwesomeIcon icon={faPlus} className="mr-2" />
		{props.label}
	</SecondaryButton>
);

export default SideNavButton;
