import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';

import { LabeledSelect } from '../../../../components/labeled-select';
import { loadIdentifier, unwrapEvent } from '../../../../common';
import themeStore from '../../../../theme/models/ThemeStore';
import {
	WorkflowInputStage,
	WorkflowStageInputSlot,
	WorkflowStageInputSlotModel,
} from '../../../models';
import { SelectedInputSlot } from '../../../workflow.contexts';

const assetTerm = themeStore._.asset.toLowerCase();

const WorkflowStageInputSlotSelect = (props: { stage: WorkflowInputStage }) => {
	const stage = props.stage;
	const slots = stage.inputSlots;

	const [slot, setSlot] = SelectedInputSlot.useMaybe();

	const updateInputSlot = useCallback(
		unwrapEvent((slotId: string) => {
			const selectedSlot = loadIdentifier(
				WorkflowStageInputSlotModel,
				stage,
				slotId
			);
			setSlot(selectedSlot);
		}),
		[props.stage]
	);

	const slotOptions = slots.map((slot: WorkflowStageInputSlot) => (
		<option key={slot._id} value={slot._id}>
			{slot.label}
		</option>
	));

	return useObserver(() => (
		<LabeledSelect
			className="mb-4"
			id="slotSelect"
			name="select"
			label={`Which ${assetTerm} are you uploading?`}
			onChange={updateInputSlot}
			defaultValue={slot?._id}
		>
			{slotOptions}
		</LabeledSelect>
	));
};

export default WorkflowStageInputSlotSelect;
