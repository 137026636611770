import styled from 'styled-components';
import { Button } from 'reactstrap';

import circularButton from '../../../theme/components/circular-button';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const ButtonWrapper = styled.div`
	position: absolute;
	border-radius: 10em !important;
	bottom: 0.25em;
	right: 0.25em;
	z-index: 2;
`;

export const EditButton = styled(Button)`
	${circularButton};

	&:hover {
		background-color: ${colors.success} !important;
	}

	&.selected {
		color: ${colors.success} !important;
	}
`;

export const DeleteButton = styled(Button)`
	${circularButton};
	margin-left: 8px;

	&:hover {
		background-color: ${colors.danger} !important;
	}
`;

export const AddSubstageButton = styled(Button)`
	${circularButton};
	margin-left: 8px;

	&:hover {
		background-color: ${colors.success} !important;
	}
`;
