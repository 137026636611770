import React from 'react';
import { getParentOfType } from 'mobx-state-tree';

import { _logError } from '../../../common/log';
import { StageStatus } from '../../../models/StageStatusModel';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowActionableStage, WorkflowModel } from '../../models';

import StageDialog from './stage-dialog';

const queueTerm = themeStore._.queue;
const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;

interface Props {
	stage: WorkflowActionableStage;
}

const ActivateFirstStageDialog = ({ stage }: Props) => {
	const workflow = getParentOfType(stage, WorkflowModel);

	const onSubmit = async (message: string) => {
		try {
			await stage.updateStatus(StageStatus.active, message);
		} catch (error) {
			_logError(error);
			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `Unable to update the ${stageTerm.toLowerCase()} status, an issue occurred while saving. Please try again later.`,
				})
			);
		}
	};

	return (
		<StageDialog
			header={`Activate stage "${stage.title}"`}
			context={`Please add some context about the status update from	"${queueTerm.toLowerCase()}" to "active"`}
			successMessage={`${workflowTerm} "${workflow.title}" has been activated!`}
			onClick={onSubmit}
		/>
	);
};

export default ActivateFirstStageDialog;
