import styled from 'styled-components';
import { Link } from '@reach/router';
import DatePicker from 'react-datepicker';

import WorkflowStatusPill from '../../../../components/workflow-status-pill';
import { underlinedAnchor } from '../../../../theme/components/anchor';
import inputField from '../../../../theme/components/input-field';

import { fsLarge, fsMedium } from '../../../../theme/ui/typography';

export const StyledPropLink = styled(Link)`
	${underlinedAnchor};
	${fsLarge};
`;

export const CustomDatePicker = styled(DatePicker)`
	${inputField};
	padding: 0.375rem 0.75rem;
`;

export const WorkflowStatusPillLg = styled(WorkflowStatusPill)`
	&& {
		${fsMedium};
		margin-top: 5px;
	}
`;
