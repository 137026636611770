import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	builtInWorkflowCollectionPredicates,
	Filter,
} from '../../../common/filters';
import { indexViewStore } from '../../../index-view/models/IndexViewStore';
import { QueryTypes } from '../../../queries/models/QueryStore';
import { unwrapEvent } from '../../../common';
import { ViewType } from '../../../index-view/models/IndexViewModel';
import { useWorkflowCollectionStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import { useFilteredStore } from '../../../common/FilteredStore';
import windowModel from '../../../models/WindowModel';
import Heading from '../../../styled-components/elements/heading';
import SideBarFiltersNav from '../../../components/side-bar-filters-nav';
import SaveQueryButton from '../../../components/save-query-button';
import FilteredCollectionList from '../../components/filtered-workflow-collection-list';
import SearchBar from '../../../components/search-bar';
import WorkflowCollectionListTable from '../../components/workflow-collection-list-table';
import { StorePaginator } from '../../../components/store-paginator';
import IndexViewToggler from '../../../index-view/components/index-view-toggler';
import { PermissionsCheck } from '../../../accounts/permissions-check.component';
import { NewCollectionButton } from './style';
import Divider from '../../../styled-components/elements/divider';

// constants
const workflowCollectionTerm = themeStore._.workflowCollection;
const filterNavOptions = [
	{
		label: `All ${workflowCollectionTerm?.toLowerCase()}s`,
		value: undefined,
	},
	{
		label: 'Created by me',
		value: Filter.CREATED_BY,
	},
];

// component
const WorkflowCollectionsIndexView = () => {
	const workflowCollectionStore = useWorkflowCollectionStore();
	const filteredCollectionStore = useFilteredStore(
		workflowCollectionStore,
		builtInWorkflowCollectionPredicates
	);

	const workflowCollectionsViewOptions = indexViewStore.workflowCollections;

	useEffect(() => {
		const searchTerm = windowModel.getURLSearchParam('q');
		if (searchTerm) {
			filteredCollectionStore.setQuery(searchTerm);
		}
	}, [filteredCollectionStore]);

	const render = () => {
		const CollectionsListRenderer =
			indexViewStore.workflowCollections.viewType === ViewType.grid
				? FilteredCollectionList
				: WorkflowCollectionListTable;

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem active>{workflowCollectionTerm}s</BreadcrumbItem>
						</Breadcrumb>
						<Heading>{workflowCollectionTerm}s</Heading>
					</Col>
					<Col md={10}>
						<SearchBar
							placeholder={`Search all ${workflowCollectionTerm?.toLowerCase()}s...`}
							onChange={unwrapEvent(filteredCollectionStore.setQuery)}
						/>
					</Col>
					<Col md={2} className="mt-md-0 mt-3">
						<SaveQueryButton
							className="float-md-right float-none"
							searchTerm={filteredCollectionStore.searchQuery}
							type={QueryTypes.WORKFLOW_COLLECTIONS}
						/>
					</Col>
					<Col xs={12} className="mt-4">
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col lg={2} md={3} className="mb-md-0 mb-4">
						<PermissionsCheck check="canCreateProject">
							<NewCollectionButton to="./new">
								<FontAwesomeIcon icon={faPlus} className="mr-2" />
								New {workflowCollectionTerm?.toLowerCase()}
							</NewCollectionButton>
						</PermissionsCheck>

						<SideBarFiltersNav
							setFilter={unwrapEvent(filteredCollectionStore.setFilter)}
							options={filterNavOptions}
						/>
					</Col>
					<Col lg={10} md={9}>
						<div className="d-flex justify-content-end">
							<IndexViewToggler view={workflowCollectionsViewOptions} />
						</div>

						<CollectionsListRenderer
							collections={filteredCollectionStore.filteredEntities}
						/>

						<StorePaginator
							pages={filteredCollectionStore.pages}
							shouldPaginate={filteredCollectionStore.shouldPaginate}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowCollectionsIndexView;
