import React from 'react';
import { useObserver } from 'mobx-react';
import { Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import { useUserStore } from '../../../stores';

import AssignmentsPanel from '../../components/assignment-panel';
import RecentWorkflowTable from '../../components/recent-workflows-table';
import RoadblocksPanel from '../../components/roadblocks-panel';
import SavedQueriesPanel from '../../components/saved-queries-panel';
import WorkflowDueDatesCalendar from '../../components/workflow-due-dates-calendar';
import WorkflowHealthPanel from '../../components/workflow-health-panel';

const WorkflowDashboard = () => {
	const userStore = useUserStore();

	return useObserver(() => (
		<Container fluid={true}>
			<Row>
				{/* header */}
				<Col xs={12}>
					<Heading>Welcome back, {userStore.currentUser.givenName}!</Heading>
				</Col>
				{/* top row */}
				<Col lg={8} md={12} className="mt-lg-0 mt-4">
					<RecentWorkflowTable />
				</Col>
				<Col lg={4} md={6} className="mt-lg-0 mt-4">
					<AssignmentsPanel link={'/admin/workflow/assignments'} />
				</Col>

				{/* 2nd row */}
				<Col lg={4} md={6} className="mt-4">
					<WorkflowHealthPanel />
				</Col>
				<Col lg={4} md={6} className="mt-4">
					<WorkflowDueDatesCalendar />
				</Col>
				<Col lg={4} md={6} className="mt-4">
					<RoadblocksPanel link={'/admin/workflow/roadblocks'} />
				</Col>

				{/* 3rd row */}
				<Col lg={4} md={6} className="mt-4">
					<SavedQueriesPanel />
				</Col>
			</Row>
		</Container>
	));
};

export default WorkflowDashboard;
