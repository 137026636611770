import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { GoHomeButton, Heading, Subheading } from './style';

const NotFound = () => (
	<Container fluid={true}>
		<Row>
			<Col className="text-center mt-5">
				<Heading>404</Heading>
				<Subheading>We couldn't find the page you were looking for.</Subheading>
				<GoHomeButton className="mt-4" to="/">
					Take me home
				</GoHomeButton>
			</Col>
		</Row>
	</Container>
);

export default NotFound;
