import styled from 'styled-components';

export const Preview = styled.div`
	display: inline-block;
	margin-left: 1%;
	overflow: hidden;
	top: 0;
	vertical-align: top;
	width: 32.666%;

	&:first-of-type {
		margin-left: 0;
	}

	canvas {
		width: 100%;
	}
`;
