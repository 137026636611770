import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HasReport } from '../../ReportModel';
import DownloaderButton from '../../../components/downloader-button';
import themeStore from '../../../theme/models/ThemeStore';

const ReportRow = ({ report }: HasReport) => {
	return (
		<tr>
			<td>{report.title}</td>
			<td>
				<DownloaderButton url={`workflow-reports/${report._id}`}>
					<FontAwesomeIcon icon={faDownload} className="mr-2" />
					<span>Download {themeStore._.report}</span>
				</DownloaderButton>
			</td>
		</tr>
	);
};

export default ReportRow;
