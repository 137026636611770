import { DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import dropDownMenuItem from '../../theme/components/drop-down-menu';
import themeStore from '../../theme/models/ThemeStore';
import { fsNormal } from '../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

const ActionsDropdownToggle = styled(DropdownToggle)`
	${dropDownMenuItem};

	&& {
		${fsNormal};
		border-color: ${colors.primary};
		color: ${colors.primary};
		background: transparent;
		border-radius: 2px !important;
		padding: 13px 20px !important;
	}

	&:hover {
		&& {
			border-color: ${colors.primary};
			background-color: ${colors.primary} !important;
			color: ${colors.white};
		}
	}
`;

export default ActionsDropdownToggle;
