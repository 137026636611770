import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';
import { NavbarBrand } from 'reactstrap';

import { focus } from '../../../theme/ui/ui';
import { Link } from '@reach/router';

const { colors, images } = themeStore.selectedTheme;

export const AppLogo = styled.div`
	background: url(${images.mobileLogo}) center center / contain no-repeat;
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	align-content: center;
`;

export const LogoContainer = styled(NavbarBrand)`
	display: flex;
	align-items: center;

	&:focus {
		${focus};
	}
`;

export const Sidebar = styled.div`
	flex-direction: column;
	display: flex;
	height: 100%;
	transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	z-index: 100;
	position: fixed;
	background-color: ${colors.white};
	border-right: 1px solid ${colors.secondary};
	width: 75px;
	left: -75px;

	@media only screen and (min-width: 768px) {
		left: 0;
	}
`;

export const SidebarHeader = styled.div`
	height: 75px;
	min-height: 75px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: ${colors.secondary};
`;

export const SidebarContent = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
`;

export const StyledLink = styled(Link)`
	text-align: center;
	width: 100%;
	height: 75px;
	border-bottom: 1px solid ${colors.grey};
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 30px;
		height: 30px;
	}
`;
