import React from 'react';
import ProductsFileUpload from '../products-file-upload';
import Subtitle from '../../../styled-components/elements/subtitle';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import { ProductTemplate } from '../../models/ProductTemplateInterface';
import { FormText } from 'reactstrap';

interface Props {
	setProductProgress: React.Dispatch<
		React.SetStateAction<{
			documentUploaded: boolean;
			templateSelected: boolean;
		}>
	>;
	setSheetHeaders: React.Dispatch<React.SetStateAction<string[]>>;
	setSubmissionData: React.Dispatch<
		React.SetStateAction<ProductSubmissionData | undefined>
	>;
	setTemplateSelected: React.Dispatch<
		React.SetStateAction<ProductTemplate | undefined>
	>;
}

const ProductDocumentSelect = ({
	setProductProgress,
	setSheetHeaders,
	setSubmissionData,
	setTemplateSelected,
}: Props) => {
	return (
		<>
			<Subtitle>1. Select a document</Subtitle>
			<ProductsFileUpload
				setProductProgress={setProductProgress}
				setSheetHeaders={setSheetHeaders}
				setSubmissionData={setSubmissionData}
				setTemplateSelected={setTemplateSelected}
			/>
			<FormText className="mt-2">
				Your document has to be an excel file
			</FormText>
		</>
	);
};

export default ProductDocumentSelect;
