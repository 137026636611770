import styled from 'styled-components';
import { Col } from 'reactstrap';
import themeStore from '../../../theme/models/ThemeStore';
import { fsSmol } from '../../../theme/ui/typography';
import { CardContainerStyles } from '../style';

const { colors } = themeStore.selectedTheme;

export const Sidebar = styled(Col)`
	position: relative;
	height: 100%;
	margin-bottom: 5em;
`;

export const StageWrapper = styled.div`
	padding: 3em 3em 4em;
`;

export const CanvasWrapper = styled.div`
	display: inline-block;
	position: relative;
	background-color: ${colors.lightGrey};
	max-height: 700px;
	min-height: 700px;
	overflow: scroll;
	width: 100%;

	.justify-content-center {
		margin-left: unset;
		margin-right: unset;
	}
`;

export const CanvasContent = styled.div`
	display: inline-block;
	position: absolute;
	top: 0;
	cursor: default;
	padding-bottom: 10em;

	padding-left: 0;
	padding-right: 0;
	max-width: unset;

	&.draggable {
		cursor: grab;

		&.dragging {
			cursor: grabbing;
		}
	}

	${CardContainerStyles};
`;

export const LastActionLabel = styled.p`
	${fsSmol}
`;
