import styled from 'styled-components';
import { Col } from 'reactstrap';

import { fsLarge, fsNormal } from '../../theme/ui/typography';

export const Column = styled(Col)`
	h2 {
		${fsLarge};
		margin-top: 1em;
	}

	p {
		${fsNormal};
		text-align: justify;
		margin: 0.5em 0;
	}
`;
