import styled from 'styled-components';
import { DropdownToggle, NavbarBrand, NavLink } from 'reactstrap';
import themeStore from '../../../theme/models/ThemeStore';

import { focus } from '../../../theme/ui/ui';
const { images, colors } = themeStore.selectedTheme;

export const StyledNavLink = styled(NavLink)`
	&:focus {
		${focus};
	}
`;

export const AvatarMenuToggle = styled(DropdownToggle)`
	&:after {
		position: relative;
		top: -8px;
	}

	&:focus {
		${focus};
	}
`;

export const MobileAppLogo = styled.div`
	background: url(${images.mobileLogo}) center center / contain no-repeat;
	width: 42px;
	height: 42px;
	align-items: center;
	align-content: center;
	display: flex;

	@media only screen and (min-width: 768px) {
		display: none;
	}
`;

export const MobileLogoContainer = styled(NavbarBrand)`
	align-items: center;
	background-color: ${colors.secondary};
	display: flex;
	padding: 5px;

	@media only screen and (min-width: 768px) {
		padding: 0;
		display: none;
	}

	&:focus {
		${focus};
	}
`;
