import React, { useCallback, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

import { AssetVersion } from '../../../dam-assets/models/AssetVersionModel';
import AssetCard from '../../../dam-assets/components/asset-card';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
} from '../../../dam-assets/components/asset-selection-toolbar';
import themeStore from '../../../theme/models/ThemeStore';
import { SelectedWorkflow } from '../../workflow.contexts';
import { WorkflowStageInputSlot } from '../../models';

const assetTerm = themeStore._.asset;

const tools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
];

// components
const renderNoResults = () => (
	<Col md={4} className="mb-4">
		<p>
			<em>No {assetTerm.toLowerCase()}s yet</em>
		</p>
	</Col>
);

const AssetsPanel = () => {
	const workflow = SelectedWorkflow.presentValue;
	const inputSlots = workflow.inputSlots;

	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<Array<AssetVersion>>([]);

	const isCardSelected = (asset: AssetVersion) => {
		if (selectedAssets) {
			return selectedAssets.includes(asset);
		} else {
			return false;
		}
	};

	// callbacks
	const toolbarCallBack = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (event.tool === AssetSelectionTool.SELECTION) {
				const isSelecting: boolean = event.value;
				setSelectingAssets(isSelecting);
				if (!isSelecting) {
					// empty selected assets if selecting is turned off
					setSelectedAssets([]);
				}
			}
		},
		[setSelectingAssets]
	);

	const assetSelectionCallBack = useCallback(
		(asset: AssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	// render methods
	const renderCards = (versions: readonly AssetVersion[]) =>
		versions.map((version) => (
			<Col lg={3} md={6} key={version._id} className="mt-1 mb-4">
				<AssetCard
					assetVersion={version}
					type="uploaded"
					isLinkEnabled={!isSelectingAssets}
					isSelected={isCardSelected(version)}
					onClick={assetSelectionCallBack}
				/>
			</Col>
		));

	const renderAssetCards = (slot: WorkflowStageInputSlot) => {
		if (slot.versions.length) {
			return renderCards(slot.versions);
		} else {
			return renderNoResults();
		}
	};

	return (
		<Container fluid={true}>
			<AssetSelectionToolbar
				tools={tools}
				className="workflow-assets-toolbar"
				onClick={toolbarCallBack}
				selectedAssets={selectedAssets}
			/>
			<Row>
				{inputSlots.map((slot) => (
					<Col md={12} key={slot._id} className="mt-4">
						<h2>
							{assetTerm} Uploads for "{slot.label}":
						</h2>
						<Row>{renderAssetCards(slot)}</Row>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default AssetsPanel;
