import React, { useCallback, useState } from 'react';
import { ButtonProps } from 'reactstrap';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { coerceToPromise, noop } from '../../common';

import PromiseSubmitButton from './promise-submit-button';

const SubmitButton = (
	props: ButtonProps & {
		label: string;
		canClick?: boolean;
		progressMsg?: string;
		icon?: IconDefinition;

		onClick?: (event: React.FormEvent) => unknown | Promise<unknown>;

		/** @deprecated */
		disabled?: boolean;
	}
) => {
	const {
		onClick = noop,
		canClick = true,
		disabled = false,
		...buttonProps
	} = props;

	const [promise, setPromise] = useState<Promise<unknown> | undefined>();

	const submit = useCallback(
		(event: React.FormEvent) => {
			event.preventDefault();

			if (!canClick) {
				// Should never happen, but you never know.
				return;
			}

			setPromise(coerceToPromise(onClick(event)));
		},
		[canClick, onClick]
	);

	return (
		<PromiseSubmitButton
			promise={promise}
			onClick={submit}
			canClick={canClick && !disabled}
			{...buttonProps}
		/>
	);
};

export default SubmitButton;
