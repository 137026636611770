import React from 'react';
import { useObserver } from 'mobx-react';
import { CardBody, Collapse } from 'reactstrap';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BaseWorkflowPhase } from '../../../models/BaseWorkflowPhaseModel';
import { useToggler } from '../../../common';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowTemplate } from '../../models';

import { PhaseIndicator, PhaseKeyCard, PhaseKeyCardHeader } from './style';
import Subheading from '../../../styled-components/elements/subheading';

const phaseTerm = themeStore._.phase;

interface TemplatePhaseKeyProps {
	template: WorkflowTemplate;
}

const TemplatePhaseKey = (props: TemplatePhaseKeyProps) => {
	const [collapse, toggleCollapse] = useToggler(true);

	const render = () => {
		const phases = props.template.phases;

		if (!phases.length) {
			return null;
		}

		return (
			<PhaseKeyCard>
				<PhaseKeyCardHeader onClick={toggleCollapse}>
					<Subheading>{phaseTerm} key</Subheading>
					<FontAwesomeIcon
						icon={collapse ? faMinus : faPlus}
						className="text-muted small"
					/>
				</PhaseKeyCardHeader>
				<Collapse isOpen={collapse}>
					<CardBody>
						{phases.map((phase: BaseWorkflowPhase) => (
							<div key={phase._id} className="d-flex">
								<PhaseIndicator background={phase.color} />
								<p>{phase.title}</p>
							</div>
						))}
					</CardBody>
				</Collapse>
			</PhaseKeyCard>
		);
	};

	return useObserver(render);
};

export default TemplatePhaseKey;
