import React from 'react';
import { capitalizeFirstLetter, buildClassList } from '../../common';
import { stageBadgeColorMap, StageStatus } from '../../models/StageStatusModel';
import themeStore from '../../theme/models/ThemeStore';
import { PaddedBadge } from '../../dashboard/components/workflow-health-flag/styled';

interface Props {
	className?: string;
	status: StageStatus;
}

const StageStatusPill = (props: Props) => {
	const { status, className } = props;

	const getStageLabel = () => {
		if (status === StageStatus.queue) {
			return themeStore._.queue;
		} else {
			return status;
		}
	};

	return (
		<PaddedBadge
			pill
			className={buildClassList('stage-status-pill', className)}
			color={stageBadgeColorMap[status]}
		>
			{capitalizeFirstLetter(getStageLabel())}
		</PaddedBadge>
	);
};

export default StageStatusPill;
