import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { unwrapEvent } from '../../../common';
import { useFilteredStore } from '../../../common/FilteredStore';
import { useAssetCollectionStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import windowModel from '../../../models/WindowModel';
import { QueryTypes } from '../../../queries/models/QueryStore';

import {
	builtInAssetCollectionPredicates,
	Filter,
} from '../../../common/filters';
import SaveQueryButton from '../../../components/save-query-button';
import SearchBar from '../../../components/search-bar';
import SideBarFiltersNav from '../../../components/side-bar-filters-nav';
import { StorePaginator } from '../../../components/store-paginator';
import Heading from '../../../styled-components/elements/heading';
import AssetCollectionListTable from '../../components/asset-collection-list-table';

import { NewCollectionButton } from './style';
import Divider from '../../../styled-components/elements/divider';

// constants
const label = themeStore._.assetCollection;

const filterNavOptions = [
	{
		label: `All ${label?.toLowerCase()}s`,
		value: undefined,
	},
	{
		label: 'Created by me',
		value: Filter.CREATED_BY,
	},
];

// component
const AssetCollectionsIndexView = () => {
	const assetCollectionStore = useAssetCollectionStore();
	const filteredCollectionStore = useFilteredStore(
		assetCollectionStore,
		builtInAssetCollectionPredicates
	);

	/*
	 Disabled until we assign a cover image to collections.
	 It is way too chatty to load the first asset for each collection.
	*/
	// const assetCollectionsViewOptions = indexViewStore.assetCollections;

	useEffect(() => {
		const searchTerm = windowModel.getURLSearchParam('q');
		if (searchTerm) {
			filteredCollectionStore.setQuery(searchTerm);
		}
	}, [filteredCollectionStore]);

	const render = () => (
		<Container fluid={true}>
			<Row>
				<Col xs={12}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem active>
							{themeStore._.asset} {label}s
						</BreadcrumbItem>
					</Breadcrumb>
					<Heading>
						{themeStore._.asset} {label}s
					</Heading>
				</Col>
				<Col md={10}>
					<SearchBar
						placeholder={`Search all ${label?.toLowerCase()}s...`}
						onChange={unwrapEvent(filteredCollectionStore.setQuery)}
					/>
				</Col>
				<Col md={2} className="mt-md-0 mt-3">
					<SaveQueryButton
						className="float-md-right float-none"
						searchTerm={filteredCollectionStore.searchQuery}
						type={QueryTypes.ASSET_COLLECTIONS}
					/>
				</Col>
				<Col xs={12} className="mt-4">
					<Divider />
				</Col>
			</Row>
			<Row>
				<Col md={2}>
					<NewCollectionButton to="./new">
						<FontAwesomeIcon icon={faPlus} className="mr-2" />
						New {label?.toLowerCase()}
					</NewCollectionButton>
					<SideBarFiltersNav
						setFilter={unwrapEvent(filteredCollectionStore.setFilter)}
						options={filterNavOptions}
					/>
				</Col>
				<Col md={10}>
					<AssetCollectionListTable
						collections={filteredCollectionStore.filteredEntities}
						noResultsLabel="No results"
					/>
					<StorePaginator
						pages={filteredCollectionStore.pages}
						shouldPaginate={filteredCollectionStore.shouldPaginate}
					/>
				</Col>
			</Row>
		</Container>
	);

	return useObserver(render);
};

export default AssetCollectionsIndexView;
