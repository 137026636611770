import { Instance } from 'mobx-state-tree';
import { makeEntityStore } from '../../models/EntityStore.model';
import {
	ProductRegistryModel,
	ProductRegistry,
	ProductRegistrySnapshot,
	loadingProductRegistry,
} from './ProductRegistryModel';

const ProductRegistryStoreModelInferred = makeEntityStore<
	ProductRegistryModel,
	ProductRegistry,
	ProductRegistrySnapshot
>(ProductRegistryModel, 'products', loadingProductRegistry)
	.views((self) => ({}))
	.actions((self) => ({}));

export interface ProductRegistryStoreModel
	extends Infer<typeof ProductRegistryStoreModelInferred> {}

export interface ProductRegistryStore
	extends Instance<ProductRegistryStoreModel> {}

export const ProductRegistryStoreModel: ProductRegistryStoreModel = ProductRegistryStoreModelInferred;
