import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { TemplateParallelStage } from '../../models';

import { SelectedTemplateStage } from '../../template.contexts';
import {
	FlexCardBody,
	MultiStageCard,
	MultiStageCardTitle,
} from '../template-stage-card/style';
import TemplateStageEditButtons from '../template-stage-edit-buttons';
import TemplateSubstageGroup from '../template-substage-group';
import { TemplateStageCardProps } from '../template-stage-card';
import { useRegisterElement } from '../../svg/ElementStore';
import { useEventHandler } from '../../../common';

const TemplateParallelStageCard = (
	props: TemplateStageCardProps<TemplateParallelStage>
) => {
	const [selectedStage, setSelectedStage] = SelectedTemplateStage.useMaybe();
	const [isPeeking, setPeeking] = useState(props.peeking);

	// build classes
	const selectedMarker = props.stage === selectedStage ? 'selected' : '';
	const peekingMarker = isPeeking ? 'peek' : '';
	const className = ['multi-stage-card', selectedMarker, peekingMarker].join(
		' '
	);

	useEffect(() => {
		if (isPeeking) {
			setPeeking(false);
		}
	}, [isPeeking]);

	const handleClick = useEventHandler(() => setSelectedStage(props.stage));

	const setStageElement = useRegisterElement(props.stage._id);

	// render methods
	const render = () => (
		<MultiStageCard
			className={className}
			innerRef={setStageElement}
			onClick={handleClick}
		>
			<MultiStageCardTitle>{props.stage.title}</MultiStageCardTitle>
			<TemplateStageEditButtons stage={props.stage} />
			<FlexCardBody>
				{props.stage.substages.map((stageGroup) => (
					<TemplateSubstageGroup
						key={stageGroup[0]?._id}
						stageGroup={stageGroup}
						editable={props.editable}
					/>
				))}
			</FlexCardBody>
		</MultiStageCard>
	);

	return useObserver(render);
};

export default TemplateParallelStageCard;
