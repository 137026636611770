import React, { PropsWithChildren, useState } from 'react';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalTitle } from './style';

import { useModalCloser } from '../../stores/ModalStack';

interface DialogProps {
	header: string;
	modalSize?: string;
}

// component
const DialogModal = ({
	header,
	modalSize,
	children,
}: PropsWithChildren<DialogProps>) => {
	const modalStack = useModalCloser();

	const [isOpen, setOpen] = useState(true);
	const close = () => setOpen(false);

	return (
		<Modal
			isOpen={isOpen}
			toggle={close}
			unmountOnClose={true}
			onClosed={modalStack.closeModal}
			size={modalSize}
		>
			<ModalHeader className="d-block">
				<ModalTitle>{header}</ModalTitle>
				<Button onClick={close} className="float-right btn-light">
					<FontAwesomeIcon icon={faTimes} />
				</Button>
			</ModalHeader>

			{children}
		</Modal>
	);
};

export default DialogModal;
