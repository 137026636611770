import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const ProgressContainer = styled.div`
	.bg-healthy {
		background-color: ${colors.healthy};
	}
	.bg-overdue {
		background-color: ${colors.overdue};
	}
	.bg-blocked {
		background-color: ${colors.blocked};
	}
`;
