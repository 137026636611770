import { css } from 'styled-components';

// font families
export const ffRegular = css`
	font-family: 'Circular-Book', sans-serif;
	font-weight: 400;
`;

export const ffBold = css`
	font-family: 'Circular-Medium', sans-serif;
`;

export const ffHeading = css`
	${ffBold};
`;

export const ffTextBody = css`
	${ffRegular};
`;

// font sizes
export const fsLarge = css`
	font-size: 15px;
`;

export const fsNormal = css`
	font-size: 14px;
`;

export const fsMedium = css`
	font-size: 13px;
`;

export const fsSmol = css`
	font-size: 12px;
`;
