import React from 'react';
import { useObserver } from 'mobx-react';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row } from 'reactstrap';

import {
	buildTemplateUrl,
	buildWorkflowCollectionUrl,
} from '../../../../common/links';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../../styled-components/blocks/entity-props';
import EntityMetadataFields from '../../../../metadata/components/entity-metadata-fields';
import themeStore from '../../../../theme/models/ThemeStore';
import { Workflow } from '../../../models';
import { SelectedWorkflow } from '../../../workflow.contexts';
import DetailsPanelEditForm from './details-panel-edit-form';
import { StyledPropLink, WorkflowStatusPillLg } from './style';
import { isIdentifiableLoaded } from '../../../../common';
import { getCreationDateOf } from '../../../../models/CreatableEntityModel';

const canOwnerActOnWorkflow = (workflow: Workflow) => {
	return workflow.isOwnedByCurrentUser || workflow.isCreatedByCurrentUser;
};

const WorkflowDetailPanel = () => {
	const workflow = SelectedWorkflow.presentValue;
	const label = themeStore._.workflow;

	return useObserver(() => {
		return (
			<>
				{canOwnerActOnWorkflow(workflow) ? (
					<DetailsPanelEditForm />
				) : (
					<Row className="px-3">
						<Col md={12}>
							<EntityPropList>
								<EntityPropListItem>
									<EntityPropLabel>{label} #</EntityPropLabel>
									<p>{workflow._id}</p>
								</EntityPropListItem>

								<EntityPropListItem>
									<EntityPropLabel>{label} Template used</EntityPropLabel>
									<StyledPropLink to={buildTemplateUrl(workflow.templateUsed)}>
										{workflow.templateUsed.title}
									</StyledPropLink>
								</EntityPropListItem>

								<EntityPropListItem>
									<EntityPropLabel>Created on</EntityPropLabel>
									<p>{getCreationDateOf(workflow).toLocaleDateString()}</p>
								</EntityPropListItem>

								<EntityPropListItem>
									<EntityPropLabel>Created by</EntityPropLabel>
									<p>{workflow.createdBy.name}</p>
								</EntityPropListItem>

								{workflow.dueDate ? (
									<EntityPropListItem>
										<EntityPropLabel>Due date</EntityPropLabel>
										<p>{workflow.dueDate.toLocaleDateString()}</p>
									</EntityPropListItem>
								) : null}

								{isIdentifiableLoaded(workflow.workflowCollection) ? (
									<EntityPropListItem>
										<EntityPropLabel>
											{themeStore._.workflowCollection}
										</EntityPropLabel>
										<StyledPropLink
											to={buildWorkflowCollectionUrl(
												workflow.workflowCollection
											)}
										>
											{workflow.workflowCollection.title}
										</StyledPropLink>
									</EntityPropListItem>
								) : null}

								<EntityPropListItem>
									<EntityPropLabel>Status</EntityPropLabel>
									<WorkflowStatusPillLg status={workflow.status} />
								</EntityPropListItem>

								{workflow.statusMsg ? (
									<EntityPropListItem>
										<EntityPropLabel>Current status message</EntityPropLabel>
										{workflow.statusMsg}
									</EntityPropListItem>
								) : null}

								<EntityMetadataFields metadata={workflow.metadata} />
							</EntityPropList>
						</Col>
					</Row>
				)}
			</>
		);
	});
};

export default WorkflowDetailPanel;
