import styled from 'styled-components';
import { Link } from '@reach/router';
import anchor from '../../../theme/components/anchor';
import themeStore from '../../../theme/models/ThemeStore';
import { fsNormal } from '../../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

export const AdminNavLink = styled(Link)`
	${anchor}
	${fsNormal}
	border-bottom: 1px solid transparent;

	&:hover {
		border-bottom: 1px solid ${colors.primaryHighlighted};
		text-decoration: none;
	}

	&.selected {
		border-bottom: 1px solid ${colors.primaryHighlighted};
		color: ${colors.primaryHighlighted};

		&:hover {
			border-bottom: 1px solid transparent;
		}
	}
`;
