import React, { useCallback } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { buildClassList } from '../../../common';
import { StageStatus } from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';

import { isFulfilled } from '../../models';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from './style';

import { StageActionButtonProps } from '.';
import ApproveStageDialog from './approve-stage-dialog';

const buildButtonClassList = (isFulfilled: boolean) => {
	let classList = 'action-button approve-stage-button';
	if (isFulfilled) {
		classList = `${classList} success`;
	} else {
		classList = `${classList} disabled`;
	}
	return classList;
};

const ApproveStageButton = (props: StageActionButtonProps) => {
	const { className, stage } = props;
	const modalStack = useModalCreator();

	const fulfilled = isFulfilled(stage);
	const buttonId = `approveStage${stage._id}`;
	const isButtonDisabled =
		!fulfilled || stage.status === StageStatus.roadblocked;

	const openApprovalModal = useCallback(() => {
		modalStack.addModal(<ApproveStageDialog stage={stage} />);
	}, [stage, modalStack]);

	return (
		<ActionButtonContainer
			className={buildClassList('stage-action approve-stage', className)}
		>
			<StyledTooltip target={buttonId}>
				{fulfilled ? 'Approve stage' : 'Stage is missing a required asset'}
			</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				onClick={openApprovalModal}
				className={buildButtonClassList(fulfilled)}
				disabled={isButtonDisabled}
			>
				<IconWithRightMargin icon={faCheck} />
				Complete
			</ActionButton>
		</ActionButtonContainer>
	);
};

export default ApproveStageButton;
