import React from 'react';
import { Container } from 'reactstrap';

import themeStore from '../../../theme/models/ThemeStore';
import { SelectedWorkflow } from '../../workflow.contexts';
import { Timeline } from '../timeline';

const collectionTerm = themeStore._.workflowCollection.toLowerCase();
const noAccessLogsMessage = (
	<p>{`There are no logs for this ${collectionTerm} yet.`}</p>
);

const AccessLogsPanel = () => {
	const workflow = SelectedWorkflow.presentValue;
	const hasEvents = workflow.stageEvents?.length;

	return (
		<Container fluid={true}>
			{hasEvents ? (
				<Timeline events={workflow.stageEvents} displayStageTitle={true} />
			) : (
				noAccessLogsMessage
			)}
		</Container>
	);
};

export default AccessLogsPanel;
