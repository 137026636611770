import React from 'react';
import Subtitle from '../../../styled-components/elements/subtitle';
import StyledSelect from '../../../styled-components/elements/select';
import useTemplateSelect from './useTemplateSelect';
import { ProductTemplate } from '../../models/ProductTemplateInterface';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import { Col, FormGroup, FormText } from 'reactstrap';

interface Props {
	templateSelected?: ProductTemplate;
	setTemplateSelected: React.Dispatch<
		React.SetStateAction<ProductTemplate | undefined>
	>;
	setProductProgress: React.Dispatch<
		React.SetStateAction<{
			documentUploaded: boolean;
			templateSelected: boolean;
		}>
	>;
	setSubmissionData: React.Dispatch<
		React.SetStateAction<ProductSubmissionData | undefined>
	>;
}

const ProductTemplateSelect = ({
	templateSelected,
	setTemplateSelected,
	setProductProgress,
	setSubmissionData,
}: Props) => {
	const { templateList } = useTemplateSelect();

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		const selectedTemplate = templateList.filter((y) => y.name === value);
		setTemplateSelected(selectedTemplate[0]);
		setSubmissionData((c) => {
			return { ...c, data: { productTemplateId: selectedTemplate[0]?.id } };
		});
		setProductProgress((c) => {
			return { ...c, templateSelected: !!value };
		});
	};

	return (
		<>
			<Subtitle>2. Select Template</Subtitle>

			<FormGroup row>
				<Col md={6}>
					<StyledSelect
						id="selectTemplate"
						name="template"
						value={templateSelected?.name}
						defaultValue=""
						onChange={(e) => handleChange(e)}
					>
						<option value="">Select one</option>
						{templateList.map((name, i) => {
							return <option key={i}>{name.name}</option>;
						})}
					</StyledSelect>
					<FormText className="mt-2">
						This document seems to fit the BOOK template
					</FormText>
				</Col>
			</FormGroup>
		</>
	);
};

export default ProductTemplateSelect;
