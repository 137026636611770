import { useObserver } from 'mobx-react';
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';
import themeStore from '../../../theme/models/ThemeStore';
import Heading from '../../../styled-components/elements/heading';

import { useProductRegistryStore } from '../../../stores';
import ScrollCard from '../../../styled-components/elements/scroll-card';
import ProductsRegistryListTable from '../../components/products-registry-list-table';
import Divider from '../../../styled-components/elements/divider';
import { useFilteredStore } from '../../../common/FilteredStore';
import { unwrapEvent } from '../../../common';
import SearchBar from '../../../components/search-bar';
import SaveQueryButton from '../../../components/save-query-button';
import { QueryTypes } from '../../../queries/models/QueryStore';

// constants
const label = themeStore._.productsRegistry;

export const ProductRegistryIndex = () => {
	const filteredProductRegistryStore = useFilteredStore(
		useProductRegistryStore(),
		{}
	);
	const render = () => (
		<Container fluid={true}>
			<Row>
				<Col xs={12}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem active>{label}</BreadcrumbItem>
					</Breadcrumb>
					<Heading>{label}</Heading>
				</Col>
				<Col md={10}>
					<SearchBar
						placeholder={`Search all ${label}s...`}
						onChange={unwrapEvent(filteredProductRegistryStore.setQuery)}
					/>
				</Col>
				<Col md={2} className="mt-md-0 mt-3">
					<SaveQueryButton
						className="float-md-right float-none"
						searchTerm={filteredProductRegistryStore.searchQuery}
						type={QueryTypes.PRODUCTS}
					/>
				</Col>

				<Col xs={12} className="mt-4">
					<Divider />
				</Col>
				<Col md={11} xs={12}>
					<ScrollCard>
						<ProductsRegistryListTable />
					</ScrollCard>
				</Col>
			</Row>
		</Container>
	);

	return useObserver(render);
};
