import React from 'react';
import {
	faFile,
	faFileArchive,
	faFilePdf,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import { HasAssetVersion } from '../../models/AssetVersionModel';
import DetailedViewButton from './detail-view-button';
import { FallbackIconWrapper, FallbackIcon, Message } from './style';

const fallbackMapping: Record<string, IconDefinition> = {
	'application/zip': faFileArchive,
	'application/pdf': faFilePdf,
	'application/postscript': faFilePdf,
};

export const FallbackViewer = ({ asset }: HasAssetVersion) => (
	<FallbackIconWrapper>
		<FallbackIcon icon={fallbackMapping[asset.type] || faFile} />
		<div className="mt-5">
			<Message>No preview available.</Message>
			<Message>There is no viewer available for this file type.</Message>
		</div>
	</FallbackIconWrapper>
);

export const DetailsOnlyViewer = ({ asset }: HasAssetVersion) => (
	<>
		<FallbackIconWrapper>
			<FallbackIcon icon={fallbackMapping[asset.type] || faFile} />
			<div className="mt-5">
				<p>No preview available.</p>
				<p>Only the detailed view is available for this file type.</p>
			</div>
		</FallbackIconWrapper>
		<div className="text-center">
			<DetailedViewButton asset={asset} />
		</div>
	</>
);
