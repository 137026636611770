import React from 'react';
import Moment from 'react-moment';

import { buildSavedQueryUrl } from '../../../common/links';
import { Query } from '../../../queries/models/QueryModel';
import { Heading, StyledLink } from './style';

interface Props {
	query: Query;
}

const SavedQueryItem = ({ query }: Props) => {
	const render = () => (
		<article>
			<Heading>"{query.value}"</Heading>
			<StyledLink to={buildSavedQueryUrl(query)}>
				Created in {query.type}, on{' '}
				<Moment format="MM/DD/YYYY" date={query.createdAt} />
			</StyledLink>
		</article>
	);

	return render();
};

export default SavedQueryItem;
