import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

interface AvatarProps {
	image: string;
}

const Avatar = styled.div<AvatarProps>`
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background-color: white;
  background-image: url('${(props) => props.image}');
  background-size: cover;
  background-position: center center;
`;

// modifiers
export const UserAvatar = styled(Avatar)`
	min-height: 5em;
	min-width: 5em;
	margin-bottom: 1.5em;
`;

export const CollaboratorAvatar = styled(Avatar)`
	border: 2px solid ${colors.white};
	margin-right: -0.5em;
	flex: none;
`;

export const CollaboratorAvatarXs = styled(CollaboratorAvatar)`
	border-width: 1px;
	width: 0.5em;
	height: 0.5em;
`;

export const CollaboratorAvatarSm = styled(CollaboratorAvatar)`
	border-width: 1px;
	width: 1em;
	height: 1em;
`;

export const CollaboratorAvatarMd = styled(CollaboratorAvatar)`
	border-width: 1px;
	width: 1.5em;
	height: 1.5em;
`;

export const CollaboratorAvatarLg = styled(CollaboratorAvatar)`
	border-width: 2px;
	width: 2em;
	height: 2em;
`;

export const CommentAvatar = styled(Avatar)`
	height: 30px;
	margin-right: 10px;
	width: 32px;
`;

export default Avatar;
