import styled from 'styled-components';
import { InputGroup } from 'reactstrap';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const SearchInputGroup = styled(InputGroup)`
	@media (min-width: 992px) {
		height: 100%;
	}

	border: 1px solid ${colors.grey};
`;

export default SearchInputGroup;
