import React, {
	PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { _logError } from '../../common/log';
import { useAPIClient } from '../../stores';
import { SecondaryButton } from '../../styled-components/elements/button';

const DownloaderButton = ({
	url,
	data = {},
	children,
}: PropsWithChildren<{
	url: string;
	data?: object;
}>) => {
	const [hasAuthCookie, setHasAuthCookie] = useState(false);
	const client = useAPIClient();
	const formRef = useRef<HTMLFormElement>(null);

	const triggerDownload = useCallback(
		() =>
			void client
				.refreshAuthCookie()
				.then(() => setHasAuthCookie(true))
				.catch(_logError),
		[client, setHasAuthCookie]
	);

	useEffect(() => {
		if (hasAuthCookie && formRef.current) {
			formRef.current.submit();
			setHasAuthCookie(false);
		}
	}, [hasAuthCookie]);

	return (
		<form
			className="d-inline-block"
			method="POST"
			target="_blank"
			ref={formRef}
			action={client.buildPath(url)}
		>
			<input type="hidden" name="data" value={JSON.stringify(data)} />
			<SecondaryButton type="button" onClick={triggerDownload}>
				{children}
			</SecondaryButton>
		</form>
	);
};

export default DownloaderButton;
