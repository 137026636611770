import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArchive,
	faTimes,
	faTrashRestore,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import { useModalCreator } from '../../../stores/ModalStack';
import { useTemplateStore } from '../../../stores';
import { ConfirmationDialog } from '../../../components/modals';

import { TemplateState, WorkflowTemplate } from '../../models';
import { capitalizeFirstLetter } from '../../../common';
import { StyledButton } from './style';

// interface
interface DeleteTemplateButtonProps {
	template: WorkflowTemplate;
}

interface TemplateStateChangeMessages {
	buttonId: string;
	tooltip: string;
	heading: string;
	warning: string;
	icon: IconDefinition;
}

const messagesForAction = (action: string, icon: IconDefinition) => ({
	buttonId: `${action}TemplateButton`,
	tooltip: `${capitalizeFirstLetter(action)} template`,
	heading: `${capitalizeFirstLetter(action)} Template`,
	warning: `Are you certain you want to ${action} this template?`,
	icon: icon,
});

const messagesForState: Record<TemplateState, TemplateStateChangeMessages> = {
	[TemplateState.draft]: messagesForAction('delete', faTimes),
	[TemplateState.final]: messagesForAction('archive', faArchive),
	[TemplateState.archived]: messagesForAction('unarchive', faTrashRestore),
};

// component
const DeleteTemplateButton = (props: DeleteTemplateButtonProps) => {
	const modalStack = useModalCreator();
	const templateStore = useTemplateStore();

	const { template } = props;

	const msgs = messagesForState[template.editingState];
	const buttonId = msgs.buttonId + template._id;

	// callbacks
	const showModal = useCallback(
		() =>
			modalStack.addModal(
				<ConfirmationDialog
					header={msgs.heading}
					onConfirm={() => templateStore.deleteOne(template)}
				>
					<p>{msgs.warning}</p>
				</ConfirmationDialog>
			),
		[template, templateStore, msgs, modalStack]
	);

	// render methods
	const renderButton = () => (
		<StyledButton id={buttonId} onClick={showModal}>
			<UncontrolledTooltip target={buttonId}>
				{msgs.tooltip}
			</UncontrolledTooltip>
			<FontAwesomeIcon icon={msgs.icon} />
		</StyledButton>
	);

	const render = () => (template.isOwnedByCurrentUser ? renderButton() : null);

	return render();
};

export default DeleteTemplateButton;
