import React from 'react';
import Moment from 'react-moment';

import { HasWorkflow } from '../../../workflows/models';
import { Cell, CellLink } from '../../../styled-components/blocks/table';
import WorkflowStatusPill from '../../../components/workflow-status-pill';
import WorkflowCompletionPieChart from './workflow-completion-pie-chart';
import WorkflowHealthFlag from '../workflow-health-flag';

const WorkflowDate = ({ date }: { date: Nullable<Date> }) =>
	date ? <Moment format="MMM DD" date={date} /> : <>TBD</>;

const RecentWorkflowRow = ({ workflow }: HasWorkflow) => (
	<tr>
		<Cell>
			<CellLink to={`./workflow/workflows/${workflow._id}/stages`}>
				{workflow.title}
			</CellLink>
		</Cell>

		<Cell>
			<WorkflowDate date={workflow.startDate} />
		</Cell>

		<Cell>
			<WorkflowDate date={workflow.dueDate} />
		</Cell>

		<Cell>
			<WorkflowStatusPill status={workflow.status} />
		</Cell>

		<Cell>
			<div className="d-flex">
				<WorkflowCompletionPieChart workflow={workflow} />
			</div>
		</Cell>

		<Cell>
			<WorkflowHealthFlag workflow={workflow} whenInactive={<p>—</p>} />
		</Cell>
	</tr>
);

export default RecentWorkflowRow;
