import React, { FormEvent, useCallback, useRef } from 'react';
import { getSnapshot } from 'mobx-state-tree';
import { navigate } from '@reach/router';

import { useAssetCollectionStore, useStores } from '../../../stores';
import { emptyCollection } from '../../models/AssetCollectionStore';
import AssetCollectionForm from '../asset-collection-form';

const AddAssetCollectionForm = () => {
	const collectionStore = useAssetCollectionStore();

	const collectionRef = useRef(emptyCollection(useStores()));

	const addCollection = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdCollection = await collectionStore.createOne(
				getSnapshot(collectionRef.current)
			);

			return navigate(`/admin/dam/collections/${createdCollection._id}`);
		},
		[collectionStore]
	);

	return (
		<AssetCollectionForm
			selectedCollection={collectionRef.current}
			onSubmit={addCollection}
		/>
	);
};
export default AddAssetCollectionForm;
