import styled from 'styled-components';
import select from '../../theme/components/select';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const StyledSelect = styled.select`
	${select};
	display: block;
	background-color: ${colors.white};
	width: 100%;
	padding: 12px;
`;

export default StyledSelect;
