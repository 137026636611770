import React, { useEffect, useState } from 'react';
import { onSnapshot } from 'mobx-state-tree';
import { useObserver } from 'mobx-react';
import debounce from 'lodash/debounce';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import { shouldShow } from '../../../common';
import { TemplateStage, WorkflowTemplateSnapshotOut } from '../../models';
import themeStore from '../../../theme/models/ThemeStore';
import {
	SelectedTemplate,
	SelectedTemplateStage,
} from '../../template.contexts';
import TemplateActions from '../template-actions';
import TemplateEditorCanvas from './template-editor-canvas';
import TemplateEditorSidebar from './template-editor-sidebar';
import { useTemplateStore } from '../../../stores';

import { LastActionLabel, Sidebar } from './style';
import TemplateStageEditForm from '../template-stage-edit-form';
import Heading from '../../../styled-components/elements/heading';

const AUTOSAVE_INTERVAL_MS = 1000;

const TemplateEditor = () => {
	const templateStore = useTemplateStore();
	const template = SelectedTemplate.presentValue;

	const [selectedStage, setSelectedStage] = useState<Maybe<TemplateStage>>(
		undefined
	);

	useEffect(() => {
		const autosaveTemplate = debounce(
			(snapshot: WorkflowTemplateSnapshotOut) => {
				if (snapshot.editingState === 'final') {
					// Remove the onSnapshot handler (defined below)
					// if template was finalized.
					disposer();
				}
				template.save(snapshot);
			},
			AUTOSAVE_INTERVAL_MS,
			{ leading: false, trailing: true }
		);
		const disposer = onSnapshot(template, (snapshot) => {
			template.setLastAction('Saving...');
			return autosaveTemplate(snapshot);
		});

		return disposer;
	}, [templateStore, template]);

	const render = () => (
		<SelectedTemplateStage.Provider value={[selectedStage, setSelectedStage]}>
			<Container fluid={true}>
				<Row>
					<Col md={9}>
						<div onClick={() => setSelectedStage(undefined)}>
							<Breadcrumb>
								<BreadcrumbItem tag={Link} to="/">
									Home
								</BreadcrumbItem>
								<BreadcrumbItem
									tag={Link}
									to=".."
								>{`${themeStore._.workflow} Templates`}</BreadcrumbItem>
								<BreadcrumbItem active>{template.title}</BreadcrumbItem>
							</Breadcrumb>
							<Heading>{template.title}</Heading>
						</div>
					</Col>
					<Col md={3}>
						<div className="text-right pt-1">
							<LastActionLabel>{template.lastAction}</LastActionLabel>
							<TemplateActions template={template} />
						</div>
					</Col>
				</Row>
			</Container>
			<Row className="p-0">
				<Col lg={9} md={8} className="p-0">
					<TemplateEditorCanvas />
				</Col>
				<Sidebar lg={3} md={4} className="pl-0 mb-0">
					{shouldShow(selectedStage) ? (
						<TemplateStageEditForm />
					) : (
						<TemplateEditorSidebar />
					)}
				</Sidebar>
			</Row>
		</SelectedTemplateStage.Provider>
	);

	return useObserver(render);
};

export default TemplateEditor;
