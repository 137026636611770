import React, { useState } from 'react';
import { Card, Nav } from 'reactstrap';
import { faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import {
	TabProps,
	TabsetButton,
	TabsetContent,
} from '../../../components/tabset';

import { AssetVersion } from '../../models/AssetVersionModel';
import { DamAsset } from '../../models/DamAssetModel';

import { TabNavItem } from './style';
import InfoPanel from './info-panel';
import VersionsPanel from './versions-panel';

export interface AssetTabsProps {
	asset: AssetVersion;
	damAsset?: DamAsset;
	tabs: Readonly<TabProps<AssetTabsProps>[]>;
}

const infoTab = {
	tabId: 'infoTab',
	label: 'Info',
	icon: faInfoCircle,
	content: InfoPanel,
};

const versionsTab = {
	tabId: 'versionsTab',
	label: 'Versions',
	icon: faCopy,
	content: VersionsPanel,
};

export const DetailsTabs: Readonly<TabProps<AssetTabsProps>[]> = [
	infoTab,
	versionsTab,
];

export const VersionTabs: Readonly<TabProps<AssetTabsProps>[]> = [
	infoTab,
	versionsTab,
];

// component
const AssetDetailsTabSet = (props: AssetTabsProps) => {
	const [activeTab, setActiveTab] = useState(props.tabs[0].tabId);

	const renderTabButton = (tabProps: TabProps) => (
		<TabNavItem key={tabProps.tabId}>
			<TabsetButton
				activeTab={activeTab}
				selectTab={setActiveTab}
				{...tabProps}
			/>
		</TabNavItem>
	);

	const renderTabContent = (tabProps: TabProps<AssetTabsProps>) => (
		<TabsetContent
			key={tabProps.tabId}
			activeTab={activeTab}
			selectTab={setActiveTab}
			{...tabProps}
		>
			{tabProps.content(props)}
		</TabsetContent>
	);

	return (
		<Card>
			<Nav tabs>{props.tabs.map(renderTabButton)}</Nav>
			{props.tabs.map(renderTabContent)}
		</Card>
	);
};

export default AssetDetailsTabSet;
