import { Instance, resolveIdentifier, types } from 'mobx-state-tree';
import { _logError, _logWarning } from '../../common/log';
import storageModel from '../../models/StorageModel';
import {
	acreageTheme,
	defaultTheme,
	kdpTheme,
	treehouseTheme,
} from '../themes';
import ThemeModel, { Theme } from './ThemeModel';
import { ThemeTerm, ThemeTerms } from './ThemeTermsModel';

const ThemeStoreModel = types
	.model('ThemeStore', {
		themes: types.array(ThemeModel),
		selectedTheme: types.reference(ThemeModel, {
			onInvalidated({ invalidId }) {
				_logWarning('Invalid theme ' + invalidId);
				return defaultTheme;
			},
		}),
	})
	.views((self) => ({
		get _(): ThemeTerms {
			return self.selectedTheme.terms;
		},
		getThemeWithId(id: string): Maybe<Theme> {
			return resolveIdentifier(ThemeModel, self, id);
		},
		getTerm(term: ThemeTerm): string {
			return this._[term];
		},
	}))
	.actions((self) => ({
		setSelected(model: Theme) {
			self.selectedTheme = model;
			storageModel.set('theme', model._id);
		},
		setSelectedWithId(id: string) {
			const theme = self.getThemeWithId(id);
			if (theme) {
				this.setSelected(theme);
			} else {
				_logError('Unable to set theme, id is not in list.');
			}
		},
	}));

export interface ThemeStoreModel extends Infer<typeof ThemeStoreModel> {}

export interface ThemeStore extends Instance<ThemeStoreModel> {}

const fixedTheme = process.env.REACT_APP_ROME_THEME;

export const themeStore: ThemeStore = ThemeStoreModel.create({
	themes: [defaultTheme, acreageTheme, kdpTheme, treehouseTheme],
	selectedTheme: fixedTheme || storageModel.get('theme') || defaultTheme._id,
});

export default themeStore;
