import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { generateID, loadingValue } from '../../common/index';
import { tolerantDate } from '../../models/common';

export const ProductRegistryFieldType = [
	'text',
	'image',
	'price',
	'numeric',
] as const;

const BaseProductFieldModelInferred = types.model('BaseProductField', {
	type: types.enumeration<typeof ProductRegistryFieldType[number]>(
		'ProductRegistryFieldType',
		ProductRegistryFieldType.map((x) => x)
	),
	name: types.string,
	label: types.string,
	required: types.boolean,
	isVariable: types.boolean,
});

const ProductImageField = BaseProductFieldModelInferred.named(
	'ProductImageField'
)
	.props({
		type: types.literal(ProductRegistryFieldType[1]),
		minWidth: types.number,
		maxWidth: types.number,
		minHeight: types.number,
		maxHeight: types.number,
		url: types.string,
	})
	.views(() => ({}));

const ProductFieldGroup = types.model('ProductFieldGroup', {
	name: types.string,
	items: types.array(ProductImageField),
});

export const loadingProductRegistry: ProductRegistrySnapshot = {
	_id: loadingValue,
	createdAt: new Date(),
};

const ProductRegistryModelInferred = types.model('ProductRegistryModel').props({
	_id: types.optional(types.identifier, generateID),
	createdAt: types.maybe(tolerantDate),
	updatedAt: types.maybe(tolerantDate),
	refId: types.optional(types.string, ''),
	name: types.optional(types.string, ''),
	template: types.optional(types.string, ''),
	fieldValues: types.map(types.union(types.string, types.number)),
	group: types.optional(types.string, ''),
});

export interface ProductRegistryModel
	extends Infer<typeof ProductRegistryModelInferred> {}

export const ProductRegistryModel: ProductRegistryModel = ProductRegistryModelInferred;

export interface ProductRegistrySnapshot
	extends SnapshotIn<ProductRegistryModel> {
	_id: string;
}
export interface ProductRegistry extends Instance<ProductRegistryModel> {}
