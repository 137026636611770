import React from 'react';
import Moment from 'react-moment';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Col, Container, Row } from 'reactstrap';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SecondaryButton } from '../../../styled-components/elements/button';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../styled-components/blocks/entity-props';
import OwnerList from '../../../components/owner-list';
import EntityMetadataFields from '../../../metadata/components/entity-metadata-fields';
import themeStore from '../../../theme/models/ThemeStore';
import { SelectedTemplate } from '../../template.contexts';
import { Aside, AsideContent, Line } from '../template-stage-edit-form/style';

const TemplateEditorSidebar = () => {
	const template = SelectedTemplate.presentValue;

	return useObserver(() => (
		<Aside>
			<AsideContent>
				<p>Template Details</p>
			</AsideContent>
			<Line />
			<Container fluid={true}>
				<Row className="p-0">
					<Col xs={7} className="p-0">
						<EntityPropList>
							<EntityPropListItem>
								<EntityPropLabel>Created on</EntityPropLabel>
								<p>
									<Moment format="MMM DD, YYYY" date={template.createdAt} />
								</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>Created by</EntityPropLabel>
								<p>{template.createdBy.name}</p>
							</EntityPropListItem>
							<EntityPropListItem>
								<EntityPropLabel>{themeStore._.owner}(s)</EntityPropLabel>
								<OwnerList entity={template} />
							</EntityPropListItem>
							<EntityMetadataFields metadata={template.metadata} />
						</EntityPropList>
					</Col>
					<Col xs={5} className="text-right pr-0">
						<Link to="./edit">
							<SecondaryButton>
								<FontAwesomeIcon icon={faEdit} className="mr-1" />
								Edit
							</SecondaryButton>
						</Link>
					</Col>
				</Row>
			</Container>
		</Aside>
	));
};

export default TemplateEditorSidebar;
