import React from 'react';
import { useObserver } from 'mobx-react';
import { ParallelSubstageWrapper } from '../template-stage-card/style';
import TemplateSingleStageCard from '../template-single-stage-card';
import SubstageCreationButton from '../substage-creation-button';
import { TemplateSubstage } from '../../models';

export interface TemplateSubstageGroupProps {
	stageGroup: TemplateSubstage[];
	editable?: boolean;
	peeking?: boolean;
}

const TemplateSubstageGroup = (props: TemplateSubstageGroupProps) => {
	const substages = useObserver(() =>
		props.stageGroup.flatMap((ts) => [
			<TemplateSingleStageCard
				key={ts._id}
				stage={ts}
				editable={props.editable}
				peeking={props.peeking}
			/>,
			<SubstageCreationButton key={`insert-${ts._id}`} stage={ts} />,
		])
	);

	return <ParallelSubstageWrapper>{substages}</ParallelSubstageWrapper>;
};

export default TemplateSubstageGroup;
