import React, { useState } from 'react';
import { useObserver } from 'mobx-react';
import { CalendarTileProperties } from 'react-calendar';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledCalendar } from './style';
import WorkflowDueDatesCalendarTile from './workflow-due-dates-calendar-tile';
import P from '../../../styled-components/elements/p';

import themeStore from '../../../theme/models/ThemeStore';

const workflowTerm = themeStore._.workflow;

const WorkflowDueDatesCalendar = () => {
	const [date] = useState(new Date());

	const buildTileContent = (options: CalendarTileProperties) => {
		return (
			<WorkflowDueDatesCalendarTile date={options.date} view={options.view} />
		);
	};

	const render = () => (
		<Card className="workflow-dure-date-calendar h-100">
			<CardHeader>
				<P className="mb-0">
					<FontAwesomeIcon icon={faCalendarCheck} className="mr-2" />
					{workflowTerm} Due Dates
				</P>
			</CardHeader>
			<CardBody>
				<StyledCalendar
					value={date}
					tileContent={buildTileContent}
					tileClassName="calendar-tile"
					tileDisabled={() => {
						return true;
					}}
				/>
			</CardBody>
		</Card>
	);

	return useObserver(render);
};

export default WorkflowDueDatesCalendar;
