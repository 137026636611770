import React from 'react';
import { useObserver } from 'mobx-react';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';

import SmallCTALink from '../../../components/small-cta-link';
import { useDashboardStore } from '../../../stores';
import AssignmentItem from '../assignment-item';
import { List, ListItem } from './style';
import { compareStageStatus } from '../../../models/StageStatusModel';
import P from '../../../styled-components/elements/p';

interface Props {
	link: string;
}

const AssignmentsPanel = (props: Props) => {
	const dashboardStore = useDashboardStore();

	const renderedAssignmentList = useObserver(() => {
		const ongoingStages = dashboardStore.recentWorkflows
			.flatMap((wf) => wf.stagesOwnedByCurrentUser)
			.sort(compareStageStatus)
			.slice(0, 4);
		return (
			<CardBody className="pb-1">
				<List className="assignments-list">
					{ongoingStages.length ? (
						ongoingStages.map((stage) => (
							<ListItem className="list-item" key={stage._id}>
								<AssignmentItem stage={stage} />
							</ListItem>
						))
					) : (
						<ListItem>
							<p>No assignments yet.</p>
						</ListItem>
					)}
				</List>
			</CardBody>
		);
	});

	return (
		<Card className="h-100">
			<CardHeader>
				<P className="mb-0">
					<FontAwesomeIcon icon={faList} className="mr-2" />
					My Assignments
				</P>
			</CardHeader>

			{renderedAssignmentList}

			<CardFooter className="bg-white border-top-0">
				<div className="float-right">
					<SmallCTALink link={props.link} label="View all assignments" />
				</div>
			</CardFooter>
		</Card>
	);
};

export default AssignmentsPanel;
