import styled from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100vh;

	@keyframes run {
		0% {
			color: ${colors.lightGrey};
			left: -90px;
		}
		50% {
			color: ${colors.darkestGrey};
		}
		100% {
			color: ${colors.lightGrey};
			left: 90px;
		}
	}
`;

export const LoadingDot = styled.div`
	position: relative;
	font-size: 25px;
	animation: ease-in-out infinite alternate;
	animation-name: run;
	animation-duration: 1.2s;
`;
