import React from 'react';
import { useObserver } from 'mobx-react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { StageStatus } from '../../../../models/StageStatusModel';
import { usePermissions } from '../../../../stores';
import {
	isActionable,
	isOwnedByCurrentUser,
	WorkflowParallelStageModel,
	WorkflowRootStageModel,
} from '../../../models';
import { SelectedWorkflowStage } from '../../../workflow.contexts';
import {
	ApproveStageButton,
	BypassStageButton,
	ForceActivateButton,
	RejectStageButton,
} from '../../stage-actions';
import { IconWithRightMargin } from '../../stage-actions/style';
import { Timeline } from '../../timeline';
import ActivateFirstStageButton from '../../stage-actions/activate-first-stage-button';
import CommentForm from '../../comment-form';
import { DetailCard, DetailCardBody } from './style';
import DetailCardHeader from './workflow-stage-card-header';
import StageCardDetails from './workflow-stage-card-details';
import StageInputSlots from '../workflow-stage-input-slots';
import StageInstructions from '../workflow-stage-index-panel/workflow-stage-instructions';
import Subheading from '../../../../styled-components/elements/subheading';

const StageDetailCard = () => {
	const stage = SelectedWorkflowStage.presentValue;
	const permissions = usePermissions();

	const renderForceActivateButton = () => {
		if (
			!WorkflowParallelStageModel.is(stage) &&
			!stage.isCompleted &&
			permissions.canForceActivateStage(stage)
		) {
			if (WorkflowRootStageModel.is(stage) && stage.initial) {
				return <ActivateFirstStageButton stage={stage} />;
			} else if (stage.firstStageIsActivated) {
				return <ForceActivateButton stage={stage} />;
			}
			return null;
		} else {
			return null;
		}
	};

	return useObserver(() => (
		<DetailCard>
			<DetailCardHeader stage={stage} />
			<DetailCardBody>
				<div className="d-flex mb-4 justify-content-between">
					<h2 className="align-self-center mb-0">
						{stage.status === StageStatus.completed ? (
							<IconWithRightMargin icon={faCheckCircle} className="completed" />
						) : null}
						<span className="d-inline-block">
							<Subheading>{stage.phase ? stage.phase.title : null}</Subheading>
							{stage.title}
						</span>
					</h2>
					<div className="d-flex">
						{isActionable(stage) ? (
							<>
								{isOwnedByCurrentUser(stage) ? (
									<>
										<ApproveStageButton stage={stage} />
										<RejectStageButton stage={stage} />
									</>
								) : null}
								<BypassStageButton stage={stage} />
							</>
						) : null}
						{renderForceActivateButton()}
					</div>
				</div>
				<StageCardDetails>
					<>
						<StageInstructions />
						<StageInputSlots />
						<div className="my-4">
							<Timeline events={stage.events} />
						</div>
						<CommentForm />
					</>
				</StageCardDetails>
			</DetailCardBody>
		</DetailCard>
	));
};

export default StageDetailCard;
