import React from 'react';
import { Workflow } from '../../../workflows/models';
import { StyledList } from './style';

interface TooltipWorkflowsDueListProps {
	workflows: ReadonlyArray<Workflow>;
}

const TooltipWorkflowsDueList = (props: TooltipWorkflowsDueListProps) => {
	const render = () => (
		<StyledList className="tooltip-workflows-due-list">
			{props.workflows.map((workflow) => (
				<li key={workflow._id} className="list-item">
					{workflow.title}
				</li>
			))}
		</StyledList>
	);

	return render();
};

export default TooltipWorkflowsDueList;
