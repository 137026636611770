import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import {
	Alert,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from 'reactstrap';

import { AuthContext } from '../../core';
import { MutedButton } from '../../styled-components/elements/button';
import SubmitButton from '../../components/submit-button';
import { useEventHandler } from '../../common';
import googleBrand from '../../assets/images/google.svg';
import { Logo } from './style';
import StyledInput from '../../styled-components/elements/input';

interface MaybeHasError {
	error?: Error;
}

interface LoginViewProps {
	location?: {
		state?: MaybeHasError;
	};
}

const LoginError = ({ error }: MaybeHasError) => {
	if (!error) {
		return null;
	}
	return (
		<Row className="justify-content-center mt-5">
			<Col lg={5} md={7}>
				<Alert color="danger">
					Sorry, there was an error logging you in. Please try again later.
					{error.message}
				</Alert>
			</Col>
		</Row>
	);
};

const Login = (props: LoginViewProps) => {
	const auth = React.useContext(AuthContext);

	const signInWithGoogle = useEventHandler(auth.signInWithGoogle);

	const [loginForm, setForm] = useState({ email: '', password: '' });
	const updateLogin = (event: any) => {
		const { target } = event;
		const { name, value } = target;
		setForm({
			...loginForm,
			[name]: value,
		});
	};
	const signInWithEmailPassword = useCallback(
		() => auth.signInWithEmailPassword(loginForm),
		[auth, loginForm]
	);

	return (
		<Container fluid={true}>
			<LoginError error={props.location?.state?.error} />
			<Row className="justify-content-center">
				<Col lg={5} md={7} className="mt-5">
					<Card className="mt-4 shadow-none">
						<CardHeader className="py-5 text-center">
							<Logo />
							<div className="btn-wrapper text-center">
								<MutedButton
									type="button"
									className="btn-light"
									onClick={signInWithGoogle}
								>
									<span className="d-inline-block">
										<img alt="Google" src={googleBrand} className="w-50" />
									</span>
									<span className="d-inline-block">
										<span className="mb-0">Sign in with Google</span>
									</span>
								</MutedButton>
							</div>
						</CardHeader>
						<CardBody className="px-lg-5 py-lg-5">
							<div className="text-center text-muted mb-4">
								<small>Or sign in with credentials</small>
							</div>
							<Form role="form" onSubmit={signInWithEmailPassword}>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faEnvelope} />
											</InputGroupText>
										</InputGroupAddon>
										<StyledInput
											name="email"
											placeholder="Email"
											type="email"
											autoComplete="on"
											onChange={updateLogin}
										/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faLock} />
											</InputGroupText>
										</InputGroupAddon>
										<StyledInput
											name="password"
											placeholder="Password"
											type="password"
											autoComplete="on"
											onChange={updateLogin}
										/>
									</InputGroup>
								</FormGroup>
								<div className="custom-control custom-control-alternative custom-checkbox">
									<input
										className="custom-control-input"
										id=" customCheckLogin"
										type="checkbox"
									/>
									<label
										className="custom-control-label"
										htmlFor=" customCheckLogin"
									>
										<p className="text-muted">Remember me</p>
									</label>
								</div>
								<div className="text-center">
									<SubmitButton
										label="Sign in"
										onClick={signInWithEmailPassword}
									/>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
