import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const Toolbar = styled.div`
	display: inline-flex;
`;

// TODO: extract to reusable component (see also IndexViewToggler)
export const ToolbarButton = styled.button`
	background: transparent;
	border-radius: 2px;
	border: none;
	color: ${colors.darkGrey};
	margin-left: 6px;

	&.active {
		color: ${colors.darkestGrey};
	}

	&:hover {
		color: ${colors.darkestGrey};
	}
`;
