import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { Col, Input, Row } from 'reactstrap';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

import CircleButton from '../../../components/circle-button';
import { EntityMetadata } from '../../models/EntityMetadataModel';

const ExistingMetadataFields = ({ metadata }: { metadata: EntityMetadata }) => {
	const handleRemoveField = useCallback(
		(field: string) => {
			metadata.removeField(field);
		},
		[metadata]
	);

	const render = () => (
		<>
			{metadata.fields.map((fieldKey: string) => (
				<Row key={fieldKey} className="mb-3 mb-md-0">
					<Col md={4} xs={10}>
						<Input readOnly type="text" name="key" value={fieldKey} />
					</Col>
					<Col md={5} xs={10}>
						<Input
							type="text"
							name="value"
							defaultValue={metadata.values.get(fieldKey)}
							onChange={(event) =>
								metadata.setKvPair(fieldKey, event.target.value)
							}
						/>
					</Col>
					<Col md={3} xs={2}>
						<CircleButton
							className="sm"
							color="danger"
							id="removeMetadataItem"
							icon={faMinus}
							onClick={() => handleRemoveField(fieldKey)}
							tooltip="Remove metadata entry"
						/>
					</Col>
				</Row>
			))}
		</>
	);

	return useObserver(render);
};

export default ExistingMetadataFields;
