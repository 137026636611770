import { css } from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

// style props
export const CardStyleProps = {
	minHeight: 100,
	width: 165,
};

const CardMarginLeft = CardStyleProps.width * -0.5;

export const MultiStageCardStyleProps = {
	minWidth: 400,
	minHeight: 140,
};

// style definitions
export const SingleStageCardStyles = css`
	position: relative;
	border-bottom-color: transparent !important;
	border-bottom-style: solid !important;
	border-bottom-width: 3px !important;
	cursor: pointer;
	min-height: ${CardStyleProps.minHeight}px;
	overflow: hidden;
	width: ${CardStyleProps.width}px;
	transition: transform 1ms ease-in-out, background-color 1s ease-out;

	&.selected {
		border-bottom-color: ${colors.primaryHighlighted} !important;
		z-index: 2;
	}

	&:hover {
		border-bottom-color: ${colors.primaryHighlighted} !important;
		transition: transform 1ms ease-in-out;
	}

	&.peek {
		background-color: ${colors.primaryHighlighted};
		transition: background-color 0s ease-out;
	}
`;

export const MultiStageCardStyles = css`
	background-color: transparent !important;
	border: 2px dashed ${colors.grey} !important;
	box-shadow: none !important;
	min-height: ${MultiStageCardStyleProps.minHeight}px !important;
	min-width: ${MultiStageCardStyleProps.minWidth}px !important;
	width: max-content !important;
	margin: auto !important;
	left: auto !important;
	overflow: visible;
	z-index: 2 !important;

	&.selected {
		border-bottom-color: ${colors.primaryHighlighted} !important;
	}

	.single-stage-card {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 20px;
		min-height: ${CardStyleProps.minHeight}px;
	}
`;

export const CardContainerStyles = css`
	.card {
		left: 50%;

		&.single-stage-card {
			margin-left: ${CardMarginLeft}px;

			&.single-stage-card {
				display: block;
			}
		}

		&.multi-stage-card {
			width: 100%;
			margin-left: -50%;

			.card {
				left: unset;
				width: ${CardStyleProps.width}px;
				margin-left: unset;
			}
		}
	}
`;
