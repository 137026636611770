import React, { useCallback, useRef } from 'react';
import { ModalBody } from 'reactstrap';
import { _logError } from '../../../common/log';
import { DialogModal } from '../../../components/modals';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useUserStore } from '../../../stores';
import { useModalCloser } from '../../../stores/ModalStack';

import EditUserForm from '../edit-user-form';
import { UserSnapshotIn } from '../../../accounts/models/UserModel';
import { generateID } from '../../../common';

const AddUserDialog = () => {
	const userStore = useUserStore();
	const modalStack = useModalCloser();
	const userRef = useRef({ _id: generateID() } as UserSnapshotIn);

	const addUser = useCallback(
		async (userSnapshot: UserSnapshotIn) => {
			try {
				await userStore.createOne(userSnapshot);
				modalStack.closeModal();

				notificationStore.push(
					NotificationModel.create({
						text: 'User added!',
					})
				);
			} catch (error) {
				_logError(error);
				modalStack.closeModal();

				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An issue occurred while adding user. Please try again later.`,
					})
				);
			}
		},
		[userStore, modalStack]
	);

	return (
		<DialogModal header="Invite user">
			<ModalBody>
				<EditUserForm
					selectedUser={userRef.current}
					onSubmit={addUser}
					submitLabel="Create & send invitation"
				/>
			</ModalBody>
		</DialogModal>
	);
};

export default AddUserDialog;
