import { css } from 'styled-components';
import themeStore from '../models/ThemeStore';
import { primaryButton } from './primary-button';

const { colors } = themeStore.selectedTheme;

export const secondaryButton = css`
	${primaryButton};
	background-color: ${colors.secondary} !important;
	color: ${colors.black} !important;

	&:hover {
		background-color: ${colors.secondaryHighlighted} !important;
		color: ${colors.black} !important;
	}
`;

export const outlinedSecondaryButton = css`
	${secondaryButton};

	&& {
		background-color: transparent;
		color: ${colors.secondary};
		border: 1px solid ${colors.secondary};

		&:hover {
			color: ${colors.white};
			background-color: ${colors.secondary};
		}
	}
`;
