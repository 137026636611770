import React, { useCallback } from 'react';
import {
	Breadcrumb,
	BreadcrumbItem,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from 'reactstrap';
import { Link } from '@reach/router';

import { camelCaseToSentenceCase } from '../../common';
import { _logError } from '../../common/log';
import Heading from '../../styled-components/elements/heading';
import NotificationModel, {
	NotificationType,
} from '../../notifications/NotificationModel';
import notificationStore from '../../notifications/NotificationStore';
import { useUserStore } from '../../stores';
import { UserSnapshotIn } from '../../accounts/models/UserModel';
import { UserAvatar } from '../../styled-components/elements/avatar';
import EditUserForm from '../../admin-dashboard/components/edit-user-form';

const EditProfile = () => {
	const userStore = useUserStore();

	const currentUser = userStore.currentUser;

	const onSubmit = useCallback(
		async (updatedUser: UserSnapshotIn) => {
			try {
				await userStore.updateOne(updatedUser);

				notificationStore.push(
					NotificationModel.create({
						text: 'Profile saved!',
					})
				);
			} catch (error) {
				_logError(error);

				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An issue occurred while updating your profile. Please try again later.`,
					})
				);
			}
		},
		[userStore]
	);

	return (
		<Container fluid={true}>
			<Row>
				<Col xs={12}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem active>Edit my profile</BreadcrumbItem>
					</Breadcrumb>
					<Heading>Edit my profile</Heading>

					<Card>
						<CardBody>
							<Row>
								<Col
									lg={3}
									className="d-flex justify-content-center align-items-center flex-column"
								>
									<UserAvatar image={currentUser.picture} />
									<h2>{currentUser.name}</h2>
									<p>{camelCaseToSentenceCase(currentUser.role)}</p>
								</Col>
								<Col lg={9} className="p-4">
									<EditUserForm
										selectedUser={currentUser}
										onSubmit={onSubmit}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default EditProfile;
