import { APIClient } from './APIClient';
import { AuthProvider, authProvider } from './AuthProvider';
import { getEnv, IAnyStateTreeNode } from 'mobx-state-tree';
import { requiredValue, throwErr } from '../common';
import { FileUploadProvider } from './FileUploadProvider';

export * from './APIClient';
export * from './AuthProvider';

const aexApiClient = new APIClient(authProvider);

const uploadProvider = new FileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);

export interface RootEnv {
	readonly authProvider: AuthProvider;
	readonly apiClient: APIClient;
	readonly uploadProvider: FileUploadProvider;
}

export const rootEnv: RootEnv = Object.freeze({
	authProvider: authProvider,
	apiClient: aexApiClient,
	uploadProvider,
});

interface EnvGetter<T> {
	(node: IAnyStateTreeNode): T;
}

const envGetter = <K extends keyof RootEnv>(key: K): EnvGetter<RootEnv[K]> => (
	node
) =>
	getEnv<RootEnv>(node)[key] ??
	throwErr(
		'Node is not part of the tree! Make sure to attach before getting env.'
	);

export const getClient = envGetter('apiClient');
export const getAuthProvider = envGetter('authProvider');
export const getUploader = envGetter('uploadProvider');
