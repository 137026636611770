import styled from 'styled-components';
import { Card } from 'reactstrap';

export const ScrollCard = styled(Card)`
	overflow-y: scroll;

	&& {
		background-color: transparent;
	}
`;

export default ScrollCard;
