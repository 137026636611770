import styled from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';
import { fsNormal } from '../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

export const ItemOptionList = styled.ul`
	${fsNormal};
	background-color: ${colors.white};
	border-radius: 0 0 2px 2px;
	border: 1px solid ${colors.darkGrey};
	margin-top: -3px;
	padding-inline-start: 0 !important;
	padding: 8px 0;
`;

export const ItemOption = styled.li`
	cursor: pointer;
	list-style: none;
	padding: 0.25em 0.5em;

	&:hover {
		background-color: ${colors.secondaryHighlighted} !important;
		color: ${colors.white};
	}
`;

export const DownshiftButton = styled.button`
	display: flex;
	position: absolute;
	align-items: center;
	background-color: transparent;
	border: none;
	bottom: 0;
	cursor: pointer;
	flex-direction: column;
	height: 47px;
	justify-content: center;
	outline: none;
	right: 11px;

	&:focus {
		outline: none;
	}
`;
