import React from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StorePaginator } from '../../../components/store-paginator';
import ScrollCard from '../../../styled-components/elements/scroll-card';
import Heading from '../../../styled-components/elements/heading';
import { useTemplateStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import TemplateListTable from '../../components/template-list-table';
import CreateTemplateButton from '../../../styled-components/elements/create-template-button';
import Divider from '../../../styled-components/elements/divider';

const baseWorkflowTerm = themeStore._.baseWorkflow;

const TemplatesIndexView = () => {
	const templateStore = useTemplateStore();

	const render = () => (
		<Container fluid={true}>
			<Row>
				<Col lg={10} md={9}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem active>{baseWorkflowTerm} Templates</BreadcrumbItem>
					</Breadcrumb>
					<Heading>{baseWorkflowTerm} Templates</Heading>
				</Col>
				<Col lg={2} md={3} className="text-right">
					<CreateTemplateButton to="./new">
						<FontAwesomeIcon icon={faPlus} className="mr-2" />
						New template
					</CreateTemplateButton>
				</Col>
				<Col xs={12}>
					<Divider />
				</Col>
				<Col md={11} xs={12}>
					<ScrollCard>
						<TemplateListTable />
					</ScrollCard>

					{templateStore.pages.totalPages > 1 ? (
						<StorePaginator pages={templateStore.pages} />
					) : null}
				</Col>
			</Row>
		</Container>
	);

	return useObserver(render);
};

export default TemplatesIndexView;
