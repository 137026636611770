import React from 'react';
import { Router } from '@reach/router';

import { getRGBfromHEX } from '../common';
import Footer from '../components/footer';
import Login from '../views/login';
import themeStore from '../theme/models/ThemeStore';
import { LoginViewWrapper } from '../styled-components/elements/view-wrapper';

const { images, colors } = themeStore.selectedTheme;
const rgb = getRGBfromHEX(colors.secondary);

const Auth = () => {
	return (
		<div style={{ position: 'relative' }}>
			<LoginViewWrapper image={images.loginBackground} rgb={rgb}>
				<Router>
					<Login path="login" default />
				</Router>
			</LoginViewWrapper>
			<Footer style={{ position: 'inherit' }} />
		</div>
	);
};

export default Auth;
