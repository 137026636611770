import React from 'react';
import { useObserver } from 'mobx-react';
import { Table } from 'reactstrap';
import { useReportsStore } from '../../../stores';
import { NoResultsRow } from '../../../components/no-results-row';
import ReportRow from './report-row';
import ScrollCard from '../../../styled-components/elements/scroll-card';

const ReportsTable = () => {
	const reportsStore = useReportsStore();

	return useObserver(() => {
		const reports = reportsStore.all;

		return (
			<ScrollCard>
				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>{/* Button Column */}</th>
						</tr>
					</thead>
					<tbody>
						{reports.length ? (
							reports.map((r) => <ReportRow key={r._id} report={r} />)
						) : (
							<NoResultsRow term="report" />
						)}
					</tbody>
				</Table>
			</ScrollCard>
		);
	});
};

export default ReportsTable;
