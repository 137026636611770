import React, { FormEvent } from 'react';
import { Form, FormGroup, ModalBody } from 'reactstrap';

import { _logError } from '../../../common/log';
import SubmitButton from '../../../components/submit-button';
import { useAssetCollectionStore } from '../../../stores';
import { useEditingFlow } from '../../../common';
import { useModalCloser } from '../../../stores/ModalStack';
import { DialogModal } from '../../../components/modals';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import themeStore from '../../../theme/models/ThemeStore';

import { AssetCollection } from '../../models/AssetCollectionModel';
import CollectionAssetSelect from '../collection-asset-select';

const assetTerm = themeStore._.asset;
const collectionTerm = themeStore._.assetCollection;

// interface
interface Props {
	selectedCollection: AssetCollection;
}

// component
const AddAssetsDialog = (props: Props) => {
	const modalStack = useModalCloser();
	const collectionStore = useAssetCollectionStore();
	const [, getCollectionSnapshot, setLastKnownGood] = useEditingFlow(
		props.selectedCollection
	);

	const onFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedCollection = await collectionStore.updateOne(
				getCollectionSnapshot()
			);
			setLastKnownGood(updatedCollection);

			modalStack.closeModal();

			notificationStore.push(
				NotificationModel.create({
					text: `${collectionTerm} saved!`,
				})
			);
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while adding ${assetTerm} to ${collectionTerm}. Please try again later.`,
				})
			);
		}
	};

	return (
		<DialogModal header={`Add ${themeStore._.asset.toLowerCase()}s`}>
			<ModalBody>
				<Form>
					<FormGroup>
						<CollectionAssetSelect collection={props.selectedCollection} />
					</FormGroup>
					<SubmitButton label="Save" onClick={onFormSubmit} />
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default AddAssetsDialog;
