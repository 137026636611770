import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import {
	faCog,
	faSignOutAlt,
	faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Collapse,
	Container,
	DropdownMenu,
	Nav,
	Navbar,
	NavbarToggler,
	NavItem,
	UncontrolledDropdown,
} from 'reactstrap';
import { useAuth, useUserStore } from '../../../stores';
import { AvatarMenuToggle, StyledNavLink } from './style';
import Avatar from '../../../styled-components/elements/avatar';
import { DropDownMenuItem } from '../../../styled-components/elements/dropdown-menu';
import {
	initializeIntercom,
	shutdownIntercom,
} from '../../../third-parties/intercom.service';
import { PermissionsCheck } from '../../../accounts/permissions-check.component';
import Link from '../../../styled-components/elements/link';
import { useToggler } from '../../../common';
import themeStore from '../../../theme/models/ThemeStore';
import { MobileLogoContainer, MobileAppLogo } from './style';

const AdminNavigation = () => {
	const auth = useAuth();
	const userStore = useUserStore();

	const [isOpen, toggle] = useToggler(false);

	useEffect(() => {
		// todo Fix email in user schema
		initializeIntercom(userStore.currentUser as any);
		return () => shutdownIntercom();
	}, [auth.isAuthenticated, userStore]);
	const render = () => {
		const currentUser = userStore.currentUser;

		return (
			<Navbar light expand="md" className="px-md-0 pb-md-2 pb-4">
				<Container fluid={true}>
					<MobileLogoContainer tag={Link} to="/">
						<MobileAppLogo />
					</MobileLogoContainer>
					<NavbarToggler onClick={toggle} />
					<Collapse isOpen={isOpen} navbar>
						<Nav className="mr-auto d-md-none" navbar>
							<NavItem>
								<StyledNavLink tag={Link} to="dam/assets">
									{`${themeStore._.asset}s`}
								</StyledNavLink>
							</NavItem>
							<NavItem>
								<StyledNavLink tag={Link} to="dam/collections">
									{`${themeStore._.asset} ${themeStore._.assetCollection}s`}
								</StyledNavLink>
							</NavItem>
							<PermissionsCheck check="canViewTemplates">
								<NavItem>
									<StyledNavLink tag={Link} to="workflow/templates">
										{`${themeStore._.workflow} Templates`}
									</StyledNavLink>
								</NavItem>
							</PermissionsCheck>
							<NavItem>
								<StyledNavLink tag={Link} to="workflow/workflows">
									{`${themeStore._.workflow}s`}
								</StyledNavLink>
							</NavItem>
							<NavItem>
								<StyledNavLink tag={Link} to="workflow/projects">
									{`${themeStore._.workflowCollection}s`}
								</StyledNavLink>
							</NavItem>
							<NavItem>
								<StyledNavLink tag={Link} to="products">
									Product Registry
								</StyledNavLink>
							</NavItem>
							<PermissionsCheck check="canViewReports">
								<NavItem>
									<StyledNavLink tag={Link} to="reports">
										{themeStore._.report}s
									</StyledNavLink>
								</NavItem>
							</PermissionsCheck>
						</Nav>

						<Nav className="ml-auto" navbar>
							<PermissionsCheck check="canViewAdminPanel">
								<NavItem>
									<StyledNavLink tag={Link} to="dashboard">
										Admin
									</StyledNavLink>
								</NavItem>
							</PermissionsCheck>

							<UncontrolledDropdown nav inNavbar>
								<AvatarMenuToggle nav caret>
									<Avatar image={currentUser.avatar} />
								</AvatarMenuToggle>
								<DropdownMenu right>
									<DropDownMenuItem tag={Link} to="workflow/assignments">
										<small>
											<FontAwesomeIcon
												icon={faTasks}
												className="text-muted mr-2"
											/>
										</small>
										My Assignments
									</DropDownMenuItem>
									<DropDownMenuItem divider />
									<DropDownMenuItem tag={Link} to="profile">
										<small>
											<FontAwesomeIcon
												icon={faCog}
												className="text-muted mr-2"
											/>
										</small>
										Edit profile
									</DropDownMenuItem>
									<DropDownMenuItem divider />
									<DropDownMenuItem onClick={auth.signOut}>
										<small>
											<FontAwesomeIcon
												icon={faSignOutAlt}
												className="text-muted mr-2"
											/>
										</small>
										Logout
									</DropDownMenuItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		);
	};

	return useObserver(render);
};

export default AdminNavigation;
