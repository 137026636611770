import React from 'react';
import { useObserver } from 'mobx-react';
import { Container } from 'reactstrap';

import { useTemplateStore } from '../../../stores';
import { LoadingRow } from '../../../components/loading-indicator';
import TemplateEditor from '../../components/template-editor';
import TemplateFinalDetails from '../../components/template-final-details';
import { SelectedTemplate } from '../../template.contexts';

interface WorkflowTemplateDetailProps {
	workflowTemplateId: string;
}

const TemplateDetailsView = ({
	workflowTemplateId,
}: WorkflowTemplateDetailProps) => {
	const templateStore = useTemplateStore();

	return useObserver(() => {
		const template = templateStore.getOne(workflowTemplateId);

		if (!template) {
			return (
				<Container fluid={true}>
					<LoadingRow />
				</Container>
			);
		}

		return (
			<SelectedTemplate.Provider value={SelectedTemplate.asReadonly(template)}>
				{template.isDraft ? <TemplateEditor /> : <TemplateFinalDetails />}
			</SelectedTemplate.Provider>
		);
	});
};

export default TemplateDetailsView;
