import React from 'react';
import { useObserver } from 'mobx-react';

import DeleteEntityButton from '../../../components/delete-entity-button';
import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';
import OwnerList from '../../../components/owner-list';
import { CellLink } from '../../../styled-components/blocks/table';
import { usePermissions, useWorkflowCollectionStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowCollection } from '../../models/WorkflowCollectionModel';
import ScrollCard from '../../../styled-components/elements/scroll-card';

// constants
const projectTerm = themeStore._.workflowCollection;

// interface
interface Props {
	collections: readonly WorkflowCollection[];
}

// component
const WorkflowCollectionListTable = (props: Props) => {
	const { collections } = props;

	const permissions = usePermissions();
	const collectionStore = useWorkflowCollectionStore();

	const collectionListColumns: ListColumn<WorkflowCollection>[] = makeColumns([
		{
			label: 'Name',
			prop: (c: WorkflowCollection) => (
				<CellLink to={`./${c._id}`}>{c.title}</CellLink>
			),
		},
		{
			label: `${themeStore._.owner}(s)`,
			prop: (c: WorkflowCollection) => <OwnerList entity={c} />,
		},
		{
			label: `${themeStore._.workflow} count`,
			prop: (c: WorkflowCollection) => <p>{c.workflows.length}</p>,
		},
		{
			label: '',
			prop: (c: WorkflowCollection) =>
				c.workflows.length === 0 && permissions.canEditWorkflowCollection(c) ? (
					<DeleteEntityButton
						entity={c}
						deleteOne={collectionStore.deleteOne}
						entityName={projectTerm}
					/>
				) : null,
		},
	]);

	const render = () => (
		<ScrollCard className="mt-2">
			<ListTable
				columns={collectionListColumns}
				rows={collections ? collections : []}
				noResultsLabel={`No ${projectTerm.toLowerCase()}s yet`}
			/>
		</ScrollCard>
	);

	return useObserver(render);
};

export default WorkflowCollectionListTable;
