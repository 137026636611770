import React, { useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputGroupAddon } from 'reactstrap';

import windowModel from '../../models/WindowModel';
import {
	SearchButton,
	SearchInput,
	SearchInputGroup,
} from '../../styled-components/blocks/search-bar';

const SearchBox = ({
	placeholder,
	currentRefinement,
	refine,
}: {
	placeholder: string;
	currentRefinement: string;
	refine: (event: any) => void;
}) => {
	useEffect(() => {
		const queryValue = windowModel.getURLSearchParam('q');
		if (queryValue) {
			refine(queryValue);
		}
	}, [refine]);

	return (
		<SearchInputGroup className="h-100">
			<SearchInput
				type="search"
				placeholder={placeholder}
				value={currentRefinement}
				onChange={(event: any) => refine(event.currentTarget.value)}
			/>
			<InputGroupAddon addonType="append" className="px-2 bg-white">
				<SearchButton>
					<FontAwesomeIcon icon={faSearch} />
				</SearchButton>
			</InputGroupAddon>
		</SearchInputGroup>
	);
};

export const CustomSearchBox = connectSearchBox(SearchBox);
