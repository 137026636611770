import React from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';

import { useQueryStore } from '../../../stores';
import QueriesTable from '../../components/queries-table';
import Heading from '../../../styled-components/elements/heading';
import Divider from '../../../styled-components/elements/divider';

const QueriesIndexView = () => {
	const queryStore = useQueryStore();
	queryStore.parse();

	const render = () => (
		<Container fluid={true} className="queries-index-view">
			<Row>
				<Col xs={12}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem active>Saved Queries</BreadcrumbItem>
					</Breadcrumb>
					<Heading>Saved Queries</Heading>
					<Divider />
				</Col>
				<Col md={11}>
					<QueriesTable queries={queryStore.allQueries} />
				</Col>
			</Row>
		</Container>
	);

	return render();
};

export default QueriesIndexView;
