import React from 'react';
import { LabeledSelect } from '../../../components/labeled-select';

interface Props {
	id: string;
	label?: string;
	name?: string;
	onChange?: (event: any) => void;
	value?: string;
	options: string[];
}

const UserSelect = (props: Props) => {
	const getLabel = () => {
		if (props.label) {
			return props.label;
		}
		return 'Role';
	};

	return (
		<LabeledSelect
			id={props.id}
			label={getLabel()}
			name="role"
			value={props.value}
			onChange={props.onChange}
		>
			<option value="-1">
				Select a {props.label?.toLowerCase()} for this user
			</option>
			{props.options.map((option) => (
				<option key={option} value={option}>
					{option}
				</option>
			))}
		</LabeledSelect>
	);
};

export default UserSelect;
