import React from 'react';
import themeStore from '../../../theme/models/ThemeStore';
import { StyledLink } from './style';

const { colors } = themeStore.selectedTheme;

const SidebarLink = (props: any) => (
	<StyledLink
		{...props}
		getProps={({ isCurrent }) => {
			return {
				style: {
					color: isCurrent ? colors.darkestGrey : colors.darkGrey,
					backgroundColor: isCurrent
						? `${colors.secondaryHighlighted}80`
						: colors.white,
					borderLeft: isCurrent ? `5px solid ${colors.secondary}` : 'none',
				},
			};
		}}
	/>
);

export default SidebarLink;
