import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const FallbackIconWrapper = styled.div`
	width: 100%;
	padding-top: 1em;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 100px;
	color: ${colors.darkerGrey};
`;

export const FallbackIcon = styled(FontAwesomeIcon)`
	margin: auto;
`;

export const Message = styled.p`
	margin-top: 20px;
`;

export const Container = styled.div`
	display: inline-block;
`;

export const StyledImage = styled.img`
	background-image: url('/images/transparency-grid.png');
	background-repeat: repeat;
	border-radius: 2px;
	image-rendering: crisp-edges;
`;

export const PreviewContainer = styled.div`
	margin-top: 2em;
`;
