import React, { FormEvent } from 'react';
import { useObserver } from 'mobx-react';
import { Col, Form, Row } from 'reactstrap';

import { isIdentifiableLoaded, useEditingFlow } from '../../../../common';
import {
	buildTemplateUrl,
	buildWorkflowCollectionUrl,
} from '../../../../common/links';
import { _logError } from '../../../../common/log';
import {
	EntityPropList,
	EntityPropListItem,
} from '../../../../styled-components/blocks/entity-props';
import LabeledInput from '../../../../components/labeled-input';
import SubmitButton from '../../../../components/submit-button';
import EntityMetadataForm from '../../../../metadata/components/entity-metadata-form';

import NotificationModel, {
	NotificationType,
} from '../../../../notifications/NotificationModel';
import notificationStore from '../../../../notifications/NotificationStore';
import themeStore from '../../../../theme/models/ThemeStore';
import { SelectedWorkflow } from '../../../workflow.contexts';

import {
	CustomDatePicker,
	StyledPropLink,
	WorkflowStatusPillLg,
} from './style';
import { getCreationDateOf } from '../../../../models/CreatableEntityModel';
import StyledLabel from '../../../../styled-components/elements/label';
import {
	RemoveFromWorkflowCollectionButton,
	AddWorkflowToCollectionForm,
	UpdateWorkflowStatusForm,
} from '../../workflow-actions';

const DetailsPanelEditForm = () => {
	const workflow = SelectedWorkflow.presentValue;
	const label = themeStore._.workflow;

	const [
		propertyPatcher,
		getCurrentWorkflowSnapshot,
		setLastKnownGood,
	] = useEditingFlow(workflow);

	const updateWorkflowSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedWorkflow = await workflow.save(getCurrentWorkflowSnapshot());
			setLastKnownGood(updatedWorkflow);

			notificationStore.push(
				NotificationModel.create({
					text: `${label} saved!`,
				})
			);
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while updating ${label.toLowerCase()}. Please try again later.`,
				})
			);
		}
	};

	const renderUpdateStatusForm = () =>
		!workflow.isCompleted ? <UpdateWorkflowStatusForm /> : null;

	return useObserver(() => {
		return (
			<Row className="px-3">
				<Col md={7} xs={{ size: 12, order: 2 }}>
					<Form>
						<EntityPropList>
							<EntityPropListItem>
								<StyledLabel>{label} template used</StyledLabel>
								<div>
									<StyledPropLink to={buildTemplateUrl(workflow.templateUsed)}>
										{workflow.templateUsed.title}
									</StyledPropLink>
								</div>
							</EntityPropListItem>

							<EntityPropListItem>
								<StyledLabel>Due date</StyledLabel>
								<CustomDatePicker
									selected={workflow.dueDate}
									placeholderText="Click to select a date"
									onChange={propertyPatcher('dueDate')}
								/>
							</EntityPropListItem>

							<EntityPropListItem>
								<LabeledInput
									label={`${label} #`}
									type="text"
									value={workflow._id}
									disabled={true}
								/>
							</EntityPropListItem>

							<EntityPropListItem>
								<LabeledInput
									label="Created on"
									type="text"
									value={getCreationDateOf(workflow).toLocaleDateString()}
									disabled={true}
								/>
							</EntityPropListItem>

							<EntityPropListItem>
								<LabeledInput
									label="Created by"
									type="text"
									value={workflow.createdBy.name}
									disabled={true}
								/>
							</EntityPropListItem>

							<EntityPropListItem>
								<EntityMetadataForm
									metadata={workflow.metadata}
									displayTemplateField={false}
								/>
							</EntityPropListItem>
						</EntityPropList>

						<SubmitButton
							onClick={updateWorkflowSubmit}
							label={`Update ${label?.toLowerCase()}`}
							className="mt-5"
						/>
					</Form>
				</Col>
				<Col md={{ size: 4, offset: 1, order: 2 }} xs={{ size: 12, order: 1 }}>
					<h2>{label} status</h2>
					<hr />
					<EntityPropList className="mb-2 px-0">
						<EntityPropListItem>
							<StyledLabel className="mb-0 mr-2">Current status:</StyledLabel>
							<WorkflowStatusPillLg status={workflow.status} />
						</EntityPropListItem>

						{workflow.statusMsg ? (
							<EntityPropListItem>
								<StyledLabel>Current status message:</StyledLabel>
								<p>"{workflow.statusMsg}"</p>
							</EntityPropListItem>
						) : null}
					</EntityPropList>

					{renderUpdateStatusForm()}

					<h2 className="mt-5">{themeStore._.workflowCollection}</h2>
					<hr />

					{isIdentifiableLoaded(workflow.workflowCollection) ? (
						<EntityPropList className="mb-4">
							<EntityPropListItem>
								<StyledPropLink
									to={buildWorkflowCollectionUrl(workflow.workflowCollection)}
								>
									{workflow.workflowCollection.title}
								</StyledPropLink>
								<RemoveFromWorkflowCollectionButton workflow={workflow} />
							</EntityPropListItem>
						</EntityPropList>
					) : (
						<AddWorkflowToCollectionForm />
					)}
				</Col>
			</Row>
		);
	});
};

export default DetailsPanelEditForm;
