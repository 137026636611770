import React from 'react';
import { useObserver } from 'mobx-react';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import { getName } from '../../common';

import { DownshiftContext } from './downshift-options.context';
import { DownshiftBaseProps, SelectionContext } from './downshift.interfaces';

import { DownshiftOptionsList } from './downshift-options-list';
import StyledLabel from '../../styled-components/elements/label';

const defaultRenderInput = (downshift: ControllerStateAndHelpers<any>) => (
	<input {...downshift.getInputProps()} />
);

const DownshiftSelect = <
	Item extends Displayable,
	Option extends Displayable = Item
>(
	props: DownshiftBaseProps<Item, Option>
) => {
	const {
		renderOption = (option: Option) => getName(option),
		selectionState,
		selectionActions,

		label = 'Start typing to search...',
		renderLabel = () => <StyledLabel>{label}</StyledLabel>,
		renderInput = defaultRenderInput,

		...downshiftProps
	} = props;

	return useObserver(() => (
		<Downshift
			{...downshiftProps}
			itemToString={getName}
			stateReducer={props.stateReducer}
		>
			{(downshift) => {
				const selectionContext: SelectionContext<Item, Option> = {
					downshift: downshift,

					selection: selectionState.selection,
					options: selectionState.options,
					searchPredicate: selectionState.searchPredicate,

					select: selectionActions.select,
					unselect: selectionActions.unselect,
				};

				return (
					<div>
						<DownshiftContext.Provider value={selectionContext}>
							{renderLabel(downshift)}

							{renderInput(downshift)}

							<DownshiftOptionsList renderOption={renderOption} />
						</DownshiftContext.Provider>
					</div>
				);
			}}
		</Downshift>
	));
};

export default DownshiftSelect;
