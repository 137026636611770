import styled from 'styled-components';
import { Link as LinkBase } from '@reach/router';
import { focus } from '../../theme/ui/ui';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const Link = styled(LinkBase)`
	color: ${colors.primary};
	text-decoration: none;

	&:hover {
		color: ${colors.primaryHighlighted};
		text-decoration: none;
	}

	&:focus {
		${focus};
	}
`;

export default Link;
