import React from 'react';
import { useObserver } from 'mobx-react';
import { Link, RouteComponentProps } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import { StyledLabel } from '../../../components/labeled-input/style';
import { useAssetStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';

import { HasDamAsset } from '../../models/DamAssetModel';
import EditAssetForm from '../../components/edit-asset-form';
import LoadingAssetState from '../../components/loading-asset-state';
import Divider from '../../../styled-components/elements/divider';

const capitalizedAssetTerm = themeStore._.asset;

const DamAssetEditor = ({ asset }: HasDamAsset) => (
	<Container fluid={true}>
		<Row>
			<Col xs={12}>
				<Breadcrumb>
					<BreadcrumbItem tag={Link} to="/">
						Home
					</BreadcrumbItem>
					<BreadcrumbItem
						tag={Link}
						to="../.."
					>{`${capitalizedAssetTerm}s`}</BreadcrumbItem>
					<BreadcrumbItem tag={Link} to="..">
						{asset.title ? asset.title : asset.fileName}
					</BreadcrumbItem>
					<BreadcrumbItem active>Edit</BreadcrumbItem>
				</Breadcrumb>
				<Heading>{asset.title ? asset.title : asset.fileName}</Heading>
				<Divider />
			</Col>
			<Col md={8}>
				<EditAssetForm asset={asset} />
			</Col>
			<Col md={4}>
				{asset.previewURL ? (
					<>
						<StyledLabel>{capitalizedAssetTerm} Preview</StyledLabel>
						<img
							className="img-fluid"
							src={asset.previewURL}
							alt={asset.title}
						/>
					</>
				) : null}
			</Col>
		</Row>
	</Container>
);

const EditDAMAssetView = (props: RouteComponentProps<{ assetId: string }>) => {
	const assetStore = useAssetStore();

	const render = () => {
		const damAsset = assetStore.findOne(props.assetId);
		return damAsset ? (
			<DamAssetEditor asset={damAsset} />
		) : (
			<LoadingAssetState />
		);
	};

	return useObserver(render);
};

export default EditDAMAssetView;
