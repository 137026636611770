import React, { FormEvent } from 'react';
import { navigate } from '@reach/router';

import { useEditingFlow } from '../../../common';
import { _logError } from '../../../common/log';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import themeStore from '../../../theme/models/ThemeStore';
import { WorkflowCollection } from '../../models/WorkflowCollectionModel';
import WorkflowCollectionForm from '../workflow-collection-form';

// constants
const collectionTerm = themeStore._.workflowCollection;

//interface
interface EditWorkflowCollectionFormProps {
	collection: WorkflowCollection;
}

// component
const EditWorkflowCollectionForm = (props: EditWorkflowCollectionFormProps) => {
	const { collection } = props;

	const [, , setLastKnownGood] = useEditingFlow(collection);

	const onSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedCollection = await collection.save();
			setLastKnownGood(updatedCollection);

			notificationStore.push(
				NotificationModel.create({
					text: `${collectionTerm} saved!`,
				})
			);

			return navigate(`/admin/workflow/projects/${updatedCollection._id}`);
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while updating ${collectionTerm.toLowerCase()}. Please try again later.`,
				})
			);
		}
	};

	return (
		<WorkflowCollectionForm
			selectedCollection={collection}
			onSubmit={onSubmit}
		/>
	);
};

export default EditWorkflowCollectionForm;
