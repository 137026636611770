import styled from 'styled-components';
import Calendar from 'react-calendar';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const StyledCalendar = styled(Calendar)`
	.calendar-tile {
		position: relative;

		&:disabled {
			background-color: transparent;

			&:not(.react-calendar__month-view__days__day--neighboringMonth) {
				color: ${colors.black};
			}
		}

		&:hover {
			background-color: transparent !important;
			cursor: default !important;
		}

		&.react-calendar__tile--active {
			background-color: ${colors.grey} !important;
			color: ${colors.black};

			&:hover {
				background-color: ${colors.grey} !important;
				color: ${colors.black};
			}
		}

		&.react-calendar__month-view__days__day--weekend {
			color: ${colors.darkerGrey};
		}

		.has-workflow-marker {
			position: absolute;
			cursor: help;
			height: 100%;
			left: 0;
			top: 0;
			width: 100%;

			.marker-label {
				color: ${colors.secondary};
				font-size: 8px;
				padding-bottom: 8px;
				padding-top: 24px;
			}
		}
	}
`;
