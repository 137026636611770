import React from 'react';
import { useObserver } from 'mobx-react';
import { RouteComponentProps } from '@reach/router';

import CommonAssetVersionDisplay from '../../../dam-assets/components/common-asset-version-display';
import LoadingAssetState from '../../../dam-assets/components/loading-asset-state';
import { Workflow } from '../../models';

interface WorkflowAssetVersionDetailsProps extends RouteComponentProps {
	versionId?: string;
	workflow: Workflow;
}

const WorkflowAssetVersionDetailsView = (
	props: WorkflowAssetVersionDetailsProps
) => {
	const { versionId, workflow } = props;

	const render = () => {
		if (!versionId) {
			return <LoadingAssetState />;
		}

		const version = workflow.loadAssetVersion(versionId);

		return (
			<>
				{version ? (
					<CommonAssetVersionDisplay version={version} />
				) : (
					<LoadingAssetState />
				)}
			</>
		);
	};

	return useObserver(render);
};

export default WorkflowAssetVersionDetailsView;
