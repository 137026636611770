import React, { PropsWithChildren, useCallback } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';

import { useModalCloser } from '../../stores/ModalStack';
import DialogModal from './dialog-modal';
import { isThenable } from '../../common';

interface ConfirmationDialogProps {
	header: string;
	onConfirm: () => void | Promise<void>;
	shouldWait?: boolean;
}

// component
const ConfirmationDialog = (
	props: PropsWithChildren<ConfirmationDialogProps>
) => {
	const modalStack = useModalCloser();
	const { children, header, onConfirm, shouldWait } = props;

	const confirm = useCallback(() => {
		const result = onConfirm();
		if (shouldWait && isThenable(result)) {
			result.then(() => modalStack.closeModal());
		} else {
			modalStack.closeModal();
		}
	}, [modalStack, onConfirm, shouldWait]);

	const render = () => (
		<DialogModal header={header}>
			<ModalBody>{children}</ModalBody>
			<ModalFooter>
				<Button onClick={confirm} color="danger" size="sm">
					Yes
				</Button>
				<Button size="sm" onClick={modalStack.closeModal}>
					Cancel
				</Button>
			</ModalFooter>
		</DialogModal>
	);

	return render();
};

export default ConfirmationDialog;
