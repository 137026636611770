import React, { FormEvent, useCallback, useRef } from 'react';
import { ModalBody } from 'reactstrap';

import { _logError } from '../../../common/log';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useModalCloser } from '../../../stores/ModalStack';
import { DialogModal } from '../../../components/modals';
import { useGroupStore } from '../../../stores';
import { useEditingFlow } from '../../../common';
import EditGroupForm from './edit-group-form';

const AddGroupDialog = () => {
	const groupStore = useGroupStore();
	const modalStack = useModalCloser();

	const groupRef = useRef(groupStore.buildEmptyGroup());

	const [, getGroupSnapshot, setLastKnownGood] = useEditingFlow(
		groupRef.current
	);

	const addGroup = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();

			try {
				const updatedGroup = await groupStore.createUserGroup(
					getGroupSnapshot()
				);
				setLastKnownGood(updatedGroup);

				modalStack.closeModal();

				notificationStore.push(
					NotificationModel.create({
						text: 'User group added!',
					})
				);
			} catch (error) {
				_logError(error);
				modalStack.closeModal();

				notificationStore.push(
					NotificationModel.create({
						type: NotificationType.ERROR,
						text: `An issue occurred while adding user group. Please try again later.`,
					})
				);
			}
		},
		[getGroupSnapshot, setLastKnownGood, groupStore, modalStack]
	);

	return (
		<DialogModal header="Create group">
			<ModalBody>
				<EditGroupForm selectedGroup={groupRef.current} onSubmit={addGroup} />
			</ModalBody>
		</DialogModal>
	);
};

export default AddGroupDialog;
