import { css } from 'styled-components';

import themeStore from '../../theme/models/ThemeStore';
import { ffRegular, fsNormal } from '../ui/typography';
import { focus } from '../ui/ui';

const { colors } = themeStore.selectedTheme;

const tabButton = css`
	${ffRegular};
	${fsNormal};
	background-color: transparent;
	border: none;
	color: ${colors.primary};
	cursor: pointer;
	padding: 0.75em 1.5em;

	&.selected {
		border-bottom: 2px solid ${colors.primary};
		margin-top: 2px;
	}

	&:hover {
		color: ${colors.primaryHighlighted} !important;
		text-decoration: none;
	}

	&:focus {
		${focus};
	}
`;

export default tabButton;
