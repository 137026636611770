import React, { FormEvent } from 'react';
import { useObserver } from 'mobx-react';
import { Link, navigate } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { LoadingRow } from '../../../components/loading-indicator';
import Heading from '../../../styled-components/elements/heading';
import NotificationModel from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useTemplateStore } from '../../../stores';
import EditTemplateForm from '../../components/edit-template-form';
import themeStore from '../../../theme/models/ThemeStore';
import Divider from '../../../styled-components/elements/divider';

const TemplateEditView = ({
	workflowTemplateId,
}: {
	workflowTemplateId: string;
}) => {
	const templateStore = useTemplateStore();

	const updateTemplate = (event: FormEvent) => {
		event.preventDefault();
		templateStore.updateOne(templateStore.getOne(workflowTemplateId));

		notificationStore.push(
			NotificationModel.create({
				text: 'Template saved!',
			})
		);

		return navigate(`.`);
	};

	return useObserver(() => {
		const template = templateStore.getOne(workflowTemplateId);

		if (!template) {
			return (
				<Container fluid={true}>
					<LoadingRow />
				</Container>
			);
		}

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem
								tag={Link}
								to="../.."
							>{`${themeStore._.workflow} Templates`}</BreadcrumbItem>
							<BreadcrumbItem tag={Link} to="..">
								{template.title}
							</BreadcrumbItem>
							<BreadcrumbItem active>Edit</BreadcrumbItem>
						</Breadcrumb>
						<Heading>Edit {template.title}</Heading>
						<Divider />
					</Col>
					<Col lg={8}>
						<EditTemplateForm
							onSubmit={updateTemplate}
							selectedTemplate={template}
							buttonLabel="Save changes"
						/>
					</Col>
				</Row>
			</Container>
		);
	});
};

export default TemplateEditView;
