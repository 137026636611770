import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';
import { secondaryButton } from '../../../theme/components/secondary-button';

const { colors } = themeStore.selectedTheme;

export const StyledButton = styled.button`
	${secondaryButton};
	background-color: transparent !important;
	color: ${colors.primary} !important;
	height: 24px;
	line-height: 0;
	padding: 0;
	vertical-align: middle;
	width: 24px;

	&:hover {
		background-color: ${colors.danger} !important;
		color: ${colors.white} !important;
	}
`;
