import React from 'react';
import { useObserver } from 'mobx-react';

import Subtitle from '../../../styled-components/elements/subtitle';
import AccountsTable from './accounts-table';
import { PanelHeader } from '../users-panel/style';
import ScrollCard from '../../../styled-components/elements/scroll-card';
//import SideNavButton from '../side-nav-button.component';

const AccountsPanel = () => {
	const render = () => (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">Accounts</Subtitle>
				{/* TODO: Implement adding Accounts */}
				{/*<SideNavButton*/}
				{/*	label="Add Account"*/}
				{/*/>*/}
			</PanelHeader>
			<ScrollCard className="mt-4">
				<AccountsTable />
			</ScrollCard>
		</article>
	);

	return useObserver(render);
};

export default AccountsPanel;
