import React from 'react';
import { Table } from 'reactstrap';

import { WorkflowStage } from '../../../workflows/models';

import { Cell } from '../../../styled-components/blocks/table';
import themeStore from '../../../theme/models/ThemeStore';

import AssignmentTableRow from './assignment-table-row';
import ScrollCard from '../../../styled-components/elements/scroll-card';

// constants
const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;

// interface
interface Props {
	stages: ReadonlyArray<WorkflowStage>;
}

const renderNoAssignmentRow = () => (
	<tr>
		<Cell className="pt-4">You currently have no assignments.</Cell>
	</tr>
);

// component
const AssignmentsTable = (props: Props) => {
	const render = () => (
		<ScrollCard>
			<Table>
				<thead>
					<tr>
						<th>{`${stageTerm}`}</th>
						<th>Expected duration</th>
						<th>{`${stageTerm} Status`}</th>
						<th>{workflowTerm}</th>
					</tr>
				</thead>
				<tbody>
					{props.stages.length
						? props.stages.map((stage) => (
								<AssignmentTableRow key={stage._id} stage={stage} />
						  ))
						: renderNoAssignmentRow()}
				</tbody>
			</Table>
		</ScrollCard>
	);

	return render();
};

export default AssignmentsTable;
