import React from 'react';
import { useObserver } from 'mobx-react';

import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';
import { useProductRegistryStore } from '../../../stores';
import { ProductRegistry } from '../../models';

const productRegistryListColumns: ListColumn<ProductRegistry>[] = makeColumns([
	{
		label: 'Name',
		prop: 'name',
	},
	{
		label: 'Last Modified',
		prop: (t: ProductRegistry) => <>{t.updatedAt?.toISOString()}</>,
	},
]);

// component
const ProductsRegistryListTable = () => {
	const productStore = useProductRegistryStore();

	const render = () => {
		const products = productStore.all;
		return (
			<ListTable
				columns={productRegistryListColumns}
				rows={products.length ? products : []}
				noResultsLabel="No products yet"
			/>
		);
	};

	return useObserver(render);
};

export default ProductsRegistryListTable;
