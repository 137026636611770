import styled from 'styled-components';
import { Link as LinkBase } from '@reach/router';
import { CardBody } from 'reactstrap';

import themeStore from '../../../theme/models/ThemeStore';
import { ffBold } from '../../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

export const CollectionCardBody = styled(CardBody)`
	&&& {
		background-color: ${colors.white};
	}
`;

export const CollectionCardBodyHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-right: 10px;
	margin-bottom: 5px;
`;

export const CollectionTitle = styled.h6`
	${ffBold};
	font-size: 15px;
	margin: 0;
	overflow: hidden;
	padding-right: 1.25rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: auto;
`;

export const Link = styled(LinkBase)`
	color: black;
	text-decoration: none;

	&:hover {
		color: black;
		text-decoration: none;

		.card {
			transform: scale(1.0125);
			transition: transform 1ms ease-in-out;
		}
	}
`;
