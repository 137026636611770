import styled from 'styled-components';
import { Link } from '@reach/router';

import { secondaryButton } from '../../../theme/components/secondary-button';
import { CardContainerStyles } from '../style';

export const TemplateHeader = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;

	@media only screen and (min-width: 992px) {
		flex-direction: row;
	}
`;

export const InfoWrapper = styled.span`
	margin-left: 0.5em;
	opacity: 0.5;
	font-size: 75%;
`;

export const ButtonLink = styled(Link)`
	${secondaryButton};
	display: inline-block;
	margin-bottom: 2em;
`;

export const FinalTemplateCanvas = styled.div`
	position: relative;
	${CardContainerStyles};
`;

export const FinalTemplateKeyWrapper = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
`;
