import React from 'react';
import { useObserver } from 'mobx-react';

import { _logError } from '../../../common/log';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { Workflow, WorkflowStatus } from '../../models';
import StageDialog from '../stage-actions/stage-dialog';

interface Props {
	workflow: Workflow;
	status: WorkflowStatus;
}

const UpdateWorkflowStatusDialog = (props: Props) => {
	const { workflow, status } = props;

	const onSubmit = async (message: string) => {
		try {
			await workflow.updateStatus(status, message);
		} catch (error) {
			_logError(error);
			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text:
						'An error occurred while updating the workflow status. Please try again later.',
				})
			);
		}
	};

	return useObserver(() => (
		<StageDialog
			header={`Changing status to ${status}`}
			context="Please provide some context about the status change."
			successMessage="Status updated!"
			onClick={onSubmit}
		/>
	));
};

export default UpdateWorkflowStatusDialog;
