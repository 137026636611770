import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, Router } from '@reach/router';
import {
	Breadcrumb,
	BreadcrumbItem,
	Col,
	Container,
	Nav,
	Row,
} from 'reactstrap';

import { LoadingRow } from '../../../components/loading-indicator';
import OwnerAvatarList from '../../../components/owner-avatar-list';
import WorkflowHealthFlag from '../../../dashboard/components/workflow-health-flag';
import { useWorkflowStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import AccessLogsPanel from '../../components/workflow-details-tab-set/access-logs-panel';
import AssetsPanel from '../../components/workflow-details-tab-set/assets-tab-panel';
import WorkflowDetailPanel from '../../components/workflow-details-tab-set/details-panel';
import StageIndexPanel from '../../components/workflow-stage-details/workflow-stage-index-panel';
import WorkflowAssetVersionDetailsView from '../workflow-asset-version-details';
import { SelectedWorkflow } from '../../workflow.contexts';
import Heading from '../../../styled-components/elements/heading';
import { Link } from './style';
import WorkflowProductRegistryPanel from '../../components/workflow-details-tab-set/product-registry-panel';
import ProductImportView from '../../../products/views/products-import';

const Assets = ({ children }: { children: ReactElement[] }) => <>{children}</>;
const ProductRegistry = ({ children }: { children: ReactElement[] }) => (
	<>{children}</>
);

interface WorkflowDetailsViewProps extends RouteComponentProps {
	workflowId: string;
}

const WorkflowDetailsView = observer((props: WorkflowDetailsViewProps) => {
	const workflowStore = useWorkflowStore();
	const workflowTerm = themeStore._.workflow;

	const workflow = workflowStore.findOne(props.workflowId);

	const isSelected = (link: string) => {
		return window.location.pathname.includes(link) ? 'selected' : '';
	};

	const workflowDetail = workflow ? (
		<Row>
			<Col xs={12}>
				<Breadcrumb>
					<BreadcrumbItem tag={Link} to="/">
						Home
					</BreadcrumbItem>
					<BreadcrumbItem
						tag={Link}
						to=".."
					>{`${workflowTerm}s`}</BreadcrumbItem>
					<BreadcrumbItem active>{workflow.title}</BreadcrumbItem>
				</Breadcrumb>
			</Col>
			<Col xs={12} className="mb-4">
				<Row>
					<Col md={11}>
						<div className="d-flex align-items-center">
							<Heading className="mr-2 d-inline mb-0 pb-0">
								{workflow.title}
							</Heading>
							<WorkflowHealthFlag workflow={workflow} />
						</div>
					</Col>
					<Col md={1} className="text-md-right">
						<OwnerAvatarList owners={workflow.involvedOwners} />
					</Col>
				</Row>
			</Col>
			<Col xs={12}>
				<Nav tabs>
					<Link to="stages" className={isSelected('stages')}>
						{themeStore._.stage}s
					</Link>
					<Link to="details" className={isSelected('details')}>
						{workflowTerm} Details
					</Link>
					<Link to="products" className={isSelected('products')}>
						Product Registry
					</Link>
					{workflow.inputSlots && workflow.inputSlots.length ? (
						<Link to="assets" className={isSelected('assets')}>
							{themeStore._.asset}s
						</Link>
					) : null}
					<Link to="logs" className={isSelected('logs')}>
						Access Logs
					</Link>
				</Nav>
			</Col>
			<Col xs={12} className="mt-4">
				<SelectedWorkflow.Provider
					value={SelectedWorkflow.asReadonly(workflow)}
				>
					<Router primary={false}>
						<StageIndexPanel path="stages" default />
						<WorkflowDetailPanel path="details" />
						<ProductRegistry path="products">
							<WorkflowProductRegistryPanel path="/" workflow={workflow} />
							<ProductImportView path="import" workflow={workflow} />
						</ProductRegistry>
						<Assets path="assets">
							<AssetsPanel path="/" />
							<WorkflowAssetVersionDetailsView
								path=":versionId"
								workflow={workflow}
							/>
						</Assets>
						<AccessLogsPanel path="logs" />
					</Router>
				</SelectedWorkflow.Provider>
			</Col>
		</Row>
	) : (
		<LoadingRow label={`Loading ${workflowTerm.toLowerCase()}...`} />
	);

	return <Container fluid={true}>{workflowDetail}</Container>;
});

export default WorkflowDetailsView;
