import styled from 'styled-components';

export const List = styled.ul`
	padding-left: 0;
	list-style: none;
`;

export const ListItem = styled.li`
	margin-bottom: 16px;
`;
