import React from 'react';
import { useObserver } from 'mobx-react';

import { Ownable } from '../../models/BaseWorkflowOwnableModel';
import {
	BaseWorkflowOwner,
	OwnerType,
} from '../../models/BaseWorkflowOwnerModel';
import { OwnerAvatarWrapper } from '../../workflow-templates/components/template-stage-card/style';
import GroupAvatar from '../group-avatar';
import {
	CollaboratorAvatarLg,
	CollaboratorAvatarMd,
	CollaboratorAvatarSm,
	CollaboratorAvatarXs,
} from '../../styled-components/elements/avatar';

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg';

interface OwnerAvatarListProps
	extends Ownable,
		React.HTMLAttributes<HTMLDivElement> {
	size?: AvatarSize;
}

interface OwnerAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
	size?: AvatarSize;
	owner: BaseWorkflowOwner;
}

const avatarsBySize = {
	xs: CollaboratorAvatarXs,
	sm: CollaboratorAvatarSm,
	md: CollaboratorAvatarMd,
	lg: CollaboratorAvatarLg,
} as const;

export const OwnerAvatar = (props: OwnerAvatarProps) => {
	const { owner, size = 'md', ...avatarProps } = props;

	return useObserver(() => {
		const Avatar = avatarsBySize[size];

		if (owner.type === OwnerType.AccountUser) {
			const { user } = owner;
			return <Avatar image={user.avatar} {...avatarProps} />;
		} else if (owner.type === OwnerType.AccountGroup) {
			const { group } = owner;
			return <GroupAvatar group={group} />;
		}
		return null;
	});
};

const OwnerAvatarList = (props: OwnerAvatarListProps) => {
	const { owners, size = 'md' } = props;

	return useObserver(() => (
		<OwnerAvatarWrapper>
			{owners.map((owner: BaseWorkflowOwner) => (
				<OwnerAvatar key={owner._id} owner={owner} size={size} />
			))}
		</OwnerAvatarWrapper>
	));
};

export default OwnerAvatarList;
