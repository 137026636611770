import React, { ReactElement } from 'react';
import { Workflow } from '../../../workflows/models';
import { PaddedBadge } from './styled';

const WorkflowHealthFlag = (props: {
	workflow: Workflow;
	whenInactive?: ReactElement;
}) => {
	const { workflow, whenInactive = null } = props;

	if (!workflow.isActive) {
		return whenInactive;
	} else if (workflow.isOverdue) {
		return <PaddedBadge color="warning">Overdue</PaddedBadge>;
	} else if (workflow.isRoadblocked) {
		return <PaddedBadge color="danger">Roadblocked</PaddedBadge>;
	} else {
		return <PaddedBadge color="success">Healthy</PaddedBadge>;
	}
};

export default WorkflowHealthFlag;
