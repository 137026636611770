import React from 'react';
import { useObserver } from 'mobx-react';
import { Col } from 'reactstrap';

import OwnerAvatarList from '../../../components/owner-avatar-list';
import { SmallTextMuted } from '../../../styled-components/elements/small';
import themeStore from '../../../theme/models/ThemeStore';

import { WorkflowCollection } from '../../models/WorkflowCollectionModel';

import {
	CollectionCardBody,
	CollectionCardBodyHeader,
	CollectionTitle,
	Link,
} from './style';
import ScrollCard from '../../../styled-components/elements/scroll-card';

// interface
interface CollectionCardProps {
	collection: WorkflowCollection;
}

// component
const WorkflowCollectionCard = ({ collection }: CollectionCardProps) => {
	const workflowCount = collection.workflows.length;

	return useObserver(() => (
		<Col lg={4} sm={6} className="mt-2 mb-3">
			<Link to={`./${collection._id}`}>
				<ScrollCard>
					<CollectionCardBody>
						<CollectionCardBodyHeader>
							<CollectionTitle>{collection.title}</CollectionTitle>
							<OwnerAvatarList owners={collection.owners} />
						</CollectionCardBodyHeader>
						<SmallTextMuted>
							{workflowCount} {themeStore._.workflow.toLowerCase()}
							{workflowCount === 1 ? '' : 's'}
						</SmallTextMuted>
					</CollectionCardBody>
				</ScrollCard>
			</Link>
		</Col>
	));
};

export default WorkflowCollectionCard;
