import styled from 'styled-components';
import { heading1 } from '../../theme/ui/ui';

const Heading = styled.h1`
	${heading1};
	padding-bottom: 0.75em;
	word-break: break-word;
`;

export default Heading;
