import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const Divider = styled.hr`
	border: 0.5px solid ${colors.grey};
	margin: 0 0 2em;
`;

export default Divider;
