import React from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import { useWorkflowStore } from '../../../stores';
import RoadblocksTable from '../../components/roadblocks-table';
import Divider from '../../../styled-components/elements/divider';

const RoadblocksIndexView = () => {
	const workflowStore = useWorkflowStore();

	const render = () => {
		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem active>Roadblocks</BreadcrumbItem>
						</Breadcrumb>
						<Heading>Roadblocks</Heading>
						<Divider />
					</Col>
					<Col md={11}>
						<RoadblocksTable roadblocks={workflowStore.activeRoadblocks} />
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default RoadblocksIndexView;
