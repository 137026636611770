import styled from 'styled-components';

const EntityPropListItem = styled.li`
	margin-top: 15px;

	&:first-of-type {
		margin-top: 0;
	}
`;

export default EntityPropListItem;
