import React, { FormEvent, useCallback, useRef } from 'react';
import { applyPatch, getSnapshot } from 'mobx-state-tree';
import { navigate } from '@reach/router';

import { useStores, useWorkflowCollectionStore } from '../../../stores';
import { emptyCollection } from '../../models/WorkflowCollectionStore';
import WorkflowCollectionForm from '../workflow-collection-form';
import { Workflow } from '../../../workflows/models';

const AddWorkflowCollectionForm = () => {
	const collectionStore = useWorkflowCollectionStore();

	const collectionRef = useRef(emptyCollection(useStores()));

	const addCollection = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdCollection = await collectionStore.createOne(
				getSnapshot(collectionRef.current)
			);

			createdCollection.workflows.forEach((wf: Workflow) =>
				applyPatch(wf, {
					op: 'replace',
					path: '/workflowCollection',
					value: createdCollection._id,
				})
			);

			return navigate(`/admin/workflow/projects/${createdCollection._id}`);
		},
		[collectionStore]
	);

	return (
		<WorkflowCollectionForm
			selectedCollection={collectionRef.current}
			onSubmit={addCollection}
		/>
	);
};
export default AddWorkflowCollectionForm;
