import React, { ChangeEvent } from 'react';
import { InputGroupAddon } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
	SearchButton,
	SearchInput,
	SearchInputGroup,
} from '../../styled-components/blocks/search-bar';

interface SearchBarProps {
	placeholder: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar = (props: SearchBarProps) => (
	<SearchInputGroup>
		<SearchInput
			type="search"
			name="search"
			placeholder={props.placeholder}
			onChange={props.onChange}
		/>
		<InputGroupAddon addonType="append" className="px-2 bg-white">
			<SearchButton>
				<FontAwesomeIcon icon={faSearch} />
			</SearchButton>
		</InputGroupAddon>
	</SearchInputGroup>
);

export default SearchBar;
