import React, { RefObject, useEffect, useRef, useState } from 'react';

import { _logError } from '../../../common/log';
import { asPercent } from '../../../common';

import { AssetVersion } from '../../models/AssetVersionModel';
import { drawVersionDiff } from '../../comparison.utils';
import Subheading from '../../../styled-components/elements/subheading';
import { Preview } from './style';

interface PreviewDiffProps {
	versionA: AssetVersion;
	versionB: AssetVersion;
}

const refContext = (ref: RefObject<HTMLCanvasElement>) =>
	ref.current?.getContext('2d');

export const AssetPreviewDiff = (props: PreviewDiffProps) => {
	const { versionA, versionB } = props;

	const aCanvas = useRef<HTMLCanvasElement>(null);
	const bCanvas = useRef<HTMLCanvasElement>(null);
	const diffCanvas = useRef<HTMLCanvasElement>(null);

	const [similarity, setSimilarity] = useState(asPercent(1));

	useEffect(() => {
		const aContext = refContext(aCanvas);
		const bContext = refContext(bCanvas);
		const diffContext = refContext(diffCanvas);

		if (aContext && bContext && diffContext) {
			drawVersionDiff(
				{ versionA, versionB },
				{ a: aContext, b: bContext, diff: diffContext }
			)
				.then(({ similarity: actual }) => {
					setSimilarity(asPercent(actual));
				})
				.catch(_logError);
		}
	}, [versionA, versionB]);

	return (
		<div className="d-block">
			<Preview>
				<Subheading>Source</Subheading>
				<canvas ref={aCanvas} />
			</Preview>
			<Preview>
				<Subheading>Difference</Subheading>
				<canvas ref={diffCanvas} />
				<p>Similarity: {similarity}</p>
			</Preview>
			<Preview>
				<Subheading>Other</Subheading>
				<canvas ref={bCanvas} />
			</Preview>
		</div>
	);
};
