import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

interface GroupAvatarCircleProps {
	background: string;
}

const GroupAvatarCircle = styled.div<GroupAvatarCircleProps>`
	display: inline-flex;
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	background-color: ${(props) => props.background};
	color: white;
	align-items: center;
	justify-content: center;
	border: 1px solid ${colors.white};
	margin-right: -0.5em;
`;

export const GroupAvatarCircleLg = styled(GroupAvatarCircle)`
	width: 2.5em;
	height: 2.5em;
	margin-right: 0;
`;

export default GroupAvatarCircle;
