import React, { useCallback } from 'react';
import Downshift from 'downshift';

import {
	DownshiftBaseProps,
	DownshiftReducer,
	ItemRenderer,
	MultiSelectionState,
} from './downshift.interfaces';

import DownshiftSelect from './downshift-select';
import { DownshiftTagInputField } from './downshift-tag-input';

export interface DownshiftMultiSelectProps<
	Item extends Displayable,
	Option extends Displayable = Item
> extends DownshiftBaseProps<Item, Option> {
	selectionState: MultiSelectionState<Item, Option>;
	renderItem?: ItemRenderer<Item>;
	isRemovable?: (x: Item) => boolean;
}

const DownshiftMultiSelect = <
	Item extends Displayable,
	Option extends Displayable = Item
>(
	props: DownshiftMultiSelectProps<Item, Option>
) => {
	const {
		selectionActions: { select },
		renderInput = () => <DownshiftTagInputField {...props} />,
	} = props;

	const stateReducer: DownshiftReducer<Option> = useCallback(
		(state, changes) => {
			switch (changes.type) {
				case Downshift.stateChangeTypes.keyDownEnter:
				case Downshift.stateChangeTypes.clickItem:
					if (changes.selectedItem) {
						select(changes.selectedItem);
					}

					return {
						...changes,
						highlightedIndex: state.highlightedIndex,
						isOpen: true,
						inputValue: '',
					};

				case Downshift.stateChangeTypes.mouseUp:
					if (!changes.isOpen) {
						// Clear input when clicking away.
						// Default behavior is to bring back the last value.
						return { ...changes, inputValue: '' };
					} else {
						return changes;
					}

				default:
					return changes;
			}
		},
		[select]
	);

	return (
		<DownshiftSelect
			{...props}
			renderInput={renderInput}
			stateReducer={stateReducer}
		/>
	);
};

export default DownshiftMultiSelect;
