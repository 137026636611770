import { CardBody } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const Avatar = styled.img`
	border-radius: 50%;
	height: 2.5em;
	margin-right: 1em;
	width: 2.5em;
`;

export const CommentItem = styled.div`
	display: flex;
	border-bottom: 1px solid ${colors.lightGrey};
	padding: 1.18em 1em;
`;

export const RoadblockCardBody = styled(CardBody)`
	padding: 0 !important;
`;
