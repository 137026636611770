import React from 'react';
import { Container } from 'reactstrap';

import { LoadingRow } from '../../../components/loading-indicator';
import themeStore from '../../../theme/models/ThemeStore';

const LoadingAssetState = () => (
	<Container fluid={true}>
		<LoadingRow label={`Loading ${themeStore._.asset.toLowerCase()}...`} />
	</Container>
);

export default LoadingAssetState;
