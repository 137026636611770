import React, { FormEvent, useState } from 'react';
import _ from 'lodash';
import { Form } from 'reactstrap';

import { _logError } from '../../../common/log';
import { isValidEmail } from '../../../common';
import { UncontrolledLabeledInput } from '../../../components/labeled-input';
import SubmitButton from '../../../components/submit-button';
import { useModalCloser } from '../../../stores/ModalStack';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import { useAssetStore } from '../../../stores';

import { ShareDialogComponentProps } from './index';

const EmailForm = (props: ShareDialogComponentProps) => {
	const { link } = props;

	const assetStore = useAssetStore();
	const modalStack = useModalCloser();

	const [errorMessage, setErrorMessage] = useState('');

	const sendEmail = async (event: FormEvent) => {
		event.preventDefault();

		// validation
		const form = _.get(event.target, 'form');
		const emailInput = _.get(form, 'email');

		if (!isValidEmail(emailInput.value)) {
			setErrorMessage('Please enter a valid email.');
			return;
		}

		// clear error message
		setErrorMessage('');

		// call api
		try {
			await assetStore.share(link, emailInput.value);

			if (props.onSuccess) {
				props.onSuccess();
			}
		} catch (error) {
			_logError(error);

			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred, unable to send an email at this time. Please try again later.`,
				})
			);

			modalStack.closeModal();
		}

		return false;
	};

	const render = () => (
		<Form>
			<UncontrolledLabeledInput
				label="Email"
				placeholder="Enter email to which link will be sent"
				type="email"
				name="email"
				autoComplete="on"
				errorMsg={errorMessage}
			/>
			<SubmitButton onClick={sendEmail} label="Send email" />
		</Form>
	);

	return render();
};

export default EmailForm;
