import styled from 'styled-components';
import { Link } from '@reach/router';
import { ffRegular, fsSmol } from '../../theme/ui/typography';
import { secondaryButton } from '../../theme/components/secondary-button';

export const Heading = styled.h1`
	${ffRegular};
	${fsSmol};
	letter-spacing: 2px;
	text-transform: uppercase;
`;

export const Subheading = styled.p`
	${ffRegular};
	font-size: 20px;
	font-weight: 500;
	line-height: 1.2;
	margin-bottom: 0.5em;
	margin-top: 0;
	text-align: center;
`;

export const GoHomeButton = styled(Link)`
	${secondaryButton};
`;
