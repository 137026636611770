import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import ProductListTable from '../../../../products/components/product-list-table';
import { ImportProductsButton } from '../../../../products/views/products-index/style';
import { useAPIClient } from '../../../../stores';
import { Workflow } from '../../../models';

// TODO: move this to mobx state and make it properly typed
const useWorkflowProducts = (workflowId: string) => {
	const [products, setProducts] = useState<any[]>([]);
	const client = useAPIClient();

	useEffect(() => {
		client
			.get(`workflows/${workflowId}/products`)
			.then((res) => {
				setProducts(res);
			})
			.catch(() => {});
	}, [client, workflowId]);

	return products;
};

interface WorkflowProductRegistryPanelProps {
	workflow: Workflow;
}

const WorkflowProductRegistryPanel = observer(
	({ workflow }: WorkflowProductRegistryPanelProps) => {
		const products = useWorkflowProducts(workflow._id);
		return (
			<>
				<ImportProductsButton to="./import">
					<FontAwesomeIcon icon={faUpload} className="mr-2" />
					Import products
				</ImportProductsButton>
				<ProductListTable products={products} />
			</>
		);
	}
);

export default WorkflowProductRegistryPanel;
