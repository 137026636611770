import { useObserver } from 'mobx-react';
import React, { useCallback } from 'react';
import Moment from 'react-moment';
import { Container } from 'reactstrap';
import { faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../styled-components/blocks/entity-props';
import { isIdentifiableLoaded } from '../../../common';
import NotificationModel, {
	NotificationType,
} from '../../../notifications/NotificationModel';
import notificationStore from '../../../notifications/NotificationStore';
import themeStore from '../../../theme/models/ThemeStore';

import { DamAsset } from '../../models/DamAssetModel';

import { AssetTabsProps } from './index';
import { FullWidthButton, FullWidthLink } from './style';
import AssetMetadataProps from './asset-metadata-props';
import AssetWorkflowProps from './asset-workflow-props';

const assetTerm = themeStore._.asset.toLowerCase();

const InfoPanel = ({ asset, damAsset }: AssetTabsProps) => {
	const downloadAsset = useCallback(() => {
		asset.downloadAssetFile().catch(() => {
			notificationStore.push(
				NotificationModel.create({
					type: NotificationType.ERROR,
					text: `An issue occurred while downloading the ${assetTerm}. Please try again later.`,
				})
			);
		});
	}, [asset]);

	const renderEditLink = (damAsset?: DamAsset) => {
		if (damAsset) {
			return (
				<FullWidthLink
					className="mt-5"
					to={`/admin/dam/assets/${damAsset._id}/edit`}
				>
					<FontAwesomeIcon icon={faEdit} className="mr-2" />
					Edit asset
				</FullWidthLink>
			);
		} else {
			console.warn('No DAM asset provided.');
			return null;
		}
	};

	const renderComponent = () => (
		<>
			<EntityPropList>
				<EntityPropListItem>
					<EntityPropLabel>File name</EntityPropLabel>
					<p>{asset.fileName}</p>
				</EntityPropListItem>

				{asset.title ? (
					<EntityPropListItem>
						<EntityPropLabel>Display name</EntityPropLabel>
						<p>{asset.title}</p>
					</EntityPropListItem>
				) : null}

				{asset.createdBy ? (
					<EntityPropListItem>
						<EntityPropLabel>Added by</EntityPropLabel>
						<p>{asset.createdBy?.name}</p>
					</EntityPropListItem>
				) : null}

				<EntityPropListItem>
					<EntityPropLabel>Added on</EntityPropLabel>
					<Moment format="MM/DD/YYYY" date={asset.createdAt} />
				</EntityPropListItem>

				<AssetWorkflowProps asset={asset} />

				<EntityPropListItem>
					<EntityPropLabel>File size</EntityPropLabel>
					<p>{asset.size}</p>
				</EntityPropListItem>

				<EntityPropListItem>
					<EntityPropLabel>File type</EntityPropLabel>
					<p>{asset.type}</p>
				</EntityPropListItem>

				{/* TODO: display privacy property (RRR-138) */}
				{/*<EntityPropListItem>*/}
				{/*	<EntityPropLabel>Privacy setting</EntityPropLabel>*/}
				{/*	<p>{ props.asset.privacy ?? ' Default' }</p>*/}
				{/*</EntityPropListItem>*/}

				<AssetMetadataProps asset={asset} />
			</EntityPropList>

			{renderEditLink(damAsset)}

			<FullWidthButton className="mt-3" onClick={downloadAsset}>
				<FontAwesomeIcon icon={faDownload} className="mr-2" />
				Download {assetTerm}
			</FullWidthButton>
		</>
	);

	const render = () => (
		<Container fluid={true} className="p-md-4 py-3">
			{isIdentifiableLoaded(asset) ? renderComponent() : <p>Loading...</p>}
		</Container>
	);

	return useObserver(render);
};

export default InfoPanel;
