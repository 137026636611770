import React, { useCallback } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Heading from '../../../styled-components/elements/heading';
import { EntityPropLabel } from '../../../styled-components/blocks/entity-props';
import { useModalCreator } from '../../../stores/ModalStack';
import themeStore from '../../../theme/models/ThemeStore';
import { AssetVersion } from '../../models/AssetVersionModel';
import { DamAsset } from '../../models/DamAssetModel';

import { ShareAssetButton } from './style';
import AssetDetailsTabSet, { DetailsTabs } from '../asset-details-tab-set';
import AssetPreview from '../asset-preview';
import RelatedAssetsRow from '../related-assets-row';
import { ShareSingleAssetDialog } from '../share-dialogs';
import Divider from '../../../styled-components/elements/divider';

const assetTerm = themeStore._.asset;

interface CommonAssetVersionDisplayProps {
	version: AssetVersion;
	damAsset?: DamAsset;
}

const CommonAssetVersionDisplay = (props: CommonAssetVersionDisplayProps) => {
	const { version, damAsset } = props;

	const fileSource = damAsset || version;

	const modalStack = useModalCreator();

	const showShareModal = useCallback(() => {
		modalStack.addModal(<ShareSingleAssetDialog asset={fileSource} />);
	}, [fileSource, modalStack]);

	return (
		<Container fluid={true}>
			<Row>
				<Col xs={12}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem tag={Link} to="/admin/dam/assets">
							{assetTerm}s
						</BreadcrumbItem>
						<BreadcrumbItem active>
							{version.title || version.fileName}
						</BreadcrumbItem>
					</Breadcrumb>
					<Heading>{version.title || version.fileName}</Heading>
					<Divider />
				</Col>
				<Col md={8}>
					<div className="d-flex justify-content-between">
						<EntityPropLabel className="mr-4">
							ID: {version._id}
						</EntityPropLabel>

						<ShareAssetButton onClick={showShareModal}>
							<FontAwesomeIcon icon={faShare} className="mr-2" />
							Share {assetTerm.toLowerCase()}
						</ShareAssetButton>
					</div>

					<AssetPreview asset={fileSource} />

					<RelatedAssetsRow asset={damAsset} />
				</Col>
				<Col md={4}>
					<AssetDetailsTabSet
						asset={version}
						damAsset={damAsset}
						tabs={DetailsTabs}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default CommonAssetVersionDisplay;
