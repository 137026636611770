import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const ViewButtonWrapper = styled.div`
	display: inline-flex;
`;

export const ViewButton = styled.button`
	border: none;
	background: transparent;
	margin-left: 6px;
	border-radius: 3px;
	color: ${colors.darkGrey};

	&.active {
		color: ${colors.darkestGrey};
	}

	&:hover {
		color: ${colors.darkestGrey};
	}
`;
