import React, { useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';

import ProductDocumentSelect from '../../components/products-document-select/index';
import ProductTemplateSelect from '../../components/products-template-select';
import ProductMapFields from '../../components/products-map-fields';
import Heading from '../../../styled-components/elements/heading';
import Divider from '../../../styled-components/elements/divider';
import ProductSubmitButton from '../../components/products-submit-button';
import { ProductTemplate } from '../../models/ProductTemplateInterface';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import { Workflow } from '../../../workflows/models';

interface Props {
	workflow?: Workflow;
}

const ProductImportView = ({ workflow }: Props) => {
	const [productProgress, setProductProgress] = useState({
		documentUploaded: false,
		templateSelected: false,
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [sheetHeaders, setSheetHeaders] = useState<string[]>([]);
	const [templateSelected, setTemplateSelected] = useState<ProductTemplate>();
	const [submissionData, setSubmissionData] = useState<ProductSubmissionData>();

	return (
		<Container fluid={true}>
			<Row>
				{!workflow && (
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem tag={Link} to="..">
								Product Registry
							</BreadcrumbItem>
							<BreadcrumbItem active>Import</BreadcrumbItem>
						</Breadcrumb>
						<Heading>Import</Heading>
						<Divider />
					</Col>
				)}
				<Col md={9}>
					<div className="mb-5 mt-4">
						<ProductDocumentSelect
							setProductProgress={setProductProgress}
							setSheetHeaders={setSheetHeaders}
							setSubmissionData={setSubmissionData}
							setTemplateSelected={setTemplateSelected}
						/>
					</div>
					{productProgress.documentUploaded && (
						<div className="mb-5">
							<ProductTemplateSelect
								templateSelected={templateSelected}
								setTemplateSelected={setTemplateSelected}
								setProductProgress={setProductProgress}
								setSubmissionData={setSubmissionData}
							/>
						</div>
					)}
					{productProgress.documentUploaded &&
						productProgress.templateSelected && (
							<div className="mb-5">
								<ProductMapFields
									sheetHeaders={sheetHeaders}
									templateSelected={templateSelected}
									setSubmissionData={setSubmissionData}
									submissionData={submissionData}
								/>
								<ProductSubmitButton
									submissionData={submissionData}
									workflow={workflow}
								/>
							</div>
						)}
				</Col>
			</Row>
		</Container>
	);
};

export default ProductImportView;
