import React from 'react';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WorkflowParallelStageModel } from '../../../models';
import { SelectedWorkflowStage } from '../../../workflow.contexts';
import { Hr } from '../workflow-stage-card/style';

const StageInstructions = () => {
	const stage = SelectedWorkflowStage.presentValue;

	if (!WorkflowParallelStageModel.is(stage) && stage.instructions) {
		return (
			<>
				<p className="py-2">
					<FontAwesomeIcon icon={faAlignLeft} className="mr-4" />
					{stage.instructions}
				</p>
				<Hr />
			</>
		);
	} else return null;
};

export default StageInstructions;
