import React from 'react';
import { useObserver } from 'mobx-react';
import { resolveIdentifier } from 'mobx-state-tree';
import { Col, Container, Row } from 'reactstrap';
import Moment from 'react-moment';

import Heading from '../../../styled-components/elements/heading';
import { EntityPropLabel } from '../../../styled-components/blocks/entity-props';
import { isIdentifiableLoaded } from '../../../common';
import { useAssetStore, useWorkflowStore } from '../../../stores';

import {
	AssetVersion,
	AssetVersionModel,
} from '../../models/AssetVersionModel';
import { DamAsset } from '../../models/DamAssetModel';
import AssetDetailsTabSet, {
	VersionTabs,
} from '../../components/asset-details-tab-set';
import AssetPreview from '../../components/asset-preview';
import LoadingAssetState from '../../components/loading-asset-state';
import Divider from '../../../styled-components/elements/divider';

// interface
interface Props {
	workflowId: string;
	versionId: string;
}

// component
const VersionDetailsView = (props: Props) => {
	const workflowStore = useWorkflowStore();
	const assetStore = useAssetStore();

	// render methods
	const renderView = (version: AssetVersion, damAsset?: DamAsset) => (
		<Container fluid={true}>
			<Row>
				<Col xs={12}>
					<Heading>{version.fileName}</Heading>
					<Divider />
				</Col>
				<Col md={8}>
					<Row className="justify-content-between">
						<Col>
							<EntityPropLabel>ID: {version._id}</EntityPropLabel>
						</Col>
						<Col className="text-right">
							<EntityPropLabel>
								<Moment format="MM/DD/YYYY, h:MM A" date={version.createdAt} />{' '}
								version
							</EntityPropLabel>
						</Col>
					</Row>
					<AssetPreview asset={version} />
				</Col>
				<Col md={4}>
					<AssetDetailsTabSet
						asset={version}
						damAsset={damAsset}
						tabs={VersionTabs}
					/>
				</Col>
			</Row>
		</Container>
	);

	const render = () => {
		let version: Maybe<AssetVersion>;
		const workflow = workflowStore.getOne(props.workflowId);

		if (isIdentifiableLoaded(workflow)) {
			version = resolveIdentifier(AssetVersionModel, workflow, props.versionId);
		}

		if (isIdentifiableLoaded(version)) {
			const damAsset = assetStore.getDAMAssetWithAssetVersion(version);
			return renderView(version, damAsset);
		} else {
			return <LoadingAssetState />;
		}
	};

	return useObserver(render);
};

export default VersionDetailsView;
