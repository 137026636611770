import React from 'react';
import { useObserver } from 'mobx-react';

import DeleteEntityButton from '../../../components/delete-entity-button';
import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';
import OwnerList from '../../../components/owner-list';

import { CellLink } from '../../../styled-components/blocks/table';
import { useAssetCollectionStore, usePermissions } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';

import { AssetCollection } from '../../models/AssetCollectionModel';
import ScrollCard from '../../../styled-components/elements/scroll-card';

// constants
const collectionTerm = themeStore._.assetCollection;

// interface
interface Props {
	collections: readonly AssetCollection[];
	noResultsLabel?: string;
}

// component
const AssetCollectionListTable = (props: Props) => {
	const {
		collections,
		noResultsLabel = `Loading ${collectionTerm.toLowerCase()}s ...`,
	} = props;

	const permissions = usePermissions();
	const collectionStore = useAssetCollectionStore();

	const collectionListColumns: ListColumn<AssetCollection>[] = makeColumns([
		{
			label: 'Name',
			prop: (c: AssetCollection) => (
				<CellLink to={`./${c._id}`}>{c.title}</CellLink>
			),
		},
		{
			label: `${themeStore._.owner}(s)`,
			prop: (c: AssetCollection) => <OwnerList entity={c} />,
		},
		{
			label: `${themeStore._.asset} count`,
			prop: (c: AssetCollection) => <p>{c.assets.length}</p>,
		},
		{
			label: '',
			prop: (c: AssetCollection) =>
				c.assets.length === 0 && permissions.canEditAssetCollection(c) ? (
					<DeleteEntityButton
						entity={c}
						deleteOne={collectionStore.deleteOne}
						entityName={collectionTerm}
					/>
				) : null,
		},
	]);

	const render = () => (
		<ScrollCard className="mt-2">
			<ListTable
				columns={collectionListColumns}
				rows={collections}
				noResultsLabel={noResultsLabel}
			/>
		</ScrollCard>
	);

	return useObserver(render);
};

export default AssetCollectionListTable;
