import themeStore from '../../../theme/models/ThemeStore';
import styled from 'styled-components';
import { fsNormal } from '../../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

const Cell = styled.td`
	${fsNormal};
	color: ${colors.darkestGrey};
`;

export default Cell;
