import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InfoWrapper } from './style';

export const InfoButton = () => (
	<InfoWrapper>
		<FontAwesomeIcon icon={faInfoCircle} />
	</InfoWrapper>
);

export default InfoButton;
