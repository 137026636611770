import React from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { ImportProductsButton } from './style';
import ProductListTable from '../../components/product-list-table';
import Divider from '../../../styled-components/elements/divider';

const ProductsIndexView = () => {
	const render = () => {
		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem active>Product Registry</BreadcrumbItem>
						</Breadcrumb>
						<Heading>Product Registry</Heading>
						<Divider />

						<ImportProductsButton to="./import">
							<FontAwesomeIcon icon={faUpload} className="mr-2" />
							Import products
						</ImportProductsButton>
					</Col>
					<Col md={11} className="mt-4">
						<ProductListTable />
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default ProductsIndexView;
