import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { ControllerStateAndHelpers, GetInputPropsOptions } from 'downshift';
import { getName, noop } from '../../common';

import { useDownshift } from './downshift-options.context';
import { DownshiftSingleSelectProps } from './downshift-single-select';

import { BorderlessInput, TagInputField } from '../tag-input/style';
import {
	ClearSelectionButton,
	DownshiftToggleButton,
} from './downshift-buttons';

interface DownshiftSingleInputProps<
	Item extends Displayable,
	Option extends Displayable
> extends DownshiftSingleSelectProps<Item, Option> {
	getInputOptions?: (
		downshift: ControllerStateAndHelpers<Option>
	) => Maybe<GetInputPropsOptions>;
}

export const DownshiftSingleInputField = <
	Item extends Displayable,
	Option extends Displayable
>(
	props: DownshiftSingleInputProps<Item, Option>
) => {
	const {
		label,
		placeholder = `Select ${label?.toLowerCase()} by name...`,
		getInputOptions = noop,
	} = props;

	const { downshift, selection } = useDownshift<Item, Option>();

	// Make sure the input is reset to empty when we are changing selection contexts.
	// Todo find better way to achieve this.
	useEffect(
		() => {
			downshift.reset({ inputValue: '' });
		},
		// Reset when the selection changes. Do NOT make downshift a dependency
		// of this effect else an infinite loop occurs.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selection]
	);

	return useObserver(() => {
		const inputProps = downshift.getInputProps(getInputOptions(downshift));
		if (selection) {
			inputProps.value = getName(selection as Item);
		}
		return (
			<TagInputField>
				<BorderlessInput
					{...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
					placeholder={placeholder}
				/>

				{selection ? <ClearSelectionButton /> : <DownshiftToggleButton />}
			</TagInputField>
		);
	});
};
