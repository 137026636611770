import React from 'react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import themeStore from '../../../theme/models/ThemeStore';
import AddWorkflowCollectionForm from '../../components/add-workflow-collection-form';
import Heading from '../../../styled-components/elements/heading';
import Divider from '../../../styled-components/elements/divider';

const workflowCollectionTerm = themeStore._.workflowCollection;

const WorkflowCollectionCreationView = () => (
	<Container fluid={true}>
		<Row>
			<Col xs={12}>
				<Breadcrumb>
					<BreadcrumbItem tag={Link} to="/">
						Home
					</BreadcrumbItem>
					<BreadcrumbItem
						tag={Link}
						to=".."
					>{`${workflowCollectionTerm}s`}</BreadcrumbItem>
					<BreadcrumbItem active>New</BreadcrumbItem>
				</Breadcrumb>
				<Heading>Create a {workflowCollectionTerm?.toLowerCase()}</Heading>
				<Divider />
			</Col>
			<Col lg={8}>
				<AddWorkflowCollectionForm />
			</Col>
		</Row>
	</Container>
);

export default WorkflowCollectionCreationView;
