import styled from 'styled-components';
import { Link } from '@reach/router';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const UnstyledLink = styled(Link)`
	color: ${colors.black};
	text-decoration: none;
	line-height: 1;

	&:hover {
		color: ${colors.black};
		text-decoration: none;
	}
`;

export default UnstyledLink;
