import React, { useMemo, useState } from 'react';
import StyledSelect from '../../../styled-components/elements/select';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import { FieldGroupField } from '../../models/ProductTemplateInterface';
import { SwitchInput } from './style';

interface Props {
	header: string;
	setSubmissionData: React.Dispatch<
		React.SetStateAction<ProductSubmissionData | undefined>
	>;
	initialFields?: FieldGroupField[];
	submittedColumns?: Record<string, string>;
}

const ProductMapFieldsRows = ({
	header,
	setSubmissionData,
	initialFields,
	submittedColumns,
}: Props) => {
	const [switchChecked, setSwitchChecked] = useState(false);
	const [selectValue, setSelectValue] = useState<any>();

	const fields = useMemo(() => {
		const fieldNames = Object.values(submittedColumns || {});
		const selectedField = submittedColumns?.[header];
		return initialFields?.filter(
			(f) => !fieldNames.includes(f.value) || f.value === selectedField
		);
	}, [header, initialFields, submittedColumns]);

	const handleSwitchChange = () => {
		setSwitchChecked(!switchChecked);
		if (switchChecked) {
			setSelectValue('');
			setSubmissionData((c) => {
				const columnsMapValue = c?.data?.columnsMap;
				delete (columnsMapValue || {})[header];
				return {
					...c,
					data: {
						...c?.data,
						columnsMap: columnsMapValue,
					},
				};
			});
		}
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selected = e.target.value;
		setSwitchChecked(!!selected);
		setSelectValue(selected);
		setSubmissionData((c) => {
			return {
				...c,
				data: {
					...c?.data,
					columnsMap: { ...c?.data?.columnsMap, [header]: selected },
				},
			};
		});
	};

	return (
		<>
			<td>{header}</td>
			<td>
				<SwitchInput
					type="switch"
					id={`${header}-switch`}
					name={`${header}-switch`}
					checked={switchChecked}
					onChange={() => {
						handleSwitchChange();
					}}
				/>
			</td>
			<td>
				{switchChecked ? (
					<StyledSelect
						id="templateField"
						name="field"
						required
						value={selectValue}
						onChange={(e) => handleSelectChange(e)}
					>
						<option value="">Template Field Name</option>
						{fields?.map((field, i) => {
							return (
								<option key={i} value={field?.value}>
									{field?.label}
								</option>
							);
						})}
					</StyledSelect>
				) : (
					<p>Will not be imported</p>
				)}
			</td>
		</>
	);
};

export default ProductMapFieldsRows;
