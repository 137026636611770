import React from 'react';
import { useObserver } from 'mobx-react';

import Notification from '../notification';
import notificationStore from '../../NotificationStore';
import { Container } from './style';

const NotificationsContainer = () => {
	const notifications = useObserver(() =>
		notificationStore.visibleNotifications.map((n) => (
			<Notification key={n.id} model={n} />
		))
	);

	return <Container>{notifications}</Container>;
};

export default NotificationsContainer;
