import styled from 'styled-components';
import { Link } from '@reach/router';
import { Table } from 'reactstrap';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const StyledLink = styled(Link)`
	color: ${colors.primary};
	text-decoration: none;

	&:hover {
		color: ${colors.primaryHighlighted};
		text-decoration: none;
	}
`;

export const StyledTable = styled(Table)`
	margin-top: 32px;
`;

export const HeadingCell = styled.th`
	color: ${colors.darkerGrey};
`;
