import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faCheckCircle,
	faPauseCircle,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themeStore from '../../../../theme/models/ThemeStore';
import { Workflow } from '../../../models';
import { StyledAlert, DisabledText, DisabledMask } from './style';

const workflowTerm = themeStore._.workflow.toLowerCase();

const WorkflowAlert = (props: {
	workflow: Workflow;
	color: string;
	icon: IconProp;
}) => (
	<StyledAlert color={props.color}>
		<DisabledText>
			<FontAwesomeIcon icon={props.icon} className="mr-2" />
			{`This ${workflowTerm} ${props.workflow.status}`}
		</DisabledText>
	</StyledAlert>
);

const WorkflowDisabledMask = (props: { workflow: Workflow }) => {
	const { workflow } = props;

	if (workflow.isPaused) {
		return (
			<>
				<WorkflowAlert
					workflow={workflow}
					color="danger"
					icon={faPauseCircle}
				/>
				<DisabledMask />
			</>
		);
	} else if (workflow.isCancelled) {
		return (
			<>
				<WorkflowAlert
					workflow={workflow}
					color="danger"
					icon={faTimesCircle}
				/>
				<DisabledMask />
			</>
		);
	} else if (workflow.isCompleted) {
		return (
			<WorkflowAlert workflow={workflow} color="success" icon={faCheckCircle} />
		);
	} else {
		return null;
	}
};

export default WorkflowDisabledMask;
