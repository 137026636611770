import styled, { css } from 'styled-components';
import { Link } from '@reach/router';
import { NavItem } from 'reactstrap';
import { secondaryButton } from '../../../theme/components/secondary-button';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const link = css`
	color: ${colors.primary};
	text-decoration: none;

	&:hover {
		color: ${colors.primaryHighlighted};
		text-decoration: none;
	}
`;

export const TabNavItem = styled(NavItem)`
	width: 50%;
`;

export const StyledLink = styled(Link)`
	${link};
`;

export const StyledSummary = styled.summary`
	${link};
`;

export const FullWidthLink = styled(Link)`
	${secondaryButton};
	width: 100%;
`;

export const FullWidthButton = styled.button`
	${secondaryButton};
	width: 100%;
`;

export const FullWidthLinkButton = styled(Link)`
	${secondaryButton};
	width: 100%;
`;
