import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	connectCurrentRefinements,
	CurrentRefinementsProvided,
} from 'react-instantsearch-core';
import RenderWhen from '../render-when';

import { StyledOutlineButton } from './style';

const ClearRefinementsButton = ({
	items,
	refine,
}: CurrentRefinementsProvided) => (
	<RenderWhen when={items.length > 0}>
		<StyledOutlineButton
			onClick={() => {
				// Weird type mismatch in algolia typedefs:
				// `Refinement` != `RefinementValue`
				// but it works anyway
				refine(items as any);
			}}
		>
			<FontAwesomeIcon icon={faTimes} className="mr-2" />
			Clear filters
		</StyledOutlineButton>
	</RenderWhen>
);

export const CustomClearRefinements = connectCurrentRefinements(
	ClearRefinementsButton
);
