import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const SmallText = styled.small`
	display: block;
	margin-bottom: 0.5em;
`;

//modifiers
export const SmallTextMuted = styled(SmallText)`
	color: ${colors.darkerGrey};
`;

export default SmallText;
