import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react';
import { CardBody, CardTitle, UncontrolledTooltip } from 'reactstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useEventHandler } from '../../../common';
import { useRegisterElement } from '../../svg/ElementStore';
import OwnerAvatarList from '../../../components/owner-avatar-list';
import SmallText from '../../../styled-components/elements/small';

import { SelectedTemplateStage } from '../../template.contexts';
import { TemplateActionableStage } from '../../models';

import {
	AvatarListContainer,
	CardTab,
	SingleStageCard,
} from '../template-stage-card/style';
import { TemplateStageCardProps } from '../template-stage-card';

import TemplateStageEditButtons from '../template-stage-edit-buttons';

const TemplateSingleStageCard = ({
	stage,
	editable,
	peeking,
}: TemplateStageCardProps<TemplateActionableStage>) => {
	const [selectedStage, setSelectedStage] = SelectedTemplateStage.useMaybe();
	const [isPeeking, setPeeking] = useState(peeking);

	const registerElement = useRegisterElement(stage._id);

	// build classes
	const selectedMarker = stage === selectedStage ? 'selected' : '';
	const peekingMarker = isPeeking ? 'peek' : '';
	const className = ['single-stage-card', selectedMarker, peekingMarker].join(
		' '
	);

	useEffect(() => {
		if (isPeeking) {
			setPeeking(false);
		}
	}, [isPeeking]);

	const handleClick = useEventHandler(() => setSelectedStage(stage));

	const renderAttachmentTab = () => {
		if (stage.inputSlots?.length) {
			return (
				<>
					<UncontrolledTooltip target={`attachmentTab${stage._id}`}>
						Attachment required
					</UncontrolledTooltip>
					<CardTab id={`attachmentTab${stage._id}`}>
						<FontAwesomeIcon icon={faPaperclip} />
					</CardTab>
				</>
			);
		}
	};

	// render methods
	const render = () => {
		return (
			<SingleStageCard
				className={className}
				innerRef={registerElement}
				onClick={handleClick}
				background={stage.phase ? stage.phase.color : '#fff'}
			>
				<CardBody>
					<TemplateStageEditButtons stage={stage} />
					{renderAttachmentTab()}
					<CardTitle>
						{stage.title}
						<SmallText className="mt-2">
							~{stage.expectedDurationHrs} hrs
						</SmallText>
					</CardTitle>

					<AvatarListContainer>
						<OwnerAvatarList owners={stage.owners} />
					</AvatarListContainer>
				</CardBody>
			</SingleStageCard>
		);
	};

	return useObserver(render);
};

export default TemplateSingleStageCard;
