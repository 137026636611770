import React, { useCallback } from 'react';
import { navigate } from '@reach/router';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SecondaryButton } from '../../../styled-components/elements/button';
import { useTemplateStore } from '../../../stores';
import { SelectedTemplate } from '../../template.contexts';

export const TemplateCloneButton = () => {
	const templateStore = useTemplateStore();
	const template = SelectedTemplate.presentValue;

	const cloneTemplate = useCallback(async () => {
		const cloned = await templateStore.cloneTemplate(template);
		return navigate(`/admin/workflow/templates/${cloned._id}`);
	}, [templateStore, template]);

	return (
		<SecondaryButton onClick={cloneTemplate} className="mb-2 mb-sm-0 mr-2">
			<FontAwesomeIcon icon={faClone} className="mr-2" />
			Clone this template
		</SecondaryButton>
	);
};

export default TemplateCloneButton;
