import React from 'react';

import { SelectedTemplateStage } from '../../template.contexts';
import OwnerSelect from '../owner-select';
import { BaseWorkflowOwnable } from '../../../models/BaseWorkflowOwnableModel';
import { StageType } from '../../models';

const StageOwnerSelect = () => {
	const stage = SelectedTemplateStage.presentValue;

	if (stage.type === StageType.parallel) {
		return null;
	}
	return <OwnerSelect ownable={stage as BaseWorkflowOwnable} />;
};

export default StageOwnerSelect;
