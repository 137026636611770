import styled from 'styled-components';
import themeStore from '../../theme/models/ThemeStore';
import { Button } from 'reactstrap';

import { boxShadowFocus } from '../../theme/ui/ui';

const { colors } = themeStore.selectedTheme;

export const StyledCircleButton = styled(Button)`
	&& {
		border-radius: 100%;
	}
	background-color: ${colors.darkerGrey};
	color: ${colors.white};
	height: 2.5em;
	margin-left: 0.5em;
	width: 2.5em;

	&:hover {
		background-color: ${colors.darkestGrey};
	}

	&:focus {
		${boxShadowFocus};
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.disabled {
		&& {
			border: 1px solid ${colors.darkerGrey};
			cursor: not-allowed;
		}
		color: ${colors.darkerGrey};
	}

	&.small,
	&.sm {
		height: 2em;
		width: 2em;

		svg {
			top: -0.125em;
			left: -0.25em;
		}
	}

	svg {
		position: relative;
	}
`;
