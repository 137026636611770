import React, { FormEvent, useCallback, useRef } from 'react';
import { navigate } from '@reach/router';
import { getSnapshot } from 'mobx-state-tree';

import { useStores, useTemplateStore, useWorkflowStore } from '../../../stores';
import { emptyWorkflow } from '../../models';
import EditWorkflowForm from '../edit-workflow-form';

const AddWorkflowForm = () => {
	const workflowStore = useWorkflowStore();
	const templateStore = useTemplateStore();

	let templateUsed = '';
	const hash = window.location.hash.slice(1);
	if (hash) {
		window.history.replaceState(null, document.title, window.location.pathname);
		const resolvedTemplate = templateStore.getOne(hash);
		if (resolvedTemplate) {
			templateUsed = resolvedTemplate._id;
		}
	}
	const workflowRef = useRef(emptyWorkflow(useStores(), templateUsed));

	const addWorkflow = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdWorkflow = await workflowStore.createOne(
				getSnapshot(workflowRef.current)
			);

			return navigate(
				`/admin/workflow/workflows/${createdWorkflow._id}/stages`
			);
		},
		[workflowStore]
	);

	return (
		<EditWorkflowForm
			workflow={workflowRef.current}
			onSubmit={addWorkflow}
			templateUsed={templateUsed}
		/>
	);
};
export default AddWorkflowForm;
