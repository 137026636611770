import React, { useCallback, useState } from 'react';

import { AssetVersion } from '../../models/AssetVersionModel';

import AssetViewerWarning from './asset-viewer-warning';
import DetailedViewButton from './detail-view-button';
import { PermissionsCheck } from '../../../accounts/permissions-check.component';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import SubmitButton from '../../../components/submit-button';
import { useAPIClient } from '../../../stores';
import { applyPatch } from 'mobx-state-tree';
import themeStore from '../../../theme/models/ThemeStore';
import { DamAssetModel } from '../../models/DamAssetModel';
import { useObserver } from 'mobx-react';
import { StyledImage, Container } from './style';

// interface
interface ImageViewerProps {
	asset: AssetVersion;
}

// component
const ImageViewer = (props: ImageViewerProps) => {
	const { asset } = props;

	const [isValid, setValid] = useState(true);

	const apiClient = useAPIClient();

	const refreshPreview = useCallback(async () => {
		const prefix = DamAssetModel.is(asset) ? 'd' : 'w';
		const updatedURL = await apiClient.put(
			`/preview/refresh/${prefix}/${asset._id}`
		);
		applyPatch(asset, {
			path: '/previewURL',
			op: 'replace',
			value: updatedURL,
		});
		setValid(true);
	}, [apiClient, asset]);

	return useObserver(() => {
		const previewImage =
			isValid && asset.previewURL ? (
				<StyledImage
					className="img-fluid"
					src={asset.previewURL}
					onError={() => setValid(false)}
				/>
			) : (
				<AssetViewerWarning
					text={`Unable to preview your file: An error may have occurred while fetching it.`}
					color="danger"
				/>
			);

		return (
			<Container>
				<DetailedViewButton asset={asset} />

				<PermissionsCheck check="canRefreshPreview">
					<SubmitButton
						label={`Refresh ${themeStore._.asset.toLowerCase()} preview`}
						icon={faSync}
						onClick={refreshPreview}
						className="mx-4"
					/>
				</PermissionsCheck>

				{previewImage}
			</Container>
		);
	});
};

export default ImageViewer;
