import React from 'react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import EntityMetadataTemplateAddForm from '../../components/entity-metadata-template-add-form';
import Divider from '../../../styled-components/elements/divider';

const MetadataTemplateCreationView = () => (
	<Container fluid={true}>
		<Row>
			<Col xs={12}>
				<Breadcrumb>
					<BreadcrumbItem tag={Link} to="/">
						Home
					</BreadcrumbItem>
					<BreadcrumbItem tag={Link} to="..">
						Metadata Templates
					</BreadcrumbItem>
					<BreadcrumbItem active>New</BreadcrumbItem>
				</Breadcrumb>
				<Heading>Create a template</Heading>
				<Divider />
			</Col>
			<Col lg={8}>
				<EntityMetadataTemplateAddForm />
			</Col>
		</Row>
	</Container>
);

export default MetadataTemplateCreationView;
