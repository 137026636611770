import styled from 'styled-components';

const ViewWrapper = styled.div`
	display: block;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
`;

// modifiers
interface LoginViewWrapperProps {
	image: string;
	rgb: number[];
}

export const LoginViewWrapper = styled(ViewWrapper)<LoginViewWrapperProps>`
	background-size: cover;
	background-image:
		linear-gradient(
			to top,
			rgba(${(props) => props.rgb[0]}, ${(props) => props.rgb[1]}, ${(props) =>
	props.rgb[2]}, 0.7) 0%,
			rgba(${(props) => props.rgb[0]}, ${(props) => props.rgb[1]}, ${(props) =>
	props.rgb[2]}, 0.1) 100%
		),
		url('${(props) => props.image}');
`;

export default ViewWrapper;
