import React from 'react';
import { useObserver } from 'mobx-react';

import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';

const productsListColumns: ListColumn<any>[] = makeColumns([
	{
		label: 'Reference ID',
		prop: 'refId',
	},
	{
		label: 'Name',
		prop: 'name',
	},
	// {
	// 	label: 'Record Type',
	// 	prop: '',
	// },
	// {
	// 	label: 'Store',
	// 	prop: '',
	// },
	// {
	// 	label: 'Last modified',
	// 	prop: 'updatedAt',
	// },
]);

// component
const ProductListTable = ({ products }: { products?: any[] }) => {
	console.log(products);
	const render = () => (
		<ListTable
			columns={productsListColumns}
			rows={products || []}
			noResultsLabel="No products yet"
		/>
	);

	return useObserver(render);
};

export default ProductListTable;
