import React from 'react';
import { StageStatus } from '../../../models/StageStatusModel';
import { WorkflowActionableStage } from '../../models';
import StageDialog from './stage-dialog';

const BypassStageDialog = ({ stage }: { stage: WorkflowActionableStage }) => (
	<StageDialog
		header={`Bypassing stage "${stage.title}"`}
		context="Please add context about why this stage is bypassed."
		warning="Are you sure you want to bypass this stage?"
		onClick={(message: string) =>
			stage.updateStatus(StageStatus.completed, message)
		}
	/>
);

export default BypassStageDialog;
