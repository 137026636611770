import React from 'react';
import Subtitle from '../../../styled-components/elements/subtitle';
import ScrollTable from '../../../styled-components/elements/scroll-table';
import { ProductTemplate } from '../../models/ProductTemplateInterface';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import ProductMapFieldsRows from './product-map-fields-rows';

interface Props {
	sheetHeaders: string[];
	templateSelected?: ProductTemplate;
	setSubmissionData: React.Dispatch<
		React.SetStateAction<ProductSubmissionData | undefined>
	>;
	submissionData?: ProductSubmissionData;
}

const ProductMapFields = ({
	sheetHeaders,
	templateSelected,
	setSubmissionData,
	submissionData,
}: Props) => {
	return (
		<>
			<Subtitle>3. Map Fields</Subtitle>
			<ScrollTable>
				<thead>
					<tr>
						<th>
							<strong>From imported document</strong>
						</th>
						<th>
							<strong>Import (yes/no)</strong>
						</th>
						<th>
							<strong>Template field</strong>
						</th>
					</tr>
				</thead>
				<tbody>
					{sheetHeaders.map((header, i) => {
						return (
							<tr key={i}>
								<ProductMapFieldsRows
									header={header}
									setSubmissionData={setSubmissionData}
									initialFields={[
										{ value: 'name', label: 'Name' },
										{ value: 'refId', label: 'Reference ID' },
										...(templateSelected?.fields || []),
									]}
									submittedColumns={submissionData?.data?.columnsMap}
								/>
							</tr>
						);
					})}
				</tbody>
			</ScrollTable>
		</>
	);
};

export default ProductMapFields;
