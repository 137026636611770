import React from 'react';
import { capitalizeFirstLetter, buildClassList } from '../../common';
import { WorkflowStatus, workflowStatusColors } from '../../workflows/models';
import Pill from '../../styled-components/elements/pill';

const WorkflowStatusPill = (props: {
	status: WorkflowStatus;
	className?: string;
}) => {
	const render = () => (
		<Pill
			pill
			className={buildClassList('workflow-status-pill', props.className)}
			color={workflowStatusColors[props.status]}
		>
			{capitalizeFirstLetter(props.status)}
		</Pill>
	);

	return render();
};

export default WorkflowStatusPill;
