import React from 'react';
import { useObserver } from 'mobx-react';
import { Link, Router } from '@reach/router';
import {
	Breadcrumb,
	BreadcrumbItem,
	Col,
	Container,
	Nav,
	Row,
} from 'reactstrap';

import { FilterNavItem } from '../../../components/side-bar-filters-nav/style';
import Heading from '../../../styled-components/elements/heading';
import { AdminNavLink } from './style';

import { usePermissions } from '../../../stores';
import { UserPermissions } from '../../../models/UserPermissionsModel';

import MetadataTemplatesIndexView from '../../../metadata-templates/views/metadata-templates-index';
import ThemePanel from '../../components/theme-panel';
import UserGroupsPanel from '../../components/user-groups-panel';
import UsersPanel from '../../components/users-panel';
import AccountsPanel from '../../components/accounts-panel';
import Divider from '../../../styled-components/elements/divider';

const buildNavOptions = (permissions: UserPermissions) => {
	let navOptions = [];

	if (permissions.canViewUsers) {
		const usersRoute = 'users';
		navOptions.push({
			label: 'Users',
			value: usersRoute,
			route: <UsersPanel path={usersRoute} key={usersRoute} default />,
		});
	}

	if (permissions.canViewUserGroups) {
		const userGroupsRoute = 'user-groups-panel';
		navOptions.push({
			label: 'User Groups',
			value: userGroupsRoute,
			route: <UserGroupsPanel path={userGroupsRoute} key={userGroupsRoute} />,
		});
	}

	if (permissions.canViewAccounts) {
		const accountsRoute = 'accounts';
		navOptions.push({
			label: 'Accounts',
			value: accountsRoute,
			route: <AccountsPanel path={accountsRoute} key={accountsRoute} />,
		});
	}

	if (permissions.canViewMetadataTemplates) {
		const metadataRoute = 'metadata-templates';
		navOptions.push({
			label: 'Metadata Templates',
			value: metadataRoute,
			route: (
				<MetadataTemplatesIndexView path={metadataRoute} key={metadataRoute} />
			),
		});
	}

	if (permissions.canViewThemes) {
		const themesRoute = 'themes';
		navOptions.push({
			label: 'Themes',
			value: themesRoute,
			route: <ThemePanel path={themesRoute} key={themesRoute} />,
		});
	}

	return navOptions;
};

const AdminDashboardView = () => {
	const permissions = usePermissions();

	const isSelected = (link: string) => {
		return window.location.pathname.includes(link) ? 'selected' : '';
	};

	return useObserver(() => {
		const navOptions = buildNavOptions(permissions);

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Breadcrumb>
							<BreadcrumbItem tag={Link} to="/">
								Home
							</BreadcrumbItem>
							<BreadcrumbItem active>Admin</BreadcrumbItem>
						</Breadcrumb>
						<Heading>Admin Dashboard</Heading>
						<Divider />
					</Col>
					<Col md={2}>
						<Nav vertical className="mt-3">
							{navOptions.map(({ label, value }) => (
								<FilterNavItem key={value}>
									<AdminNavLink
										to={`./${value}`}
										className={isSelected(`${value}`)}
									>
										{label}
									</AdminNavLink>
								</FilterNavItem>
							))}
						</Nav>
					</Col>

					<Col md={10}>
						<Router primary={false}>{navOptions.map((o) => o.route)}</Router>
					</Col>
				</Row>
			</Container>
		);
	});
};

export default AdminDashboardView;
