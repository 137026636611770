import React from 'react';
import { useObserver } from 'mobx-react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import { isIdentifiableLoaded } from '../../../common';
import { LoadingRow } from '../../../components/loading-indicator';
import Heading from '../../../styled-components/elements/heading';
import { useWorkflowCollectionStore } from '../../../stores';
import themeStore from '../../../theme/models/ThemeStore';
import EditWorkflowCollectionForm from '../../components/edit-workflow-collection-form';
import Divider from '../../../styled-components/elements/divider';

// constants
const collectionTerm = themeStore._.workflowCollection;

// interface
interface EditWorkflowCollectionProps {
	projectId: string;
}

// component
const WorkflowCollectionEditView = (props: EditWorkflowCollectionProps) => {
	const { projectId } = props;
	const workflowCollectionStore = useWorkflowCollectionStore();

	const render = () => {
		const collection = workflowCollectionStore.getOne(projectId);

		return (
			<Container fluid={true}>
				{isIdentifiableLoaded(collection) ? (
					<Row>
						<Col xs={12}>
							<Breadcrumb>
								<BreadcrumbItem tag={Link} to="/">
									Home
								</BreadcrumbItem>
								<BreadcrumbItem
									tag={Link}
									to="../.."
								>{`${collectionTerm}s`}</BreadcrumbItem>
								<BreadcrumbItem tag={Link} to="..">
									{collection.title}
								</BreadcrumbItem>
								<BreadcrumbItem active>Edit</BreadcrumbItem>
							</Breadcrumb>
							<Heading>Edit "{collection.title}"</Heading>
							<Divider />
						</Col>
						<Col lg={8}>
							<EditWorkflowCollectionForm collection={collection} />
						</Col>
					</Row>
				) : (
					<LoadingRow label="Loading..." />
				)}
			</Container>
		);
	};

	return useObserver(render);
};

export default WorkflowCollectionEditView;
