import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { IAnyStateTreeNode, resolveIdentifier } from 'mobx-state-tree';
import { useObserver } from 'mobx-react';

import Heading from '../../../styled-components/elements/heading';
import { useWorkflowStore } from '../../../stores';

import { AssetVersionModel } from '../../models/AssetVersionModel';
import { AssetPreviewDiff } from '../../components/comparison-preview';
import { AssetComparisonTable } from '../../components/comparison-props-table';
import { RouteComponentProps } from '@reach/router';
import Divider from '../../../styled-components/elements/divider';

const getAssetVersion = (node: IAnyStateTreeNode, id?: string) => {
	if (id) {
		return resolveIdentifier(AssetVersionModel, node, id);
	}
};

// component
const VersionsComparisonView = (
	props: RouteComponentProps<{
		workflowAId: string;
		versionAId: string;
		workflowBId: string;
		versionBId: string;
	}>
) => {
	const workflowStore = useWorkflowStore();

	const render = () => {
		const workflowA = workflowStore.findOne(props.workflowAId);
		const workflowB = workflowStore.findOne(props.workflowBId);

		if (!workflowA || !workflowB) {
			return null;
		}

		const versionA = getAssetVersion(workflowA, props.versionAId);
		const versionB = getAssetVersion(workflowB, props.versionBId);

		if (!versionA || !versionB) {
			return null;
		}

		return (
			<Container fluid={true}>
				<Row>
					<Col xs={12}>
						<Heading>Versions comparison</Heading>
						<Divider />
						<AssetPreviewDiff versionA={versionA} versionB={versionB} />
					</Col>
					<Col xs={12}>
						<AssetComparisonTable
							workflowA={workflowA}
							versionA={versionA}
							workflowB={workflowB}
							versionB={versionB}
						/>
					</Col>
				</Row>
			</Container>
		);
	};

	return useObserver(render);
};

export default VersionsComparisonView;
