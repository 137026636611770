import React, { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Badge,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap';
import { User } from '../../../accounts/models/UserModel';
import {
	UserStatus,
	userStatusBadgeColors,
} from '../../../accounts/models/UserStatus.model';
import {
	camelCaseToSentenceCase,
	capitalizeFirstLetter,
} from '../../../common';
import { buildUserUrl } from '../../../common/links';
import Avatar from '../../../styled-components/elements/avatar';
import { DropDownMenuItem } from '../../../styled-components/elements/dropdown-menu';
import { ConfirmationDialog } from '../../../components/modals';
import ScrollTable from '../../../styled-components/elements/scroll-table';
import ScrollCard from '../../../styled-components/elements/scroll-card';
import { useUserStore } from '../../../stores';
import { useModalCreator } from '../../../stores/ModalStack';
import PasswordLinkDialog from './password-link-dialog';
import { RequiresFeatureFlag } from '../../../core/FeatureFlags';
import Link from '../../../styled-components/elements/link';
import { Name } from './style';

// interface
interface UserTableProps {
	users: ReadonlyArray<User>;
}

interface HasUser {
	user: User;
}

// components
const NoUsersRow = () => (
	<tr>
		<td colSpan={3}>
			<p>No users yet</p>
		</td>
	</tr>
);

const UserRowDropdown = ({ user }: HasUser) => {
	const userStore = useUserStore();
	const modalStack = useModalCreator();

	const showDeletionConfirmation = useCallback(() => {
		modalStack.addModal(
			<ConfirmationDialog
				header="Delete user"
				onConfirm={() => userStore.deleteOne(user)}
			>
				<p>Are you sure you want to delete {user.name}'s account?</p>
			</ConfirmationDialog>
		);
	}, [user, userStore, modalStack]);

	const resendInvitation = useCallback(() => {
		userStore.resendInvitation(user);
	}, [user, userStore]);

	const showPasswordResetLink = useCallback(() => {
		modalStack.addModal(<PasswordLinkDialog user={user} />);
	}, [user, modalStack]);

	const resetPasswdOrReinvite =
		user.status === UserStatus.invited ? (
			<DropDownMenuItem onClick={resendInvitation}>
				Resend invitation
			</DropDownMenuItem>
		) : (
			<DropDownMenuItem onClick={showPasswordResetLink}>
				Reset password
			</DropDownMenuItem>
		);

	return (
		<UncontrolledDropdown>
			<DropdownToggle color="white">
				<FontAwesomeIcon icon={faEllipsisV} />
			</DropdownToggle>
			<DropdownMenu>
				<Link to={buildUserUrl(user)}>
					<DropDownMenuItem>Edit user</DropDownMenuItem>
				</Link>
				{resetPasswdOrReinvite}
				<RequiresFeatureFlag flag="deleteUser">
					<DropDownMenuItem
						className="danger"
						onClick={showDeletionConfirmation}
					>
						Delete user
					</DropDownMenuItem>
				</RequiresFeatureFlag>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

const UserTableRow = (props: HasUser) => {
	const user = props.user;

	return useObserver(() => (
		<tr key={user._id}>
			<td>
				<Link to={buildUserUrl(user)}>
					<Avatar image={user.picture} />
				</Link>
			</td>
			<td>
				<Link to={buildUserUrl(user)}>
					<div>
						<Name>{user.name}</Name>
						<small>{user.email}</small>
					</div>
				</Link>
			</td>
			<td>
				<p>{user.expertise}</p>
			</td>
			<td>
				<Badge color={userStatusBadgeColors[user.status]} pill>
					{capitalizeFirstLetter(user.status)}
				</Badge>
			</td>
			<td>
				<p>{camelCaseToSentenceCase(user.role)}</p>
			</td>
			<td>
				<UserRowDropdown user={user} />
			</td>
		</tr>
	));
};

const UserTable = (props: UserTableProps) =>
	useObserver(() => (
		<ScrollCard>
			<ScrollTable>
				<thead>
					<tr>
						<th>{/* image column */}</th>
						<th>Name</th>
						<th>Expertise</th>
						<th>Status</th>
						<th>Role</th>
						<th>{/* menu-button column */}</th>
					</tr>
				</thead>
				<tbody>
					{props.users.length > 0 ? (
						props.users.map((user) => (
							<UserTableRow key={user._id} user={user} />
						))
					) : (
						<NoUsersRow />
					)}
				</tbody>
			</ScrollTable>
		</ScrollCard>
	));

export default UserTable;
