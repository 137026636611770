import React, { FormEvent } from 'react';
import { useObserver } from 'mobx-react';
import { Col, Form, FormGroup, Row } from 'reactstrap';

import { usePatchers, unwrapEvent } from '../../../common';
import LabeledInput from '../../../components/labeled-input';
import SubmitButton from '../../../components/submit-button';
import EntityMetadataForm from '../../../metadata/components/entity-metadata-form';
import themeStore from '../../../theme/models/ThemeStore';
import { WorkflowCollection } from '../../models/WorkflowCollectionModel';
import CollectionWorkflowSelect from '../collection-workflow-select';

interface WorkflowCollectionFormProps {
	selectedCollection: WorkflowCollection;
	onSubmit: (event: FormEvent) => Promise<unknown>;
}

const WorkflowCollectionForm = (props: WorkflowCollectionFormProps) => {
	const { selectedCollection, onSubmit } = props;
	const propertyPatcher = usePatchers(selectedCollection);

	return useObserver(() => {
		const isFormValid = selectedCollection.title !== '';

		return (
			<Form>
				<Row form>
					<Col md={12}>
						<LabeledInput
							label="Title*"
							type="text"
							name="title"
							id="title"
							value={selectedCollection.title}
							onChange={unwrapEvent(propertyPatcher('title'))}
							required
							inputValid={selectedCollection.title !== ''}
							errorMsg="Title is required"
						/>
					</Col>
					<Col md={12}>
						<FormGroup>
							<CollectionWorkflowSelect collection={selectedCollection} />
						</FormGroup>
					</Col>
				</Row>

				<EntityMetadataForm metadata={selectedCollection.metadata} />

				<SubmitButton
					onClick={onSubmit}
					label={`Save ${themeStore._.workflowCollection.toLowerCase()}`}
					disabled={!isFormValid}
				/>
			</Form>
		);
	});
};

export default WorkflowCollectionForm;
