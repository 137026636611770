import styled from 'styled-components';
import { ffBold } from '../../../theme/ui/typography';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const Name = styled.p`
	${ffBold};
	margin-bottom: 0;
`;

export const PanelHeader = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 1.5em;
	padding-top: 1.5em;
	border-top: 1px solid ${colors.grey};

	@media only screen and (min-width: 768px) {
		flex-direction: row;
		align-items: flex-end;
		border-top: none;
		margin-top: 0;
		padding-top: 0;
	}
`;
