import styled from 'styled-components';
import { Pagination } from 'reactstrap';
import themeStore from '../../theme/models/ThemeStore';
import { focus } from '../../theme/ui/ui';

const { colors } = themeStore.selectedTheme;

export const StyledPagination = styled(Pagination)`
	display: flex;
	justify-content: center;
	margin-top: 2.5em;
	margin-bottom: 0;

	&& {
		.page-item {
			font-size: 15px;

			.page-link {
				color: ${colors.secondary};

				&:focus {
					${focus}
				}

				&:disabled {
					cursor: not-allowed;
					opacity: .5;

					&:hover {
						background-color: ${colors.white};
					}
				}
			}

			&.active .page-link {
				background-color ${colors.secondary};
				border-color: ${colors.secondary};
				color: ${colors.white};
			}
		}
	}
`;
