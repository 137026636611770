import React from 'react';
import PieChart from 'react-minimal-pie-chart';

import { Workflow } from '../../../workflows/models';
import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const pieChartBaseProps = {
	className: 'pie-chart',
	lineWidth: 25,
	style: { height: '25px', width: '25px' },
	animate: true,
} as const;

interface WorkflowCompletionPieChartProps {
	workflow: Workflow;
}

const WorkflowCompletionPieChart = (props: WorkflowCompletionPieChartProps) => {
	const {
		totalCount,
		completedCount,
		percent,
	} = props.workflow.completionStatus;

	const pieChartData = [
		{
			value: totalCount - completedCount,
			color: colors.grey,
		},
		{
			value: completedCount,
			color: colors.secondary,
		},
	];

	return (
		<>
			<PieChart {...pieChartBaseProps} data={pieChartData} />
			<p className="ml-2">{percent}</p>
		</>
	);
};

export default WorkflowCompletionPieChart;
