import React from 'react';
import { useObserver } from 'mobx-react';
import { listOwnerNames, Ownable } from '../../models/BaseWorkflowOwnableModel';

const OwnerList = ({ entity }: { entity: Ownable }) => {
	const render = () => <span>{listOwnerNames(entity)}</span>;

	return useObserver(render);
};

export default OwnerList;
