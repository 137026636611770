import React, { useCallback } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StageActionButtonProps } from '.';
import {
	StageStatus,
	statusForRoadblockToggle,
} from '../../../models/StageStatusModel';
import { useModalCreator } from '../../../stores/ModalStack';
import { isInputStage } from '../../models';
import { OutlinedCircleButton, StyledTooltip } from './style';
import ChangeStageStatusDialog from './change-stage-status-dialog';

const RoadblockStageButton = (props: StageActionButtonProps) => {
	const { stage } = props;
	const modalStack = useModalCreator();

	const openRoadblockModal = useCallback(() => {
		modalStack.addModal(
			<ChangeStageStatusDialog
				stage={stage}
				statusChange={statusForRoadblockToggle[stage.status]}
			/>
		);
	}, [stage, modalStack]);

	if (!isInputStage(stage)) {
		return null;
	}

	return (
		<>
			<StyledTooltip target="roadblockBtn">
				{stage.status === StageStatus.active
					? 'Roadblock stage'
					: 'Resolve roadblock'}
			</StyledTooltip>
			<OutlinedCircleButton
				id="roadblockBtn"
				className={
					stage.status === StageStatus.roadblocked ? 'roadblocked' : ''
				}
				disabled={stage.status === StageStatus.completed}
				color=""
				size="sm"
				icon={faExclamationTriangle}
				onClick={openRoadblockModal}
			>
				<FontAwesomeIcon icon={faExclamationTriangle} />
				{stage.status === StageStatus.roadblocked ? ' Resolve' : ''}
			</OutlinedCircleButton>
		</>
	);
};

export default RoadblockStageButton;
