import styled from 'styled-components';
import themeStore from '../../../theme/models/ThemeStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import inputField from '../../../theme/components/input-field';
import unstyledButton from '../../../theme/components/unstyled-button';
import { fsNormal, fsSmol } from '../../../theme/ui/typography';

const { colors } = themeStore.selectedTheme;

export const Container = styled.div`
	position: relative;
`;

// link tab panel
export const LinkIcon = styled(FontAwesomeIcon)`
	position: absolute;
	color: ${colors.darkestGrey};
	left: 10px;
	top: 10px;
`;

export const LinkInput = styled.input`
	${inputField};
	background-color: ${colors.lightestGrey};
	height: 32px;
	padding-left: 32px;
	padding-right: 72px;
	width: 100%;
`;

export const CopyButton = styled.button`
	${unstyledButton};
	position: absolute;
	color: ${colors.primary};
	right: 8px;
	top: 4px;

	&:hover {
		color: ${colors.primaryHighlighted};
	}
`;

export const CopyLabel = styled.span`
	${fsSmol};
	margin-left: 8px;
`;

export const Instructions = styled.div`
	${fsNormal};
	margin-top: 32px;
`;
