import styled from 'styled-components';
import { Badge } from 'reactstrap';
import themeStore from '../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

const Pill = styled(Badge)`
	color: ${colors.white};

	&& {
		padding: 4px 6px;
		font-size: 11px;
	}

	&.badge-active {
		background-color: ${colors.active};
	}
	&.badge-overdue {
		background-color: ${colors.overdue};
	}
	&.badge-blocked {
		background-color: ${colors.blocked};
	}
	&.badge-pipeline {
		background-color: ${colors.pipeline};
	}
	&.badge-completed {
		background-color: ${colors.success};
	}
`;

export default Pill;
