import React from 'react';
import { useObserver } from 'mobx-react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { Link } from '@reach/router';

import { QueryTypes } from '../../../queries/models/QueryStore';
import themeStore from '../../../theme/models/ThemeStore';

import SaveAlgoliaQueryButton from '../../components/save-algolia-query-button';
import CustomAssetHits from '../../components/asset-infinite-hits';
import CustomSearchBox from '../../../components/algolia';
import Heading from '../../../styled-components/elements/heading';
import Divider from '../../../styled-components/elements/divider';

const assetTerm = themeStore._.asset.toLowerCase();
const searchInputPlaceholder = `Search all ${assetTerm}s...`;

const searchClient = algoliasearch(
	`${process.env.REACT_APP_ALGOLIA_APP_ID}`,
	`${process.env.REACT_APP_ALGOLIA_API_KEY}`
);

const AssetsIndexView = () => {
	const render = () => {
		return (
			<InstantSearch
				indexName={`${process.env.REACT_APP_ALGOLIA_DAM_INDEX}`}
				searchClient={searchClient}
			>
				<Configure hitsPerPage={12} />

				<Container fluid={true}>
					<Row>
						<Col xs={12}>
							<Breadcrumb>
								<BreadcrumbItem tag={Link} to="/">
									Home
								</BreadcrumbItem>
								<BreadcrumbItem active>{themeStore._.asset}s</BreadcrumbItem>
							</Breadcrumb>
							<Heading>{themeStore._.asset}s</Heading>
						</Col>
						<Col md={10}>
							<CustomSearchBox placeholder={searchInputPlaceholder} />
						</Col>
						<Col md={2} className="mt-md-0 mt-3">
							<SaveAlgoliaQueryButton
								className="float-md-right float-none"
								type={QueryTypes.ASSETS}
							/>
						</Col>
						<Col xs={12} className="mt-4">
							<Divider />
						</Col>
					</Row>
					<CustomAssetHits />
				</Container>
			</InstantSearch>
		);
	};

	return useObserver(render);
};

export default AssetsIndexView;
