import themeStore from '../../../theme/models/ThemeStore';
import styled from 'styled-components';
import { Link } from '@reach/router';
import anchor from '../../../theme/components/anchor';

const { colors } = themeStore.selectedTheme;

const CellLink = styled(Link)`
	${anchor};
	color: ${colors.primary};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
		color: ${colors.primaryHighlighted};
	}
`;

export default CellLink;
