import { Instance, onSnapshot, types } from 'mobx-state-tree';
import { ExtractProps } from 'mobx-state-tree/dist/types/complex-types/model';
import { findAsObject, saveObject } from '../../common';

import { IndexViewModel } from './IndexViewModel';

const AEX_VIEW_OPTIONS = 'aexViewOptions';

const indexView = types.optional(IndexViewModel, {});

const IndexViewStoreModelInferred = types.model('IndexViewStore', {
	assets: indexView,
	workflows: indexView,
	assetCollections: indexView,
	workflowCollections: indexView,
});

export interface IndexViewStoreModel
	extends Infer<typeof IndexViewStoreModelInferred> {}

export const IndexViewStoreModel: IndexViewStoreModel = IndexViewStoreModelInferred;

export interface IndexViewStore extends Instance<IndexViewStoreModel> {}

export type IndexViewName = keyof ExtractProps<IndexViewStoreModel>;

export const indexViewStore: IndexViewStore = IndexViewStoreModel.create(
	findAsObject(AEX_VIEW_OPTIONS) ?? {}
);

onSnapshot(indexViewStore, (snapshot) => {
	saveObject(AEX_VIEW_OPTIONS, snapshot);
});
