import React from 'react';
import { useObserver } from 'mobx-react';
import Moment from 'react-moment';

import { ListColumn, makeColumns } from '../../../components/list-column';
import ListTable from '../../../components/list-table';
import { CellLink } from '../../../styled-components/blocks/table';
import WorkflowStatusPill from '../../../components/workflow-status-pill';
import WorkflowHealthFlag from '../../../dashboard/components/workflow-health-flag';
import themeStore from '../../../theme/models/ThemeStore';
import { listActiveStageNames, Workflow, WorkflowStatus } from '../../models';
import ScrollCard from '../../../styled-components/elements/scroll-card';

const workflowListColumns: ListColumn<Workflow>[] = makeColumns([
	{
		label: 'Name',
		prop: (w: Workflow) => <CellLink to={`./${w._id}`}>{w.title}</CellLink>,
	},
	{
		label: `In ${themeStore._.workflowCollection}`,
		prop: (w: Workflow) =>
			w.workflowCollection ? <p>{w.workflowCollection.title}</p> : <p>—</p>,
	},
	{
		label: `Active ${themeStore._.stage.toLowerCase()}(s)`,
		prop: (w: Workflow) => <p>{listActiveStageNames(w)}</p>,
	},
	{
		label: 'Due date',
		prop: (w: Workflow) =>
			w.dueDate ? <Moment format="MM/DD" date={w.dueDate} /> : 'TBD',
	},
	{
		label: 'Status',
		prop: (w: Workflow) => <WorkflowStatusPill status={w.status} />,
	},
	{
		label: 'Health',
		prop: (w: Workflow) =>
			w.status === WorkflowStatus.active ? (
				<WorkflowHealthFlag workflow={w} />
			) : (
				<p>—</p>
			),
	},
]);

// component
const WorkflowListTable = ({
	workflows,
}: {
	workflows?: readonly Workflow[];
}) => {
	const render = () => (
		<ScrollCard className="mt-3">
			<ListTable
				columns={workflowListColumns}
				rows={workflows ? workflows : []}
				noResultsLabel={`No ${themeStore._.workflow.toLowerCase()}s yet`}
			/>
		</ScrollCard>
	);

	return useObserver(render);
};

export default WorkflowListTable;
