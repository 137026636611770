import styled from 'styled-components';
import { Card } from 'reactstrap';

import themeStore from '../../../theme/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const InputSlotCard = styled(Card)`
	border: 1px dashed ${colors.darkerGrey} !important;
	margin-bottom: 1.5rem;

	&& {
		z-index: 0;
	}
`;

export const InputSlotImage = styled.div`
	display: flex;
	align-items: center;
	background-color: ${colors.lighterGrey};
	color: ${colors.darkGrey};
	font-size: 26px;
	height: 50px;
	justify-content: center;
	margin: 0.5em 0.5em 0;
`;
