import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useAPIClient } from '../../../stores';
import { SecondaryButton } from '../../../styled-components/elements/button';
import { ProductSubmissionData } from '../../models/ProductSubmissionData';
import { Workflow } from '../../../workflows/models';
import { FormText } from 'reactstrap';

interface Props {
	submissionData?: ProductSubmissionData;
	workflow?: Workflow;
}

const ProductSubmitButton = ({ submissionData, workflow }: Props) => {
	const [APIresponse, setAPIResponse] = useState<string>('');
	const workflowId = workflow?._id;
	const client = useAPIClient();

	const handleClick = async () => {
		const url = workflow
			? `/workflow-actions/${workflowId}/product-import`
			: `/products/import/${submissionData?.data?.productTemplateId}`;
		try {
			const request = client.postFile(url, submissionData?.file as File, {
				...submissionData?.data,
			});

			const response = await request;

			console.log(response);
			const errorList = response.errors
				.map((e: any) => e.idx)
				.reduce((n: any, e: any) => {
					if (!n.includes(e)) n.push(e);
					return n;
				}, [])
				.join(', ');
			setAPIResponse(
				`${response.created.length} products were created, ${response.updated.length} products were updated, products on line number(s) ${errorList} had errors`
			);
		} catch (e) {
			console.error(e.message);
			setAPIResponse('Files not valid');
		}
	};

	return (
		<>
			<SecondaryButton onClick={() => handleClick()}>
				<FontAwesomeIcon icon={faDownload} /> import
			</SecondaryButton>
			<FormText className="mt-2">{APIresponse}</FormText>
		</>
	);
};

export default ProductSubmitButton;
