import React from 'react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { StyledLink } from './style';

// interface
interface SmallCTALinkProps {
	link: string;
	label: string;
	additionalClasses?: string;
}

// component
const SmallCTALink = (props: SmallCTALinkProps) => {
	const buildClassName = () => {
		if (props.additionalClasses) {
			return `small-cta-link ${props.additionalClasses}`;
		} else {
			return 'small-cta-link';
		}
	};

	return (
		<StyledLink to={props.link} className={buildClassName()}>
			<span className="cta-label">{props.label}</span>
			<FontAwesomeIcon icon={faChevronRight} className="cta-icon" />
		</StyledLink>
	);
};

export default SmallCTALink;
