import React from 'react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import themeStore from '../../../theme/models/ThemeStore';
import AddWorkflowForm from '../../components/add-workflow-form';
import Divider from '../../../styled-components/elements/divider';

const WorkflowCreationView = () => {
	const label = themeStore._.workflow;

	return (
		<Container fluid={true}>
			<Row>
				<Col xs={12}>
					<Breadcrumb>
						<BreadcrumbItem tag={Link} to="/">
							Home
						</BreadcrumbItem>
						<BreadcrumbItem tag={Link} to="..">{`${label}s`}</BreadcrumbItem>
						<BreadcrumbItem active>New</BreadcrumbItem>
					</Breadcrumb>
					<Heading>Add a new {label?.toLowerCase()}</Heading>
					<Divider />
				</Col>
				<Col lg={8}>
					<AddWorkflowForm />
				</Col>
			</Row>
		</Container>
	);
};

export default WorkflowCreationView;
