import React from 'react';
import { Link } from '@reach/router';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import Heading from '../../../styled-components/elements/heading';
import AddTemplateForm from '../../components/add-template-form';
import themeStore from '../../../theme/models/ThemeStore';
import Divider from '../../../styled-components/elements/divider';

const TemplateCreationView = () => (
	<Container fluid={true}>
		<Row>
			<Col xs={12}>
				<Breadcrumb>
					<BreadcrumbItem tag={Link} to="/">
						Home
					</BreadcrumbItem>
					<BreadcrumbItem
						tag={Link}
						to=".."
					>{`${themeStore._.workflow} Templates`}</BreadcrumbItem>
					<BreadcrumbItem active>New</BreadcrumbItem>
				</Breadcrumb>
				<Heading>Create a template</Heading>
				<Divider />
			</Col>
			<Col lg={8}>
				<AddTemplateForm />
			</Col>
		</Row>
	</Container>
);

export default TemplateCreationView;
