import styled from 'styled-components';
import { fsMedium } from '../../../theme/ui/typography';

export const CenteredHeadingCell = styled.th`
	text-align: center;
`;

export const Cell = styled.td`
	${fsMedium};
	&& {
		vertical-align: middle;
	}
`;

export const CenteredCell = styled(Cell)`
	text-align: center;
`;

export const Image = styled.div`
	display: inline-block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 80px;
	width: 80px;
`;
