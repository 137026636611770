import React from 'react';
import { useObserver } from 'mobx-react';
import { UncontrolledTooltip } from 'reactstrap';

import themeStore from '../../../theme/models/ThemeStore';
import { PermissionsCheck } from '../../../accounts/permissions-check.component';
import Link from '../../../styled-components/elements/link';
import {
	AppLogo,
	LogoContainer,
	Sidebar,
	SidebarContent,
	SidebarHeader,
} from './style';
import SidebarLink from './sidebar-link';

import { ReactComponent as AssetIcon } from '../../../assets/icons/assets.svg';
import { ReactComponent as AssetCollectionIcon } from '../../../assets/icons/asset-collections.svg';
import { ReactComponent as WorkflowIcon } from '../../../assets/icons/workflows.svg';
import { ReactComponent as WorkflowTemplateIcon } from '../../../assets/icons/workflows-templates.svg';
import { ReactComponent as ProductIcon } from '../../../assets/icons/products.svg';
import { ReactComponent as ProjectIcon } from '../../../assets/icons/projects.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/reports.svg';

const AdminSidebar = () => {
	const render = () => {
		return (
			<Sidebar>
				<SidebarHeader>
					<LogoContainer tag={Link} to="/">
						<AppLogo />
					</LogoContainer>
				</SidebarHeader>
				<SidebarContent>
					<SidebarLink to="dam/assets">
						<AssetIcon id="assetIcon" />
						<UncontrolledTooltip placement="right" target="assetIcon">
							{`${themeStore._.asset}s`}
						</UncontrolledTooltip>
					</SidebarLink>

					<SidebarLink to="dam/collections">
						<AssetCollectionIcon id="assetCollectionIcon" />
						<UncontrolledTooltip placement="right" target="assetCollectionIcon">
							{`${themeStore._.asset}`} {`${themeStore._.assetCollection}s`}
						</UncontrolledTooltip>
					</SidebarLink>

					<PermissionsCheck check="canViewTemplates">
						<SidebarLink to="workflow/templates">
							<WorkflowTemplateIcon id="workflowTemplateIcon" />
							<UncontrolledTooltip
								placement="right"
								target="workflowTemplateIcon"
							>
								{`${themeStore._.workflow}`} Templates
							</UncontrolledTooltip>
						</SidebarLink>
					</PermissionsCheck>

					<SidebarLink to="workflow/workflows">
						<WorkflowIcon id="workflowIcon" />
						<UncontrolledTooltip placement="right" target="workflowIcon">
							{`${themeStore._.workflow}s`}
						</UncontrolledTooltip>
					</SidebarLink>

					<SidebarLink to="workflow/projects">
						<ProjectIcon id="projectIcon" />
						<UncontrolledTooltip placement="right" target="projectIcon">
							{`${themeStore._.workflowCollection}s`}
						</UncontrolledTooltip>
					</SidebarLink>

					<SidebarLink to="products">
						<ProductIcon id="productIcon" />
						<UncontrolledTooltip placement="right" target="productIcon">
							Product Registry
						</UncontrolledTooltip>
					</SidebarLink>

					<PermissionsCheck check="canViewReports">
						<SidebarLink to="reports">
							<ReportIcon id="reportIcon" />
							<UncontrolledTooltip placement="right" target="reportIcon">
								{themeStore._.report}s
							</UncontrolledTooltip>
						</SidebarLink>
					</PermissionsCheck>
				</SidebarContent>
			</Sidebar>
		);
	};

	return useObserver(render);
};

export default AdminSidebar;
