import React from 'react';
import { useObserver } from 'mobx-react';
import { RouteComponentProps } from '@reach/router';

import { isIdentifiableLoaded } from '../../../common';
import { useAssetStore } from '../../../stores';
import CommonAssetVersionDisplay from '../../components/common-asset-version-display';
import LoadingAssetState from '../../components/loading-asset-state';

const AssetDetailsView = (props: RouteComponentProps<{ assetId?: string }>) => {
	const assetStore = useAssetStore();

	const render = () => {
		const asset = assetStore.findOne(props.assetId);

		if (asset) {
			if (!asset.versionId) {
				return <CommonAssetVersionDisplay version={asset} damAsset={asset} />;
			}
			if (isIdentifiableLoaded(asset.versionId)) {
				return (
					<CommonAssetVersionDisplay
						version={asset.versionId}
						damAsset={asset}
					/>
				);
			}
		}

		return <LoadingAssetState />;
	};

	return useObserver(render);
};

export default AssetDetailsView;
